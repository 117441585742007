html{
  overflow-x: hidden;
}
body{
	font-family: 'Playfair Display',"Microsoft JhengHei",微軟正黑體,"Helvetica Neue",Helvetica,Arial,sans-serif;
	line-height: 24px;
	color: #515151;
	letter-spacing: 0.5px;
	font-weight: 300;
    -webkit-font-smoothing: antialiased;
	background-size: cover;
	overflow-x: hidden;
}
img{
	max-width: 100%;
}
a{
	color: #35251E!important;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	text-decoration: none!important;
}
a:hover, a:focus{
	text-decoration: none!important;
	color: inherit;
	outline: none;
}

h1,h2,h3,h4,h5,h6{
    color: #303030;
    font-weight:700 ;
    line-height: 1.5em!important;
    margin-top: 0;
    margin-bottom: 0px;
}
h1{
	font-size: 2.6em;
}
h2{
	font-size: 1.75em;
}
h3{
	font-size: 1.5em;
}
h4{
	font-size: 1.2em;
}
h5{
	font-size: 1em;
}
h6{
	font-size: 0.8em;
}
ul, ol{
	margin: 0;
	padding: 0;
}
ul ul, ol ol, ul ol, ol ul{
	padding-left: 30px
}
.container{
    max-width: 100%;
}
p{
  margin: 0px 15px 25px 15px;
  font-size: 1.2em;
  line-height: 200%;
}
.bg-parallax{
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
		width: 100%;
}
.playfair-display{font-weight: 400;}
.text-italic{font-style: italic;}
.no-padding{
    padding: 0!important;
}
.fw900{
    font-weight: 900;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="search"],
textarea{
    border: 1px solid #e8e8e8;
    max-width: 100%;
    line-height: 24px;
    padding: 6px 20px;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
textarea:focus{
    outline: none;
}
::-webkit-input-placeholder {
   color: inherit;
   opacity: 1;
}
:-moz-placeholder {
   color: inherit;
   opacity: 1;
}
::-moz-placeholder {
   opacity: 1;
   color: inherit;
}
:-ms-input-placeholder {
   opacity: 1;
   color: inherit;
}
@media (min-width: 1440px){
    .container {
        width: 1170px;
    }
}
.font-PlayfairDisplay{

    font-style: italic;
    font-size: 14px;
}
.text-color-primary{
    color:#e1571a;
}
blockquote {
    font-family: 'PlayfairDisplay';
    font-style: italic;
    border: 1px solid #e8e8e8;
    text-align: center;
    font-size: 16px;
    color: #303030;
    padding: 40px 100px;
    margin: 20px 0;
    position: relative;
}
blockquote:before{
    content: '';
    border: 1px solid #e8e8e8;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background: url("/images/quote.png") no-repeat center center;
    position: absolute;
    left: 50%;
    top: -25px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.alignleft {
    float: left;
}
img.alignleft {
    margin: 5px 30px 15px 0;
}
.div-home{
	position:absolute;
	width: 75%;
	height: 75%;
	background: #DCC3BB;
	z-index: -1;
	top:-5px;
	left:25px;
}
.div-font{
  color: #35251E;
  text-decoration:none!important;
}
.div-border{
	width: 100%;
	height: 123%;
	position: absolute;
	top: -60px;
	left: 0px;
	overflow: hidden;
	border-style: solid;
	border-color: #DCC3BB;
	z-index: 0;
}
.div-about1{
	position: absolute;
	width: 80%;
	height: 40%;
	left: 15%;
	overflow: hidden;
	border-style: solid;
	border-color: #DCC3BB;
	z-index: -1;
}
.div-about2{
	position: absolute;
	width: 80%;
	height: 40%;
	left: 5%;
	overflow: hidden;
	border-style: solid;
	border-color: #DCC3BB;
	z-index: -1;
	padding: 50px 30px 50px 500px;
}
.div-part {
    position: relative;
		top:0px;
		left: 0%;
		width: 100%;
    display: inline-block;
    overflow: hidden;
    float: left;
}
.div-part1 {
    position: relative;
		top:50px;
		left: 10%;
		width: 100%;
    display: inline-block;
    overflow: hidden;
    float: left;
}
.div-part2 {
    position: relative;
		top:50px;
		left: 15%;
		width: 100%;
    display: inline-block;
    overflow: hidden;
    float: left;
}
.text-brand{
	position: absolute;
	transform: rotate(90deg);
	color: #DBC2BA !important;
	top:30px;
	left: -200px;
}
.team-image img{
  padding: 60px 60px 40px 60px;
}
.service-style2 .service-content{
  padding: 0px 50px 0 50px;
}

/* OWL CAROUSEL*/
.owl-carousel .owl-next,
.owl-carousel .owl-prev{
    width: 23px;
    height: 23px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    text-align: center;
    line-height: 21px;
    display: inline-block;
}
.owl-carousel .owl-next:hover,
.owl-carousel .owl-prev:hover{
    border-color: #303030;
}
.navigation-bottom-center.owl-carousel .owl-nav{
    text-align: center;
}
.navigation-bottom-center.owl-carousel .owl-next,
.navigation-bottom-center.owl-carousel .owl-prev{
    margin: 0 5px;
}
.navigation-center.owl-carousel .owl-next,
.navigation-center.owl-carousel .owl-prev{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}
.navigation-center.owl-carousel .owl-next{
    right: 50px;
}
.navigation-center.owl-carousel .owl-prev{
    left: 50px;
}
.navigation-center.owl-carousel:hover .owl-next{
    right: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: inherit;
}
.navigation-center.owl-carousel:hover .owl-prev{
    left: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: inherit;
}
.owl-carousel .owl-dots {
  text-align: center;
}
.owl-carousel .owl-dots .owl-dot {
  background-color: #868686;
  border: 2px solid #868686;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  display: inline-block;
  height: 13px;
  margin: 0 5px;
  width: 13px;
}
.owl-carousel .owl-dots .owl-dot.active {
  background-color: transparent;
}
.dot-styl2.owl-carousel .owl-dots .owl-dot{
    background-color: #fff;
    border-color: #fff;
}
.dot-styl3.owl-carousel .owl-dots .owl-dot{
    width: 34px;
    height: 2px;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: #e8e8e8;
}

.dot-styl3.owl-carousel .owl-dots .owl-dot.active{
    background-color: #7a4a4b;
}
.nav-style2.owl-carousel .owl-next,
.nav-style2.owl-carousel .owl-prev{
    width: 56px;
    height: 56px;
    border: 1px solid #fff;
    color: #fff;
    background-color: transparent;
    line-height: 54px;
    font-size: 24px;
}
.nav-style2.owl-carousel .owl-next:hover,
.nav-style2.owl-carousel .owl-prev:hover{
    border-color: #e1571a;
    color: #e1571a;
}
.nav-style3.owl-carousel .owl-next,
.nav-style3.owl-carousel .owl-prev{
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    color: #fff;
    background-color: transparent;
    line-height: 48px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    font-size: 20px;
}
.nav-style3.owl-carousel .owl-next:hover,
.nav-style3.owl-carousel .owl-prev:hover{
    border-color: #e1571a;
    color: #e1571a;
}
.nav-center-center.owl-carousel .owl-next,
.nav-center-center.owl-carousel .owl-prev{
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    -moz-transition: all 0.45s ease;
    -webkit-transition: all 0.45s ease;
    -o-transition: all 0.45s ease;
    -ms-transition: all 0.45s ease;
    transition: all 0.45s ease;
    opacity: 0;
    visibility: hidden;
}
.nav-center-center.owl-carousel .owl-next{
    right: -50px;
}
.nav-center-center.owl-carousel .owl-prev{
    left: -50px;
}
.nav-center-center.owl-carousel:hover .owl-next{
    opacity: 1;
    visibility: inherit;
    right: 0;
}
.nav-center-center.owl-carousel:hover .owl-prev{
    opacity: 1;
    visibility: inherit;
    left: 0;
}
.banner-opacity {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.banner-opacity img {
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    width: 100%;
}
.banner-opacity:hover:before {
    opacity: 1;
    filter: alpha(opacity=100);
}
.
:hover img {
   -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.banner img{
    position: absolute;
    display: inline-block;
    overflow: hidden;
    float: left;
		width: 100%;
		margin-top: 100px;
		z-index: -1;
}
/*-------------------
        HEADER
-------------------*/
.header{
    position: absolute;
		background:#D7BFB7;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px 50px 0px 50px;
    z-index: 10;
    -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out,padding .5s ease-in-out;
    transition: background .5s ease-in-out,padding .5s ease-in-out;
}
.header .logo{
    float: left;
		width: 12%;
    padding: 12px 0;
}
.header-container{
    position: relative;
    display: inline-block;
    width: 100%;
}
.header .bz-main-mennu{
    float: right;
}
.header.stick{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.176);
    -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.176);
    -ms-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.176);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.176);
    padding-bottom: 0;
    z-index: 100;
    padding-top: 25px;
    background-color: rgba(0,0,0,0.9);
}
.header.style3.stick,
.header.style4.stick,
.header.header-cons.stick{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.176);
    -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.176);
    -ms-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.176);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.176);
    padding-bottom: 0;
    z-index: 100;
    padding-top: 25px;
    background-color: rgba(215,191,183,0.9);
}
.header.header-cons.stick .header-top-section{
    display: none;
}
.header.header-cons.stick .header-main-section{
    padding-top: 0;
}
.header.stick .logo{
    margin-top: 0px;
}
.header.stick .popup-search{
    top: 25px;
}
.header.stick .popup-search .popup-search-form{
    padding: 0 24px;
    background-color: #212121;
}
.header.style3.stick .popup-search .popup-search-form{
    padding: 0 24px;
}

.bz-main-mennu .mobile-navigation{
    display: block;
    color: #fff;
    display: none;
    position: absolute;
    right: 0;
    z-index: 999;
    font-size: 20px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    top: 0px;
    border: 1px solid #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
}
.header.style3 .bz-main-mennu .mobile-navigation,
.header.style4 .bz-main-mennu .mobile-navigation{
    color: #35251E;
    border-color: #35251E;
}
.header.style3 .popup-search .popup-search-form{
    padding: 10px 20px;
}
.main-menu ul{
    padding: 0;
}
.main-menu ul li{
    list-style: none;
    position: relative;
}
.main-menu>ul>li{
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    padding: 20px 15px;
    float: left;
}
.main-menu>ul>li>a{
    color: #fff!important;
    display: inline-block;
    position: relative;
    float: left;
    overflow: hidden;
}
.main-menu>ul>li>a:before{
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
}
.main-menu>ul>li>a:hover:before,
.main-menu>ul>li.current-menu-item>a:before{
    display: block;
}
.main-menu>ul>li.bz-bars-icon>a,
.main-menu>ul>li.bz-search-icon>a{
    font-size: 16px;
}
.main-menu>ul>li.bz-bars-icon>a:before,
.main-menu>ul>li.bz-mini-cart>a:before,
.main-menu>ul>li.bz-search-icon>a:before{
    display: none;
}
.main-menu>ul>li.bz-mini-cart>a{
    font-size: 20px;
    overflow: inherit;
}
.main-menu>ul>li.bz-mini-cart>a .count{
    width: 16px;
    height: 16px;
    background-color: #303030;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: #e1571a;
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    display: inline-block;
    position: absolute;
    top: -10px;
    right: -10px;
}
.main-menu>ul>li.bz-search-icon,
.main-menu>ul>li.bz-mini-cart{
    padding: 20px 12px 0px 12px;
}
.main-menu>ul>li:last-child{
    padding-right: 0;
}
.main-menu .sub-menu{
    position: absolute;
    top: 100%;
    left: -50%;
    min-width: 215px;
    background-color: #35251E;
    padding: 20px 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s ease, -webkit-transform 0.4s ease, visibility 0.4s linear 0s;
    -moz-transition: opacity 0.4s ease, -moz-transform 0.4s ease, visibility 0.4s linear 0s;
    -ms-transition: opacity 0.4s ease, -ms-transform 0.4s ease, visibility 0.4s linear 0s;
    -o-transition: opacity 0.4s ease, -o-transform 0.4s ease, visibility 0.4s linear 0s;
    transition: opacity .4s ease, transform .4s ease, visibility .4s linear 0s;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
}
.main-menu ul li.menu-item-has-children.show-submenu>.sub-menu{
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: inherit;
}
.main-menu .sub-menu>li{
    padding: 0px;
}
.main-menu .sub-menu>li>a{
    text-align: center;
    padding: 8px 0;
    display: block;
    font-size: 1em;
    color: #DCC3BB!important;
    text-transform: uppercase;
}
.main-menu .sub-menu>li>a:hover,
.main-menu .sub-menu>li>a:focus{
    color: #7a4a4b!important;
    background-color: #D7BFB7;
}
.main-menu .sub-menu .sub-menu{
    top: 0;
    left: 100%;
    background-color: #1a1a1a;
}
.main-menu .sub-menu .menu-item-has-children>a:after{
    content: "\f105";
    font-family: 'FontAwesome';
    float: right;
}
.main-menu>ul>li:nth-last-child(1) .sub-menu,
.main-menu>ul>li:nth-last-child(2) .sub-menu,
.main-menu>ul>li:nth-last-child(3) .sub-menu{
    right: 0;
    left:inherit;
}
.main-menu>ul>li:nth-last-child(1) .sub-menu .sub-menu,
.main-menu>ul>li:nth-last-child(2) .sub-menu .sub-menu,
.main-menu>ul>li:nth-last-child(3) .sub-menu .sub-menu,
.main-menu>ul>li:nth-last-child(4) .sub-menu .sub-menu,
.main-menu>ul>li:nth-last-child(5) .sub-menu .sub-menu{
    left: inherit;
    right: 100%;
}
.main-menu>ul>li.megamenu-item{
    position: static;
}
.main-menu .megamenu{
    left: 0;
    right: 0;
    top: 100%;
    padding: 60px;
}
.header.header-fullwidth .main-menu .megamenu{
    left: inherit;
}
.megamenu .widget{
    margin-bottom: 30px;
}
.megamenu .widget:last-child{
    margin-bottom: 0;
}
.megamenu .widget .widgettitle{
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 32px;
    letter-spacing: 1px;
}
.megamenu .widget ul li a{
    padding: 8px 0;
    font-size: 11px;
    color: #4b4b4b;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 600;
}
.megamenu .widget ul li a:hover{
    color:#e1571a ;
}
.megamenu .widget_slide_images .owl-nav{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}
.megamenu .owl-carousel .owl-next:hover,
.megamenu .owl-carousel .owl-prev:hover{
    background-color: #e1571a;
    border-color: #e1571a;
    color: #fff;
}
/* POPUP SEARCH */
.popup-search{
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    z-index: 100;
    display: none;
}
.popup-search .popup-search-form{
    padding: 34px 40px;
    background: rgba(0,0,0,0.4);
    position: relative;
    color: #fff;
}
.popup-search .input-search{
    padding: 10px;
    background: transparent;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    width: 100%;
    border: none;
    outline: none;
    letter-spacing: 2px;
}
.popup-search .close-popup{
    cursor: pointer;
    position: absolute;
    right: 34px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
/* MINI CART */
.mini-cart-content{
    position: absolute;
    top: 100%;
    right: 12px;
    min-width: 282px;
    background-color: #47352F;
    padding: 5px 17px 35px 17px;
    color: #4b4b4b;
    text-transform: none;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s ease, -webkit-transform 0.4s ease, visibility 0.4s linear 0s;
    -moz-transition: opacity 0.4s ease, -moz-transform 0.4s ease, visibility 0.4s linear 0s;
    -ms-transition: opacity 0.4s ease, -ms-transform 0.4s ease, visibility 0.4s linear 0s;
    -o-transition: opacity 0.4s ease, -o-transform 0.4s ease, visibility 0.4s linear 0s;
    transition: opacity .4s ease, transform .4s ease, visibility .4s linear 0s;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
}
.bz-mini-cart:hover .mini-cart-content {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    -webkit-transition: opacity 0.4s ease, -webkit-transform 0.4s ease;
    -moz-transition: opacity 0.4s ease, -moz-transform 0.4s ease;
    -ms-transition: opacity 0.4s ease, -ms-transform 0.4s ease;
    -o-transition: opacity 0.4s ease, -o-transform 0.4s ease;
    transition: opacity .4s ease, transform .4s ease;
}
.mini-cart-content li{
    list-style: none;
    padding-bottom: 25px;
    border-bottom: 1px solid #DCC3BB;
    margin-top: 25px;
    overflow: hidden;
}
.mini-cart-content .product-thumb{
    width: 80px;
    float: left;
}
.mini-cart-content .product-info{
    position: relative;
    margin-top: 20px;
    padding-left: 90px;
    color: #fff;
}
.mini-cart-content .product-name{
    margin: 0;
    color: #fff;
}
.mini-cart-content .product-name a{
    color: #fff!important;
    font-size: 12px;
    font-weight: 500;
}
.mini-cart-content .price{
    color: #DCC3BB!important;
    font-size: 14px;
    display: table;
}
.mini-cart-content .qty{
    text-transform: uppercase;
    font-size: 11px;
}
.mini-cart-content .remove{
    position: absolute;
    bottom: -6px;
    right: 0;
    font-size: 0;
    color: #DCC3BB!important;
}
.mini-cart-content .remove:before {
    content: "\f00d";
    font-family: 'FontAwesome';
    font-size: 12px;
    height: 9px;
    width: 9px;
    display: inline-block;
    line-height: 24px;
}
.mini-cart-content .sub-toal-wapper{
    margin-top: 20px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
}
.mini-cart-content .sub-toal-wapper .sub-toal{
    text-align: right;
    font-size: 14px;
    color: #e1571a;
    font-weight: 400;
    float: right;
}
.mini-cart-content .button.outline{
    color: #fff!important;
    float: right;
    opacity:0.8;
}
.mini-cart-content .button.outline:hover{
    color: #e1571a;
}
.header.style3{
    position: relative;
    padding-top: 70px;
}
.header.style3 .main-menu>ul>li>a{
    color: inherit;
}
.header.style4 .main-menu>ul>li>a{
    color: #35251E!important;
}
.group-shop-banners1{
    padding: 30px 30px 0 30px;
}
.banner-slide .owl-nav{
    position: absolute;
    bottom: 135px;
    left: 0;
    right: 0;
    text-align: center;
}
.banner-slide .owl-nav .owl-prev,
.banner-slide .owl-nav .owl-next{
    margin: 0 5px;
}
/*----------------------------
        BANNER HEADER
-----------------------------*/
.banner-header.hidden-xs{
    text-align: center;
    padding: 220px 0 30px 0;
    background-position: top ;
		background-image: url(/images/banner-lg.jpg);
    background-repeat: no-repeat;
		background-size: 100% auto;
    position: relative;
    margin-top: 30px;
}
.banner-header.visible-xs-block{
    text-align: center;
    padding: 50px 0 ;
    background-position: top ;
		background-image: url(/images/banner-xs.jpg);
    background-repeat: no-repeat;
		background-size: 100% auto;
    position: relative;
}
.banner-header .title {
    font-size: 36px;
    color: #fff;
    padding: 0 auto;
}

.banner-header .banner-content{
    position: relative;
}
.page-title{
    font-size: 48px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 13px;
    text-transform: uppercase;
}
.page-desc{
    font-size: 24px;

    font-style: italic;
}
.banner-content.banner-style2 .page-desc{
    font-family: inherit;
    font-style: normal;
    font-size: 18px;
}
.breadcrumbs{
    text-transform: uppercase;
    margin-top: 16px;
}
.breadcrumbs a,
.breadcrumbs span{
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
}
.breadcrumbs a:hover,
.breadcrumbs a:focus{
    color: #e1571a;
}
.breadcrumbs a:after{
    font-family: 'FontAwesome';
    content: "\f105";
    padding: 0 19px;
    font-weight: normal;
}
/* -------------------------------
            SHORTCOED
-------------------------------- */
/*-------- OUR TEAM -------*/
/* Style 4 */
.team-style4 .team-image{
    position: relative;
    overflow: hidden;
}
.team-style4 .team-social{
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: -75px;
    padding: 20px 0;
    background-color: #e1571a;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.team-style4 .inner{
    padding-top: 20px;
}
.team-style4 .team-name{
    margin-bottom: 4px;
}
.team-style4 .team-name a{
    font-size: 14px;
    text-transform: uppercase;
    color: #303030;
}
.team-style4 .postion{
    font-size: 11px;
    color: #e1571a;
    text-transform: uppercase;
    font-weight: 700;
}
.team-style4 .postion:after{
    content: '';
    height: 2px;
    width: 20px;
    background-color: #e8e8e8;
    display: block;
    margin-top: 5px;
    font-weight: 700;
    margin-bottom: 5px;
}
.team-style4:hover .team-social{
    bottom: 0;
}

/*------- TESTIMONIAL --------*/
.testimonials{
    color: #515151;
}
.testimonials-style1{
    border: 1px solid #e8e8e8;
    padding: 50px 30px 75px 30px;
}
.testimonials-style1 .testimonial{
    text-align: center;
    padding-bottom: 30px;
}
.testimonials-style1 .testimonial .avatar{
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.testimonials-style1 .testimonial .avatar img{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.testimonials-style1 .testimonial hr{
    height: 2px;
    height: 3px;
    width: 30px;
    border: none;
    background-color: #e8e8e8;
}
.testimonials-style1 .testimonial h6{
    color: #e1571a;
}
.testimonials-style1 .testimonial span{
    color: #303030;
}
/* Style 2 */
.testimonials-style2 .avatar{
    width: 90px;
    height: 90px;
    margin: 0 auto;
    margin-bottom: 25px;
}
.testimonials-style2 .avatar img{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.testimonials-style2 .testimonial{
    text-align: center;
    margin-bottom: 40px;
}
.testimonials-style2 .testimonial .inner{
    border: 1px solid #e8e8e8;
    padding: 40px 25px;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}
.testimonials-style2 .testimonial .inner:before{
    font-family: 'FontAwesome';
    content: "\f0d8";
    position: absolute;
    font-size: 40px;
    left: 0px;
    right: 0px;
    width: 28px;
    margin: 0 auto;
    top: -18px;
    color: #fff;
    text-shadow: 0px -1px 1px rgba(0,0,0,0.2);
}
.testimonials-style2 .testimonial hr{
    height: 2px;
    height: 3px;
    width: 30px;
    border: none;
    background-color: #e8e8e8;
    margin-top: -5px;
}
.testimonials-style2 .testimonial h6{
    margin-bottom: 0;
}
/* Style 3*/
.testimonials-style3 .testimonial{
    text-align: center;
}
.testimonials-style3 .testimonial .avatar{
    width: 90px;
    height: 90px;
    margin: 0 auto;
}
.testimonials-style3 .testimonial .avatar img{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.testimonials-style3 .inner{
    padding-top: 25px;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 20px;
}
.testimonials-style3 .testimonial hr{
    height: 2px;
    height: 3px;
    width: 30px;
    border: none;
    background-color: #e8e8e8;
}
.testimonials-style3 .testimonial h6{
    color: #e1571a;
    text-transform: uppercase;
}
/* Style 4*/
.testimonials-style4 .testimonial{
    margin-bottom: 50px;
}
.testimonials-style4 .testimonial .avatar{
    width: 70px;
    height: 70px;
    margin-left: 25px;
    margin-top: -35px;
}
.testimonials-style4 .testimonial .avatar img{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.testimonials-style4 .testimonial .text-in{
    border: 1px solid #e8e8e8;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    padding: 30px 35px 60px 35px;
    margin: 0;
    text-align: center;
}
.testimonials-style4 .testimonial .inner{
    padding-left: 25px;
    margin-top: 20px;
}
.testimonials-style4 .testimonial h6,
.testimonials-style4 .testimonial span{
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}
.testimonials-style4 .testimonial span{
    font-size: 10px;
    color: #515151;
}
/* Style 5 */
.testimonials-style5 .text-in{
    font-size: 18px;

    font-style: italic;
    margin-bottom: 50px;
    line-height: 30px;
}
.testimonials-style5 h6{
    text-transform: uppercase;
    margin: 0;
}
.testimonials-style5 span{
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
    color: #e1571a;
}
.testimonials-style5 .star{
    color: #ebc52d;
    padding: 35px 0;
}
.testimonials-style5 .inner{
    display: none;
}
.testimonials-style5 .avatar{
    opacity: 0.3;
    filter: alpha(opacity=30);
}
.testimonials-style5 .item-center .avatar{
    opacity: 1;
    filter: alpha(opacity=100);
    position: relative;
}
.testimonials-style5 .item-center .avatar:before{
    font-family: 'FontAwesome';
    content: "\f0d8";
    position: absolute;
    font-size: 40px;
    left: 0px;
    right: 0px;
    width: 28px;
    margin: 0 auto;
    bottom: -6px;
    color: #fff;
    z-index: 2;
}
/* Style 6 */
.testimonials-style6{
    color: #fff;
    position: relative;
    text-align: center;
}
.testimonials-style6 .avatar{
    max-width: 70px;
    position: relative;
    opacity: 0.6;
    filter: alpha(opacity=60);
}
.testimonials-style6 .item-center .avatar{
    opacity: 1;
    filter: alpha(opacity=100);
}
.testimonials-style6 .avatar img{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.testimonials-style6 h6{
    color: #fff;

    font-size: 18px;
    font-style: italic;
    font-weight: normal;
}
.testimonials-style6 span{
    font-size: 11px;
    font-weight: 700;
}
.testimonials-style6 .text-in{
    font-size: 16px;

    font-style: italic;
    font-weight: normal;
}
.testimonials-style6 .inner{
    display: none;
}
.testimonials-style6 .testimonial-info{
    padding-top: 55px;
}
.testimonials-style6 .testimonial-owl{
    max-width: 430px;
    margin: 0 auto;
    position: static;
}
.testimonials-style6 .owl-prev,
.testimonials-style6 .owl-next{
    width: 50px;
    height: 50px;
    border-color: #fff;
    color: #fff;
    background: transparent;
    line-height: 48px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    opacity: 0.3;
}
.testimonials-style6 .owl-prev:hover,
.testimonials-style6 .owl-next:hover{
    opacity: 1;
    border-color: #fff;
}
.testimonials-style6 .owl-prev{
    left: 0;
}
.testimonials-style6 .owl-next{
    right: 0;
}
/* Style 3*/
.testimonials-style7 .testimonial{
    text-align: center;
}
.testimonials-style7 .testimonial .avatar{
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border: 1px solid #e8e8e8;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background: url("/images/quote.png") no-repeat center center;
}
.testimonials-style7 .inner{
    padding-top: 25px;
    margin: 0 auto;
    padding-bottom: 20px;
}
.testimonials-style7 .testimonial hr{
    height: 2px;
    height: 3px;
    width: 30px;
    border: none;
    background-color: #e8e8e8;
}
.testimonials-style7 .testimonial h6{
    text-transform: uppercase;
}
.testimonials-style7 .testimonial span{
    font-size: 11px;
    text-transform: uppercase;
}
/*------ PRCING TABLE --------*/
.pricing-table{
    border: 1px solid #e8e8e8;
    color: #515151;
    margin-bottom: 35px;
    position: relative;
}
.pricing-table .popular{
    position: absolute;
    right: -6px;
    top: 135px;
}
.pricing-table .popular span{
    position: relative;
    line-height: 28px;
    display: inline-block;
    background-color: #e1571a;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 10px 0 15px;
}
.pricing-table .popular span:before{
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 9px solid #fff;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
}
.pricing-table .popular span:after{
        content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid #a53d0f;
    border-right: 6px solid transparent;
    bottom: -6px;
    right: 0;
}
.pricing-table-style1 .head{
    padding-top: 40px;
    padding-bottom: 25px;
    text-align: center;
}
.pricing-table-style1 .head .title{
    font-size: 24px;
    text-transform: uppercase;
    margin: 0;
}
.pricing-table-style1 .head .desc{
    font-size: 18px;

    font-style: italic;
    font-weight: normal;
}
.pricing-table-style1 .icon{
    width: 100px;
    height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background: #bc8069;
    margin: 0 auto;
    text-align: center;
    padding-top: 25px;
    margin-bottom: 8px;
}
.pricing-table-style1 .price{
    color: #fff;
    font-size: 30px;

    font-weight: normal;
    font-style: italic;
}
.pricing-table-style1 .price .currency{
    font-size: 14px;
    font-family: 'Raleway';
    font-weight: 400;
    vertical-align: top;
    font-style: normal;
    margin-right:3px;
    line-height: 10px;
}
.pricing-table-style1 .price .period{
    font-size: 14px;
    display: table;
    margin: 0 auto;
    margin-top: 3px;
}
.pricing-table-style1 ul{
    padding: 0;
}
.pricing-table-style1 ul li{
    list-style: none;
    padding: 15px 0;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
}
.pricing-table-style1 ul li:nth-child(even){
    background: #f3f3f3
}
.pricing-table-style1 .pricing-button{
    padding: 26px 0;
    text-align: center;
    text-transform: uppercase;
    background: #868686;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    display: inline-block;
    width: 100%;
}
.pricing-table-style1 .pricing-button:hover{
    background-color: #303030;
}
.pricing-table-style1.active .pricing-button{
    background-color: #303030;
}
/* Style 2*/
.pricing-table-style2{
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    padding: 0 30px 30px 30px;
}
.pricing-table-style2 .head{
    padding: 25px 0 15px 0;
}
.pricing-table-style2 .title{
    font-size: 18px;
    margin-bottom: 5px;
}
.pricing-table-style2 .desc{
    font-size: 14px;

    font-style: italic;
    font-weight: normal;
}
.pricing-table-style2 .icon{
    width: 57px;
    height: 57px;
    border: 1px solid #e8e8e8;
    margin: 0 auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    color: #303030;
    line-height: 55px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.09);
}
.pricing-table-style2 .price{
    font-size: 48px;
    font-weight: 700;
    border-bottom: 1px solid #e8e8e8;
    padding: 23px 0;
    margin-bottom: 10px;
}
.pricing-table-style2 .price .currency{
    vertical-align: top;
    font-size: 14px;
    font-weight: 500;
    margin-right: 3px;
    line-height: 6px;
}
.pricing-table-style2 .price .period{
    font-size: 14px;
    margin-left: 3px;
}
.pricing-table-style2 ul li{
    list-style: none;
    line-height: 36px;
    text-transform: uppercase;
}
.pricing-table-style2 .pricing-button{
    padding: 6px 40px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    background-color: #303030;
    display: inline-block;
    margin-top: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}
.pricing-table-style2 .pricing-button:hover{
    background-color: #e1571a;
}
/* Style 3*/
.pricing-table-style3{
    border: none;
    text-align: center;
    background-color: #fff;
    padding-bottom: 40px;
}
.pricing-table-style3 .head{
    padding-top: 20px;
    color: #fff;
    background-color: #43424d;
}
.pricing-table-style3 .title{
    font-size: 18px;
    font-weight: 300;
    color: #fff;
}
.pricing-table-style3 .price{
    font-size: 72px;
    padding: 20px 0 45px;
}
.pricing-table-style3 .price .currency{
    font-size: 18px;
    vertical-align: top;
    line-height: 10px;
    opacity: 0.6;
}
.pricing-table-style3 .price .period{
    font-size: 14px;
    opacity: 0.6;
}
.pricing-table-style3 li{
    list-style-type: none;
    line-height: 48px;
    font-size: 14px;
}
.pricing-table-style3 .pricing-button{
    padding: 13px 30px;
    border: 2px solid #e8e8e8;
    color: #515151;
    text-transform: uppercase;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 30px;
}
.pricing-table-style3 li .fa{
    color: #a7ce3d;
    margin-right: 20px;
}
.pricing-table-style3.active{
    margin-top: -40px;
}
.pricing-table-style3.active .head,
.pricing-table-style3.active .pricing-button{
    background-color: #e1571a;
    color: #fff;
    border-color: #e1571a;
}
.pricing-table-style3 .box-border .left-box{
    float: left;
    border-color: transparent transparent transparent #ffffff;
    border-style: solid solid solid dashed;
    border-width: 21px 0 0 135px;
    height: 0;
    width: 0;
}
.pricing-table-style3 .box-border .right-box{
    float: right;
    border-color: transparent #ffffff transparent transparent;
    border-style: solid dashed solid solid;
    border-width: 21px 135px 0 0;
    height: 0;
    width: 0;
}
.pricing-table-style3 .box-border{
    margin-top: -21px;
}
.pricing-table-style3 .inner{padding-top: 48px;}
/* Style 4*/
.pricing-table-style4{
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    padding: 35px 30px 50px 30px;
    text-align: center;
}
.pricing-table-style4 .head{
    padding-bottom: 35px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 38px;
}
.pricing-table-style4 .title{
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 30px;
}
.pricing-table-style4 .price{
    font-size: 72px;
    color: #e1571a;
}
.pricing-table-style4 .price .currency{
    font-size: 18px;
    color: #515151;
    vertical-align: top;
    line-height: 10px;
}
.pricing-table-style4 .price .period{
    font-size: 18px;
    color: #515151;
    display: block;
    margin: 0 auto;
    margin-top: 25px;
}
.pricing-table-style4 li{
    list-style: none;
    line-height: 48px;
    text-align: left;
}
.pricing-table-style4 .pricing-button{
    padding: 13px 30px;
    border: 2px solid #e8e8e8;
    color: #515151;
    text-transform: uppercase;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 35px;
}
.pricing-table-style4 li .fa{
    padding: 0 40px 0 30px;
}
.pricing-table-style4.active{
    margin-top: -30px;
}
.pricing-table-style4.active .pricing-button{
    background-color: #e1571a;
    border-color: #e1571a;
    color: #fff;
}

/*----- BUTTONS -------*/
.button{
    line-height: 24px;
    padding: 6px 20px;
    text-transform: uppercase;
    font-size: 12px;
    display: inline-block;
    font-weight: 500;
    background: #47352F;
}
.button:hover{
    background-color:#DBC2BA;
    border-color: #e1571a;
    color: #fff;
}
.button.dark{
    background-color: #303030;
    border-color: #303030;
    color: #fff;
}
.button.dark:hover{
    background-color: #aeaeae;
    border-color: #aeaeae;
    color: #303030;
}
.button.primary{
    background-color: #e1571a;
    border-color: #e1571a;
    color: #fff;
}
.button.primary:hover{
    background-color: #303030;
    border-color: #303030;
    color: #fff;
}
.button.medium{
    padding: 14px 45px;
}
.button.radius{
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
}
.button.outline{
    background-color: transparent;
    border-width: 2px;
    color: #303030;
    padding: 5px 20px;
}
.button.outline:hover{
    background-color: transparent;
    color: #303030;
}
.button.round{
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
}
.button.icon{
    padding: 23px 70px;
}
.button.icon .icon{
    margin-right: 30px;
    font-size: 24px;
    line-height: 24px;
}
.button.round-icon{
    padding: 14px 20px;
    padding-right: 65px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}
.button.round-icon .icon{
    margin-right: 30px;
    font-size: 20px;
}
.button.button-color{
    padding: 7px 42px;
    border: none;
    color: #fff;
    background-color: #303030;
}
.button.button-color:hover{
    color: #fff!important;
    background-color: #303030!important;
}
.button-custom{
    line-height: 24px;
    padding: 6px 20px;
    text-transform: uppercase;
    font-size: 12px;
    display: inline-block;
    font-weight: 500;
    border: 2px solid #DCC3BB;
    float: right;
}
.button-custom:hover{
    color: #e1571a;
}
.button-custom.medium{
    padding: 13px 50px;
}
.button-custom.big{
    padding: 23px 70px;
}
/*------- PROMO CALL OUT --------*/
.promo{
    display: table;
    width: 100%;
}
.promo .promo-left,
.promo .promo-right{
    display: table-cell;
    vertical-align: middle;
}
.promo h3{
    color: #fff;
    font-size: 30px;
    font-family: 'PlayfairDisplay';
    font-style: italic;
    font-weight: normal;
    margin: 0;
}
.promo .promo-right{
    text-align: right;
}
.promo .promo-icon{
    font-size: 40px;
    font-weight: normal;
    margin-right: 40px;
}
.promo2 {
    display: inherit;
    text-align: center;
}
.promo2 .promo-right,
.promo2 .promo-left{
    display: block;
}
.promo2 .promo-right{
    text-align: center;
    padding-top: 37px;
}
.promo3 h3{
    font-family: inherit;
    font-style: inherit;
    font-size: 24px;
}
/*------- BOX MESSAGE -------*/
.msg-box{
    padding: 25px 30px;
    border: 1px solid #e8e8e8;
    background-color: #f3f3f3;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: #303030;
    position: relative;
}
.msg-box .msg-box-icon{
    width: 50px;
    height: 50px;
    display: inline-block;
    -webkit-border-radius: 5%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: #303030;
    color: #fff;
    line-height: 50px;
    text-align: center;
    margin-right: 45px;
    font-size: 24px;
}
.msg-box .box-close{
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 40px;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.msg-box .box-close:before{
    font-family: 'FontAwesome';
    content: "\f00d";
    cursor: pointer;
}
/* success */
.msg-box.success{
    background-color: #e2f5cb;
    border-color: #b4d8ca;
    color: #93cc4e;
    font-weight: 700;
}
.msg-box.success .box-close{
   color: #93cc4e;
}
.msg-box.success .msg-box-icon{
    background-color: #93cc4e;
    color: #fff;
}
/* alert */
.msg-box.alert{
    background-color: #fff2e1;
    border-color: #ddd6c2;
    color: #ffc11b;
}
.msg-box.alert .box-close{
   color: #ffc11b;
}
.msg-box.alert .msg-box-icon{
    background-color: #ffc11b;
    color: #fff;
}
/* info */
.msg-box.info{
    background-color: #effaff;
    border-color: #c0d7e2;
    color: #6dcff6;
}
.msg-box.info .box-close{
   color: #6dcff6;
}
.msg-box.info .msg-box-icon{
    background-color: #6dcff6;
    color: #fff;
}
/* warning */
.msg-box.warning{
    background-color: #ffe8e2;
    border-color: #e8d5cf;
    color: #eb5020;
}
.msg-box.warning .box-close{
   color: #eb5020;
}
.msg-box.warning .msg-box-icon{
    background-color: #eb5020;
    color: #fff;
}
/* Style 2 */
.msg-box2{
    padding: 0;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    display: table;
    width: 100%;
}
.msg-box2 .msg-box-icon,
.msg-box2 .box-text{
    display: table-cell;
    vertical-align: middle;
}
.msg-box2 .msg-box-icon{
    width: 70px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 20px 0;
    line-height: 24px;
}
.msg-box2 .box-text{
    padding-left: 40px;
}
.msg-box2 .box-close{
    color: #fff;
}
.msg-box2.success{
    background-color: #81bb3a;
    color: #fff;
}
.msg-box2.success .msg-box-icon{
    background-color: #93cc4e;
}
.msg-box2.success .box-close{
    color: #fff;
}
.msg-box2.alert{
    background-color: #e7ae14;
    color: #fff;
}
.msg-box2.alert .msg-box-icon{
    background-color: #ffc11b;
}
.msg-box2.alert .box-close{
    color: #fff;
}
.msg-box2.info{
    background-color: #45b9e7;
    color: #fff;
}
.msg-box2.info .msg-box-icon{
    background-color: #6dcff6;
}
.msg-box2.info .box-close{
    color: #fff;
}
.msg-box2.warning{
    background-color: #d74416;
    color: #fff;
}
.msg-box2.warning .msg-box-icon{
    background-color: #eb5020;
}
.msg-box2.warning .box-close{
    color: #fff;
}
/*-------- FAQ CAT -------*/
.faq-category{
    padding: 0;
}
.faq-category li{
    list-style: none;
    padding: 18px 0;
    padding-left: 40px;
    background-color: #f0f0f0;
    font-size: 12px;
    color: #292929;
    font-weight: 600;
    position: relative;
    display: block;
    margin-bottom: 20px;
}
.faq-category li a{
    color: #292929;
}
.faq-category li:last-child{
    margin-bottom: 0;
}
.faq-category li:after{
    content: "";
    position: absolute;
    top: 50%;
    right: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid #e1571a;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none;
}
.faq-category li:hover,
.faq-category li.active,
.faq-category li.current-cat{
    background-color: #e1571a;
    color: #fff;
}
.faq-category li:hover a,
.faq-category li.active a,
.faq-category li.current-cat a{
    color: #fff;
}
.faq-category li:hover:after,
.faq-category li.active:after,
.faq-category li.current-cat:after{
    display: block;
}
.faq-category li a .fa{
    margin-right: 20px;
}
.bz-acordion.acordion2 > h3.ui-accordion-header{
    border: none;
    border-bottom: 1px solid #e8e8e8;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    padding: 16px 0;
    margin: 0;
}
.bz-acordion.acordion2 .ui-accordion-content{
    padding: 0;
    padding-top: 30px;
    border: none;
    border-bottom: 1px solid #e8e8e8;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    margin: 0;
}
.bz-acordion.acordion2 .ui-accordion-header .ui-icon{
    width: 22px;
    height: 22px;
    background-color: #e8e8e8;
    color: #303030;
    text-align: center;
    line-height: 21px;
}
.bz-acordion.acordion2 .ui-accordion-header .ui-icon:before{
    content: "\f107";
}
.bz-acordion.acordion2 > h3.ui-accordion-header.ui-accordion-header-active .ui-icon{
    background-color: #e1571a;
    color: #fff;
}
.bz-acordion.acordion2 > h3.ui-accordion-header.ui-accordion-header-active .ui-icon:before{
    content: "\f106";
}
/*---- BLOCK TITLE ----*/
.block-title h4{
    font-size: 18px;
    margin-bottom: 30px;
    text-transform: uppercase;
}


/* Driver*/
.driver{
    margin: 1.07143rem 0;
    position: relative;
    display: inline-block;
    width: 100%;
}
.driver:before,
.driver:after{
    border-top: 1px solid #e8e8e8;
    content: "";
    height: 0;
    left: 30px;
    position: absolute;
    top: 49%;
    width: 100%;
}
.driver:before{
    left: auto;
    margin-right: 10px;
    right: 50%;
    width: 3%;
}
.driver:after{
    right: auto;
    margin-left: 10px;
    left: 50%;
    width: 3%;
}
.driver .dot{
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: #e8e8e8;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: -3px;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.block-about-3{
    padding-top: 90px;
    padding-bottom: 65px;
}
.block-about-3 .about-image{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 60px;
}
.block-about-3 .section-title2{
    margin-bottom: 30px;
}
.block-about-3 .name-client{
    font-family: 'Herr Von Muellerhoff';
    line-height: normal;
    font-size: 60px;
}
.block-about-3 .position-client{
    font-weight: 700;

    font-style: italic;
    display: block;
}
.block-about-3 .social{
    padding-top: 25px;
}
.block-about-3 .social a {
    width: 42px;
    height: 42px;
    line-height: 40px;
    border: 1px solid #e8e8e8;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    margin: 0 6px;
    color: #868686;
}
.block-about-3 .social a:hover{
    background-color: #e1571a;
    border-color: #e1571a;
    color: #fff;
}
/* PHOTO GRID */
.photo-item .pt-caption {
    overflow: hidden;
    position: relative;
}
.pt-hover1 .pt-info {
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0,0,0,0.6);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 1;
}
.pt-hover1 .photo-item:hover .pt-info {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.pt-hover1 .pt-info .pt-content-info {
    width: 100%;
    padding: 0 30px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.photos-masonry .pt-cat a{
    font-size: 30px;

    font-style: italic;
    color: #fff;
}
.photos-masonry .pt-title a{
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.gap-30{
    margin: 0 -15px;
}
.gap-30 .masonry-item{
    padding: 0 15px;
    padding-bottom: 30px;
}
/* block-instagram */
.block-instagram a:hover img{
    opacity: 0.6;
}
/* SHOP */
.block-tab-products .masonry_fillter,
.block-tab-products .bz-horizontalTab .resp-tabs-list{
    display: table;
    width: auto;
    margin: 0 auto;
    margin-bottom: 50px;
}
.block-tab-products .masonry_fillter .item-fillter,
.block-tab-products .bz-horizontalTab .resp-tabs-list li{
    display: inline-block;
    padding: 0;
    padding-left: 10px;
    text-transform: uppercase;
    cursor: pointer;
    line-height: inherit;
    color: inherit;
    letter-spacing: inherit;
    font-weight: normal;
    font-size: inherit;
}
.block-tab-products .masonry_fillter .item-fillter:after,
.block-tab-products .bz-horizontalTab .resp-tabs-list li:after{
    content: "/";
    display: inline-block;
    padding-left: 10px;
    color: #515151;
}
.block-tab-products .masonry_fillter .item-fillter:last-child:after,
.block-tab-products .bz-horizontalTab .resp-tabs-list li:last-child:after{
    content: '';
}

.block-tab-products .item-fillter.fillter-active,
.block-tab-products .bz-horizontalTab .resp-tabs-list .resp-tab-active{
    color: #e1571a;
    padding: 0!important;
    padding-left: 10px;
    border: none;
    margin: 0;
    margin-left: 10px;
}
.block-tab-products .tab-list{
    padding: 0;
}
/* COLLECTION */
.collection-item{
    position: relative;
}
.collection-item .inner{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(122,74,75,0.6);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.collection-item:hover .inner{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.collection-item .content{
    position: absolute;
    top: 50%;
    left: 0;
    padding: 0 50px;
    text-align: center;
    color: #fff;
    webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

}
.collection-item .title{
    color: #fff;
}
.collection-item .button{
    border-color: #fff;
    color: #fff;
}
.collection-item .button:hover{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.shop-brands1{
    padding: 0 80px;
}
.shop-brands1 .owl-dots{
    margin-top: 70px;
}
/* shop-newaletter */
.shop-newaletter{
    background-color: #7a4a4b;
    color: #fff;
    padding: 43px 0 42px 0;
    text-align: center;
}
.shop-newaletter .left,
.shop-newaletter .right{
    display: inline-block;
}
.shop-newaletter h3{
    color: #fff;
}
.shop-newaletter .right{
    min-width: 542px;
    max-width: 100%;
    padding-left: 40px;
    position: relative;
}
.shop-newaletter .input-email{
    background-color: transparent;
    border-color: #fff;
    width: 100%;
}
.shop-newaletter .submit-button{
    position: absolute;
    top: 0;
    right: 0;
    width: 37px;
    height: 37px;
    background-color: transparent;
    border:none;
    padding: 0;
    text-align: center;
    line-height: 37px;
    font-size: 16px
}

.banner-item{
    display: inline-block;
    float: left;
}
.block-text{
    border: 1px solid #868686;
    padding: 40px 25px 25px 25px;
    margin-bottom: 30px;
}
/* block lastest new*/
.block-lastest-new{
    padding: 0;
}
.block-lastest-new .item{
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
    padding-bottom: 10px;
    overflow: hidden;
}
.block-lastest-new .item:first-child{
    padding-top: 0;
}
.block-lastest-new .item:last-child{
    margin-bottom: 0;
    border: none;
}
.block-lastest-new .thumb{
    width: 70px;
    float: left;
    padding-top: 10px;
}
.block-lastest-new .info{
    margin-left: 100px;
}
.block-lastest-new .title{
    margin-bottom: 0;
    line-height: 24px;
}
.block-lastest-new .title a{
    font-size: 14px;
    font-weight: 600;
    color: #303030;
}
.block-lastest-new .title a:hover{
    color: #e1571a;
}
.block-lastest-new .meta{
    text-align: right;
}
.block-lastest-new .desc p{
    margin-bottom: 10px;
}

/* FALSHION STYLE 4*/
.page-template-sidebar .sidebar-menu{
    width: 375px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    overflow: auto;
    z-index: 50;
    padding: 110px 0 60px 65px;
}
.sidebar-menu .inner{
    position: relative;
    height: 100%;
    padding-right: 65px;
}

.sidebar-menu .bz-mini-cart{
    margin-top: 85px;
}
.sidebar-menu .bz-mini-cart a{
    color: #2b2b2b;
    font-weight: 600;
}
.sidebar-menu .bz-mini-cart .icon{
    font-size: 20px;
    position: relative;
    margin-right: 20px;
}
.sidebar-menu .bz-mini-cart .icon .count{
    width: 16px;
    height: 16px;
   background-color: #303030;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: #e1571a;
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    display: inline-block;
    position: absolute;
    top: -10px;
    right: -10px;
}
.sidebar-menu .form-search {
    border: 1px solid #e8e8e8;
    position: relative;
    margin-top: 25px;
}
.sidebar-menu .form-search input{
    width: 100%;
    border: none;
    padding: 7px 20px;
}
.sidebar-menu .form-search .button-search{
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border:none;
}
.sidebar-menu .bz-main-mennu{
    margin-top: 50px;
}
.sidebar-menu .main-menu>ul>li{
    padding: 13px 0;
    display: block;
    float: left;
    width: 100%;
}
.sidebar-menu .main-menu>ul>li>a{
    color: #2b2b2b;
}
.sidebar-menu .main-menu>ul>li>a:hover,
.sidebar-menu .main-menu>ul>li.current-menu-item>a{
    color: #e1571a;
}
.sidebar-menu .header-sidebar{
    margin-bottom: 100px;
}
.sidebar-menu .footer-sidebar{
    width: 100%;
    float: left;
}
.sidebar-menu .footer-sidebar .footer-social{
    margin-bottom: 30px;
}
.page-template-sidebar .main-content-site{
    padding-left: 375px;
}
.mobile-sidebar {
    display: none;
    position: absolute;
    top: 10px;
    left: 20px;
    border: 1px solid;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    z-index: 99;
    padding: 10px 7px 7px;
}
.mobile-sidebar .line-menubar {
    height: 2px;
    width: 20px;
    margin-bottom: 3px;
    background-color: #303030;
    display: block;
}
/* ------ FUNFACR ------ */
.bz-funfact{
    text-align: center;
    margin-bottom: 35px;
    position: relative;
}
.bz-funfact .title{

    font-size: 18px;
    font-style: italic;
    display: block;
    font-weight: normal;
}
.bz-funfact .number{
    font-family: 'Raleway';
    display: block;
    margin-bottom: 15px;
    line-height: 1em;
}
.bz-funfact .bz-icon-funfact{
    display: block;
    margin-bottom: 15px;
    font-size: 20px;
}
.bz-funfact-style1{
    color: #ffffff;
}
.bz-funfact-style1 .number{
    font-size: 48px;
    font-weight: 500;
}
.bz-funfact-style1 .title{color: inherit;}
.section-funfact{
    overflow: hidden;
    padding: 50px 0;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}
.section-funfact.no-border{border: none;}
.section-funfact.three-column .bz-funfact{
    float: left;
    width: 33.3334%;
}
.section-funfact.four-column .bz-funfact{
    float: left;
    width: 25%;
}
.section-funfact.five-column .bz-funfact{
    float: left;
    width: 20%;
}
.bz-funfact-style2 .bz-icon-funfact{
    color: #e1571a;
    font-size: 48px;
}
.bz-funfact-style2 .number{
    color: #303030;
    font-size: 36px;
    font-weight: bold;
}
.bz-funfact-style2 .title{
    color: #515151;
}
.bz-funfact-style3{
    color: #ffffff;
}
.bz-funfact-style3 .hr{
    width: 30px;
    height: 1px;
    display: block;
    background-color: #ffffff;
    margin: 0 auto 15px;
}
.bz-funfact-style3 .number{
    font-size: 48px;
}
.bz-funfact-style3 .title{color: inherit;}
.bz-funfact-style4{color: #ffffff;}
.bz-funfact-style4 .number{
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 10px;
}
.bz-funfact-style4 .hr{
    height: 10px;
    width: 1px;
    display: block;
    background-color: #ffffff;
    margin: 0 auto 10px;
}
.bz-funfact-style4 .title{
    font-family: inherit;
    font-style: normal;
    font-size: 12px;
    color: inherit;
}
.section-funfact.funfact-style5{
    background-color: #2f2f2f;
    padding: 50px 0 35px;
    border: none;
}
.section-funfact.funfact-style5 .title{
    font-weight: bold;
    text-transform: uppercase;
}
/* PROGRESSBAR */
.bz-chart {
  text-align: center;
}
.bz-chart .info-chart{
    font-weight: 400;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    color: #ffffff;
    height: 100%;
    display: table;
}
.tabel-cell{
    display: table-cell;
    vertical-align: middle;
}
.bz-chart .info-chart .chart-percent{
    font-size: 24px;
    font-weight: 300;
    display: block;
    margin-bottom: 10px;
}
.bz-chart .info-chart .skill-title{
    font-size: 11px;
    display: block;
    font-weight: 500;
}
/*----- Processbar----- */
.bz-processbar .item-processbar{
  margin-bottom: 20px;
}
.item-processbar {
  display: block;
  height: auto;
  position: relative;
  width: 100%;
  -webkit-transition: width 0.4s linear 0s;
  -o-transition: width 0.4s linear 0s;
  transition: width 0.4s linear 0s;
}
.processbar-title {
  color: black;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
  display: inline-block;
  letter-spacing: 1px;
}
.item-processbar .processbar-bg {
  background-color: #ececec;
  height: 5px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
}

.item-processbar .processbar-bg .processbar-width {
  background-color: #303030;
  height: 100%;
  position: relative;
  width: 0;
}
.processbar-width .processbar-percent{
    color: #303030;
    line-height: normal;
    text-transform: uppercase;
    position: absolute;
    right: 0;
}
.processbar-style1 .processbar-width .processbar-percent{
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    top: 0;
}
.processbar-style2 .processbar-width .processbar-percent{
  top: -30px;
  font-size: 10px;
  font-weight: 500;
}
.bz-processbar.light .processbar-title{
    color: #ffffff
}
.bz-processbar.light .processbar-percent{
    color: #ffffff;
}
/* -----TAB----- */
.bz-tab{margin-bottom: 60px;}
.bz-horizontalTab .resp-tabs-list li{
	text-transform: uppercase;
	color: #303030;
	letter-spacing: 1.5px;
	line-height: 24px;
	font-weight: 600;
}
.bz-horizontalTab .resp-tabs-list{
	  display: inline-block;
	  margin-bottom: 40px;
	  width: 100%;
}
.bz-tab .resp-tabs-container .resp-tab-content{
	padding: 0;
	border: none;
}
.bz-horizontalTab .resp-tabs-list li:first-child{margin-left: 0}
.bz-tab-style1 .resp-tabs-list li{
	padding: 8px 30px!important;
	font-size: 12px;
	margin: 0 15px;
	border: 1px solid #e8e8e8;
}
.bz-tab-style1 .resp-tabs-list li.resp-tab-active{
	color: #e1571a;
}
.bz-tab-style2 .resp-tabs-list{
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;
	text-align: center;
}
.bz-tab-style2 .resp-tabs-list li{
	font-size: 10px;
	padding:13px 0!important;
	margin: 0px 20px;
	border: none;
	float: none;
	position: relative;
}
.bz-tab-style2 .resp-tabs-list li:after{
	content: "";
	height: 3px;
	width: 100%;
	background-color: #e1571a;
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.bz-tab-style2 .resp-tabs-list li.resp-tab-active{color: #e1571a}
.bz-tab-style2 .resp-tabs-list li.resp-tab-active:after{opacity: 1;}
.bz-tab-style3 .resp-tabs-list li{
	padding: 4px 10px!important;
	font-size: 10px;
	border: none;
	text-align: center;
	margin: 0 7px;
	position: relative;
}
.bz-tab-style3 .resp-tabs-list li:after{
	content: "";
	width: 1px;
	height: 7px;
	background-color: #e8e8e8;
	position: absolute;
	top: 50%;
	right: -7px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}
.bz-tab-style3 .resp-tabs-list li.resp-tab-active{
	background: #e1571a;
	color: #ffffff;
}
.bz-tab-style3 .resp-tabs-list li:last-child:after{
	border: none;
}
.bz-tab-style4 .resp-tabs-list li{

	font-size: 24px;
	line-height: 80px;
	font-style: italic;
	letter-spacing: -0.25px;
	border-left: 4px solid #e8e8e8;
	border-top: none;
	border-bottom: none;
	border-right: none;
	font-weight: 400;
	padding: 0 15px!important;
    position: relative;
}
.bz-tab-style4 .resp-tabs-list li.resp-tab-active{
	border-left-color: #e1571a;
}
.bz-tab-style4 .resp-tabs-list li span.number-review{
	font-family: 'Raleway';
	font-size: 12px;
	color: #ffffff;
	background-color: #e1571a;
	width: 25px;
	height: 25px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	line-height: 25px;
	text-align: center;
	font-style: normal;
	font-weight: bold;
	position: absolute;
	display: inline-block;
	top: 0;
}
.resp-vtabs .resp-tabs-container{
	border: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
}
.resp-vtabs ul.resp-tabs-list{width: 20%;display: inline-block;}
.resp-vtabs .resp-tabs-container{width: 80%;float: left; padding-left: 20px}
/*---------LIST--------*/
.bz-list li{
	list-style: none;
	display: list-item;
	font-weight: 500;
	margin-bottom: 15px
}
.bz-list li .fa{
	width: 21px;
	height: 21px;
	line-height: 21px;
	text-align: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	background-color: #e1571a;
	color: #ffffff;
	margin-right: 15px;
	display: inline-block;
	font-size: 10px;
}
/* ------ACCOREDION--------- */
.bz-acordion{font-family: inherit;}
.bz-acordion > h3.ui-accordion-header{
	font-size: 14px;
	font-weight: 600;
	color: #303030;
	padding: 13px 20px;
	border: 1px solid #e8e8e8;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	margin-bottom: 20px;
	cursor: pointer;
	outline: none;
	background-color: transparent;
    letter-spacing: 1px;
}
.bz-acordion > h3.ui-accordion-header.ui-accordion-header-active{
	-webkit-border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	-ms-border-radius: 5px 5px 0 0;
	-o-border-radius: 5px 5px 0 0;
	border-radius: 5px 5px 0 0;
	border-bottom: none;
	margin-bottom: 0;
	color: #e1571a;
}
.bz-acordion .ui-accordion-content{
	padding: 13px 20px;
	border: 1px solid #e8e8e8;
	border-top: none;
	-webkit-border-radius: 0 0 5px 5px;
	-moz-border-radius: 0 0 5px 5px;
	-ms-border-radius: 0 0 5px 5px;
	-o-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px;
	margin-bottom: 20px;
    line-height: 24px;
}
.bz-acordion .ui-accordion-header .ui-icon {
    color: #7c7c7c;
    float: right;
    font-size: 12px;
    left: auto;
    right: 20px;
    text-indent: 0;
    background-image: none;
}
/*----------- Divider -------*/
.divider {
  display: inline-block;
  margin-bottom: 30px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.divider-content {
  display: inline-block;
  position: relative;
}
.divider .divider-content::before, .divider .divider-content::after {
  border-bottom: 1px solid #000000;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 49%;
  width: 9999%;
}
.divider .divider-content::before {
  margin-right: 10px;
  right: 100%;
}
.divider .divider-content::after {
  left: 100%;
  margin-left: 10px;
}
.divider .divider-content > span {
  background: #000000;
  display: block;
  height: 7px;
  width: 7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* ---------SERVICE IMAGE------- */
.bz-service-image figure{
    margin-bottom: 25px;
}
.bz-service-image h3{
    margin-top: 10px!important;
    margin-bottom: 5px;
}
.bz-service-image .desc-service{
    margin-bottom: 20px;
}
.bz-service-image .button-custom{
    padding: 6px 25px;
}
.bz-service-image.service-style1 .service-image{
    position: relative;
    float: left;
    max-width: 50%;
    margin-right: 30px;
}
.bz-service-image.service-style1 .service-content{overflow: hidden;}
.bz-service-image.service-style1 .service-image .bz-video-lightbox{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}
.bz-service-image.service-style1 .service-image .link-lightbox{
    background: url(/images/icon-video2.png) no-repeat center center;
    display: block;
    width: 72px;
    height: 72px
}
.bz-service-image.service-style1 .service-content .title{margin-bottom: 25px}
.bz-service-image.service-style2{
    margin-top: 50px;
}

.right-image .service-image{float: right;}
.bz-service-image .name-client{
    display: block;
    font-size: 60px;
    font-family: 'Herr Von Muellerhoff';
    line-height: normal;
}
.bz-service-image .position-client{
    font-size: 14px;
    font-style: italic;

}
.bz-service-image .service-image{
    text-align: center;
}
.service-style3{
    margin-bottom: 50px;
}
.bz-section-service{
	overflow: hidden;
	background-color: #f1f1f1;
}
.bz-section-service .slogan {
  	left: 50%;
  	position: absolute;
  	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	max-width: 100%;
}
.bz-section-service .bz-service-image figure{
	margin-bottom: 0;
	display: none;
}
.bz-section-service .service-content{
	padding: 60px 0 60px 30px;
	width: 50%;
	float: left;
    background-position: right bottom;
    background-repeat: no-repeat;
}
.bz-section-service .service-content .service-info{
	max-width: 460px;
}
.bz-section-service .service-image{
	background: no-repeat;
	background-position: center;
	background-size: cover;
	width: 50%;
	margin: 0;
    float: left;
}
.bz-section-service.service-style1 .service-image{margin: 0}
.bz-section-service.service-style2{
    background-color: #e8e8e8;
    margin: 0;
}
.bz-section-service.right-image .service-image{float: right;}
.bz-section-service.service-style2 .service-content{padding: 45px 50px;}
.bz-section-service.service-style2 .service-content .service-info{
    max-width: none;
}
.bz-section-service.service-style2 .service-content .button{
    margin-bottom: 70px;
    margin-top: 25px;
}
.bz-section-service.service-style2 .service-content .title{
    font-weight: 300;
    letter-spacing: -0.25px;
    display: inline-block;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding-bottom: 10px;
}
.section-3{
    padding: 175px 0 105px
}
.section-service-style3{
    position: relative;
}
.section-service-style3 .service-image{
    position: absolute;
    top: 0;
}
.section-service-style3.left-image .service-image{
    left: 0
}
.section-service-style3.right-image .service-image{
    right: 0;
}
.section-service-style3 .button.icon{
    padding: 15px 30px;
    margin-top: 30px
}
.section-service-style3.left-image .service-content{
    padding-bottom: 120px;
}
.section-service-style3.right-image .service-content{
    padding-top: 130px;
}
.service-style3 .name-client{margin-top: 70px;}
.service-style-4{
    margin-bottom: 35px;
}
.service-style .desc-service{
    margin-bottom: 40px;
}
.bz-service-image.service-style5 > h3{
    font-weight: 300;
    letter-spacing: -0.25px;
    margin-bottom: 40px;
    font-size: 30px;
}
.bz-service-image.service-style6 .title{
    font-weight: 300;
    letter-spacing: -0.25px;
    margin-bottom: 10px;
}
.service-style6 .sub-title{
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    display: inline-block;
}
.service-style6 .button {
    padding: 13px 15px;
    margin-top: 30px;
}
/*-------VIDEO LIGHTBOX-----*/
.bz-video-lightbox{
    position: relative;
}
.section-video{
     color: #ffffff;
}
.bz-video-lightbox h2{
    font-size: 36px;
    margin-bottom: 55px;
}
.ts-button-lightbox{
    background: url(/images/icon-video.png) no-repeat center center;
    width: 66px;
    height: 66px;
    display: block;
    margin: 0 auto;
}
.bz-video-lightbox.video-cons h2{
    font-size: 48px;
    margin-bottom: 10px;
    letter-spacing: -0.25px;
}
.bz-video-lightbox.video-cons .ts-button-lightbox{
    background: url(/images/video-play2.png) no-repeat center center;
    width: 72px;
    height: 72px;
}
.bz-video-lightbox.video-cons p{font-size: 16px;}
/* --------BLOCK TEXT---------- */

/* about text */
.about-text img{margin-bottom: 30px}
.about-text h2{

	font-size: 36px;
	font-style: italic;
	letter-spacing: 0.5px;
	margin-bottom: 10px;
	font-weight: 400;
}
.about-text .divider{
	width: 100px;
	margin-bottom: 3px
}
/*--------- Title Section ----------*/
.section-title {
    margin-bottom: 75px;
    overflow: hidden;
}
.section-title.text-left{
    margin-bottom: 30px;
}
.section-title .title{
    text-transform: uppercase;
    letter-spacing: 3px;
    /*font-size: 25px;*/
    display: inline-block;
    position: relative;
    margin-bottom: 15px;
}
.section-title.text-center .title:after,
.section-title.text-center .title:before{
    content: "";
    border-bottom: 1px solid #e8e8e8;
    display: block;
    height: 1px;
    position: absolute;
    top: 49%;
    width: 9999%;
}
.section-title.text-center .title:after,
.section-title.text-center .title:before{
    max-width: 145px;
}
.section-title .title:before{
    margin-right: 55px;
    right: 100%;
}
.section-title .title:after{
    left: 100%;
    margin-left: 55px;
}
.section-title .desc-title{
    font-style: italic;

    font-size: 18px;
    display: block;
}
.section-title.title-style2{
    margin-bottom: 60px;
}
.section-title.title-style2 .title{

    font-size: 36px;
    font-style: italic;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0
}
.section-title.title-style2 .title:after,
.section-title.title-style2 .title:before{
    display: none;
}
.section-title.title-style2 .desc-title{
    font-family: inherit;
    font-style: normal;
    font-size: inherit;
}
.section-title.light{color: #ffffff;}
.section-title.light .title{color: inherit;}

.section-1 .section-title{margin-bottom: 65px;}
.section-title .big-title{font-size: 48px;font-weight: 900}
.section-title .medium-title{font-size: 36px;font-weight: 900}
.section-title h5{margin-bottom: 15px;}
.section-title.title-style3 .title{
    font-weight: 900;
    text-transform: none;
    display: block;
    letter-spacing: 0;
}
.section-title.title-style3 .sub-title{
    display: inline-block;
    font-size: 18px;
}
.section-title.title-style3 .sub-title:before,
.section-title.title-style3 .sub-title:after{
    content: "";
    height: 1px;
    width: 60px;
    background: #a3a3a3;
    display: inline-block;
    margin-bottom: 5px;
}
.section-title.title-style3 .sub-title:before{margin-right: 30px}
.section-title.title-style3 .sub-title:after{margin-left: 30px}
.section-title.title-style4 .title{
    font-size: 48px;
    font-weight: 300;
    letter-spacing: -0.25px;
    text-transform: none;
}
.section-title.title-style4 .title.fz43{font-size: 43px;}
.section-title.title-style4 .title span{
    color: #e1571a;
}
.section-title.title-style4 .sub-title{
    font-size: 24px;
    color: #515151;
}
.section-title.title-style4 .desc-title{
    font-family: inherit;
    font-size: inherit;
    font-style: normal;
}
.section-title.title-style4 .hr{
    height: 1px;
    width: 30px;
    background: #20171c;
    margin-bottom: 20px;
    display: inline-block;
}
.section-title.title-style4.text-center .hr{
    margin-left: auto;
    margin-right: auto;
}
.section-title.title-style4.text-right .hr{
    margin-left: auto;
    margin-right: 0;
}
.section-title.title-style4.text-left .hr{
    margin-left: 0;
    margin-right: auto;
}
.section-title.title-style4 .title::after, .section-title.title-style4 .title::before,
.section-title.title-style5 .title::after, .section-title.title-style5 .title::before,
.section-title.title-style6 .title::after, .section-title.title-style6 .title::before,
.section-title.title-style7 .title::after, .section-title.title-style7 .title::before
{display: none;}
.section-title.title-style5{margin-bottom: 50px}
.section-title.title-style5 .title{
    font-weight: 300;
    text-transform: none;
    letter-spacing: -0.25px;
}
.section-title.title-style5.text-center .title:after{
    content: url(/images/bg-title.png);
   display: block;
   width: 60px;
   height: 7px;
   position: static;
   border: none;
   margin: 0 auto;
}
.section-title.title-style6 .title{
    font-weight: 300;
    text-transform: none;
    letter-spacing: -0.25px;
}
.section-title .title span{
    color: #e1571a;
}
.section-title.title-style6 .desc-title{
    font-size: 14px;
}
.section-title.title-style7{margin-bottom: 40px}
.section-title.title-style7 .title{
    font-weight: 300;
     text-transform: none;
    letter-spacing: -0.25px;
}
.title-style7 .hr{
    display: block;
    width: 25px;
    height: 2px;
    background-color: #e1571a;
    position: relative;
}
.title-style7.text-center .hr{
    margin: 0 auto;
}
.title-style7.text-right .hr{
    margin: 0 0 0 auto ;
}
.title-style7.light .hr{background-color: #ffffff;}
.section-title.title-style8  .desc-title{
    font-style: normal;
    font-family: inherit;
    font-size: inherit;
}
.fw600{font-weight: 600!important;}
.fw700{font-weight: 700!important;}
.section-title.title-style8{margin-bottom: 60px}

/* HEADING STYLE 2*/
.section-title2{
    margin-bottom: 75px;
}
.section-title2 .title{

    font-size: 30px;
    color: #303030;
    font-style: italic;
    font-weight: normal;
    letter-spacing: 3px;
    margin: 0;
}
/* HEADING STYLE 3*/
.section-title3{
    margin-bottom: 0px;
}
.section-title3 .title{
    font-size: 30px;
    color: #303030;
    font-weight: normal;
    letter-spacing: 3px;
    margin-top: 80px;
		margin-bottom: -12px;
}
.section-title3 .title:after {
    content: '';
    width: 16px;
    height: 16px;
    margin: 0 auto;
    margin-top: 0px;
    display: block;
}
.div-gradient{
	height: 2px;
	width: 100%;
	margin: 15px auto;
	background: linear-gradient(90deg, transparent 0%, #D7BFB7 50%, transparent 100%);
	background: -moz-linear-gradient(90deg, transparent 0%, #D7BFB7 50%, transparent 100%);
	background: -o-linear-gradient(90deg, transparent 0%, #D7BFB7 50%, transparent 100%);
}


/*  Flex Slide thumb */
.slide-about .flex-control-thumbs{position: absolute;bottom: -35px;z-index: 5;}
.slide-about .flex-control-nav li{
    width:79px;
    float: none;
    margin: 0 4px;
    border: 5px solid #ffffff;
    position: relative;
}
.slide-about .flex-control-thumbs img{
    opacity: 0.4;
}
.slide-about .flex-control-thumbs img.flex-active{
    opacity: 1;
}
.slide-about .flex-control-thumbs li:after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,1);
    z-index: -1;
}
.slide-about .flex-direction-nav{display: none;}
/* -------------------------
        PORTFOLIO
-------------------------- */
.portfolio_fillter .item-fillter{
    display: inline-block;
    text-transform: uppercase;
    color: #303030;
    letter-spacing: 1.5px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
}
.bz-portfolio .portfolio_fillter{
      display: inline-block;
      margin-bottom: 40px;
      width: 100%;
}
.portfolio_fillter .item-fillter:first-child{margin-left: 0}
.portfolio_fillter.fillter-style1{margin-bottom: 40px}
.portfolio_fillter.fillter-style1 .item-fillter{
    padding: 8px 30px;
    font-size: 12px;
    margin: 0 15px;
    border: 1px solid #e8e8e8;
}
.portfolio_fillter.fillter-style1 .item-fillter.fillter-active{
    color: #e1571a;
}
.portfolio_fillter.fillter-style2{
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    margin: 0 auto 40px;
    padding: 0 6%;
    width: auto;
}
.portfolio_fillter.fillter-style2 .item-fillter{
    font-size: 10px;
    padding:13px 0;
    margin: 0px 20px;
    border: none;
    float: none;
    position: relative;
}
.portfolio_fillter.fillter-style2 .item-fillter:after{
    content: "";
    height: 3px;
    width: 100%;
    background-color: #e1571a;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.portfolio_fillter.fillter-style2 .item-fillter.fillter-active{color: #e1571a}
.portfolio_fillter.fillter-style2 .item-fillter.fillter-active:after{opacity: 1;}
.portfolio_fillter.fillter-style3 .item-fillter{
    padding: 4px 10px;
    font-size: 10px;
    border: none;
    text-align: center;
    margin: 0 7px;
    position: relative;
}
.portfolio_fillter.fillter-style3 .item-fillter:after{
    content: "";
    width: 1px;
    height: 7px;
    background-color: #e8e8e8;
    position: absolute;
    top: 50%;
    right: -7px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.portfolio_fillter.fillter-style3 .item-fillter.fillter-active{
    background: #e1571a;
    color: #ffffff;
}
.portfolio_fillter.fillter-style3 .item-fillter:last-child:after{
    border: none;
}
.pf-gap-30{margin: 0 -15px;}
.pf-gap-30 .item-portfolio{
    padding: 0 15px;
    margin-bottom: 30px;
    display: inline-block;
}
.item-portfolio .pf-title{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.5px;
    color: inherit;
    margin-bottom: 5px;
}
.item-portfolio .pf-title > a{
    color: inherit;
}
.item-portfolio .pf-title > a:hover{
    color: #e1571a;
}
.item-portfolio .pf-cat{

    font-size: 16px;
    font-style: italic;
    text-transform: capitalize;
}
.item-portfolio .pf-cat a{
    color: inherit;
}
.item-portfolio .pf-cat a:hover{
     color: #e1571a;
}
.item-portfolio .pf-icon{
    width: 44px;
    height: 44px;
    line-height: 42px;
    border: 2px solid;
    color: #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    font-size: 14px;
}
.item-portfolio .pf-icon:hover{
    color: #e1571a;
}
.item-portfolio .pf-caption{
    overflow: hidden;
    position: relative;
}
.item-portfolio .pf-image img{
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.pf-social li{
    list-style: none;
    display: inline-block;
    font-size: 14px;
    margin: 0 4px;
}
.pf-hover1 .pf-info{
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0,0,0,0.6);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.pf-hover1 .item-portfolio:hover .pf-info{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.pf-hover1 .pf-info .pf-content-info{
   width: 100%;
   padding: 0 30px;
   position: absolute;
   top: 50%;
   left: 0;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   -o-transform: translateY(-50%);
   transform: translateY(-50%);
}

.pf-hover2 .pf-info{
    background: #ffffff;
    position: absolute;
    bottom: -100%;
    padding: 20px 30px;
    right: 0;
    left: 0;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}
.pf-hover2 .item-portfolio{overflow: hidden;}
.pf-hover2 .item-portfolio:hover .pf-info{
    bottom: 0;
}
.portfolio-style3 .pf-social {
  font-size: 14px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.portfolio-style3 .pf-social a{
    color: inherit;
}
.portfolio-style3 .pf-social a:hover{
    color: #e1571a;
}
.pf-hover2 .pf-title {
  margin-bottom: 5px;
  color: #e1571a;
}
.pf-hover2 .pf-cat{margin-bottom: 10px}
.portfolio-style1 .pf-social{
    margin-top: 35px;
}
.portfolio-style1 .pf-social li a,
.portfolio-style4 .pf-social li a,
.portfolio-style5 .pf-social li a{
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,0.5);
    color: #ffffff;
    display: inline-block;
}
.portfolio-style1 .pf-social li a:hover,
.portfolio-style4 .pf-social li a:hover,
.portfolio-style5 .pf-social li a:hover{
    background: #ffffff;
    border-color: #ffffff;
    color: #303030;
}
.pf-hover3 .item-portfolio .pf-info{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: rgba(48,48,48,0.6);
    color: #ffffff;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.pf-hover3 .item-portfolio:hover .pf-info{
    opacity: 1;
}
.pf-hover3 .item-portfolio .pf-info .pf-content-info{
    position: absolute;
    left: 0;
    top: 50%;
    padding: 0 15px;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
}
.pf-hover3 .item-portfolio .pf-info::before, .pf-hover3 .item-portfolio .pf-info::after {
  bottom: 30px;
  content: "";
  left: 30px;
  opacity: 0;
  position: absolute;
  right: 30px;
  top: 30px;
  -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
  -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
  transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
  z-index: 1;
}
.pf-hover3 .item-portfolio .pf-info::before {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  -o-transform: scale(0, 1);
  transform: scale(0, 1);
}
.pf-hover3 .item-portfolio .pf-info::after{
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
}
.pf-hover3 .item-portfolio:hover .pf-info::before, .pf-hover3 .item-portfolio:hover .pf-info::after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 0.4s ease 0s, transform 0.45s ease 0.2s;
  -o-transition: opacity 0.4s ease 0s, transform 0.45s ease 0.2s;
  transition: opacity 0.4s ease 0s, transform 0.45s ease 0.2s;
}

.pf-hover4  .pf-hover{
    background:rgba(48,48,48,0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.pf-hover4 .pf-caption{
    position: relative;
    margin-bottom: 20px;
}
.pf-hover4 .item-portfolio:hover .pf-image img{
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}
.pf-hover4  .item-portfolio:hover .pf-hover{
    opacity: 1;
}
.pf-hover4  .pf-hover .pf-social{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.portfolio-style4 .item-portfolio{
    margin-bottom: 50px;
}
.pf-hover5 .pf-social{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
    z-index: 2;
}
.pf-hover5 .item-portfolio:hover .pf-social{
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.pf-hover5 .item-portfolio .pf-info {
    position: absolute;
    bottom: 0;
    padding: 25px;
    width: 100%;
    color: #ffffff;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 2;
}
.pf-hover5 .item-portfolio:hover .pf-info{
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.pf-hover5 .pf-caption:after{
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(48,48,48,0.6);
    -webkit-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
     -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: absolute;
}
.pf-hover5 .item-portfolio:hover .pf-caption:after{
   -webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}
.pf-hover6 .item-portfolio{
    opacity: 0.5;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}
.pf-hover6 .item-portfolio:hover{
    opacity: 1;
}
/* ------  PORTFOLIO DETAIL ------- */
.portfolio-slide .owl-prev,
.portfolio-slide .owl-next{
    width: 49px;
    height: 49px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    border-color: #ffffff;
    color: #ffffff;
    background: transparent;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 47px;
    font-size: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.portfolio-slide .owl-prev{left: 25px}
.portfolio-slide .owl-next{right: 25px;}
.portfolio-slide .owl-prev:hover,
.portfolio-slide .owl-next:hover{
    color: #e1571a;
    border-color: #e1571a;
}
.bz-pf-detail .pf-format{
    margin-bottom: 20px
}
.bz-pf-detail .pf-title{
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-bottom: 5px
}
.bz-pf-detail .pf-cat{

    font-style: italic;
    font-size: 16px;
    color: inherit;
    margin-bottom: 22px;
}
.bz-pf-detail .pf-cat a{color: inherit;}
.bz-pf-detail .pf-cat a:hover{color: #e1571a}
.pf-meta{
    border: 1px solid #e8e8e8;
    padding: 30px;
}
.pf-meta li{
    list-style: none;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1.5px;
    font-weight: 600;
    padding: 10px 0;
}
.pf-meta li a{color: inherit;}
.pf-meta li a:hover{color: #e1571a}
.pf-meta li .fa{
    margin-right: 15px;
    vertical-align: middle;
    font-size: 16px;
}
.pf-meta li.pf-webclient a{
    color: #e1571a;
}
.pf-meta li.pf-webclient a:hover{text-decoration: underline;}
.relate-project{
    margin-top: 65px;
}
.relate-project .section-title{
    margin-bottom: 50px;
}
.list-relate-project .owl-controls .owl-dots{
    text-align: center;
}
.list-relate-project .owl-controls .owl-dots .owl-dot{
    height: 2px;
    width: 35px;
    display: inline-block;
    background: #e8e8e8;
    margin: 0 4px;
}
.list-relate-project .owl-controls .owl-dots .owl-dot.active{
    background: #e1571a;
}
.pf-detail-2{
    padding-top: 80px;
}
.pf-detail-2 .pf-meta{
    display: inline-block;
}
.pf-video-detail{
    position: relative;
}
.pf-video-detail a.link-lightbox{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}


/*-------------------
HOME SLIDE
---------------------*/
.home-slide4.owl-carousel .owl-dots,
.home-slide5.owl-carousel .owl-dots{
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
}
.home-slide4.owl-carousel .owl-next,
.home-slide4.owl-carousel .owl-prev,
.home-slide5.owl-carousel .owl-next,
.home-slide5.owl-carousel .owl-prev{
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.home-slide4.owl-carousel .owl-next,
.home-slide5.owl-carousel .owl-next{
    right: 50px;
}
.home-slide4.owl-carousel .owl-prev,
.home-slide5.owl-carousel .owl-prev{
    left: 50px;
}
.home-slide5 .item-slide{
    position: relative;
}
.home-slide5 .item-slide .slide-content{
    position: absolute;
    top: 50%;
    text-align: center;
    left: 0;
    right: 0;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.home-slide5 .item-slide .slide-content h2{
    color: #fff;
}
/* ---  SLIDE CONTENT -- */
.section-slidecontent{
    padding: 95px 0 60px;
    background-image: url(/images/bg-parallax8.jpg);
}
.bz-slidecontent{
    color: #ffffff
}
.bz-slidecontent .title{color: inherit;}
.section-slidecontent .overlay{
    background-color: rgba(0,0,0,0.6);
}
.bz-slidecontent .item-content{
    margin-bottom: 35px;
}
.bz-slidecontent .owl-dots{text-align: center;}
.bz-slidecontent .owl-dots .owl-dot{
    width: 13px;
    height: 13px;
    border: 2px solid #ffffff;
    background-color: #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    margin: 0 5px;
    display: inline-block;
}
.bz-slidecontent .owl-dots .owl-dot.active{
    background-color: transparent;
}
.bz-slidecontent .item-content .button{background-color: #ffffff;}
.bz-slidecontent .item-content .button:hover{background-color: #e1571a}
/* Client slide */
.bz-clien-slide.owl-carousel .item-client{
    text-align: center;
    padding: 40px 10px;
}
.bz-clien-slide.owl-carousel .item-client > a{
    margin: 0 auto;
    display: inline-block;
    max-width: 100%;
}
.bz-clien-slide.owl-carousel .owl-item img{
    width: auto;
}
.bz-clien-slide.client-style2 .item-client{
    border: 1px solid #e8e8e8;
    padding: 10px 10px;
}
.bz-clien-slide.client-style3{
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}
.bz-clien-slide.client-style3  .item-client{
    border-left: 1px solid #e8e8e8;
}
/* Social */
.bz-social{
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    color: #ffffff!important;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 3px;
    font-weight: bold;
    padding: 15px 20px;
    width: 100%;
    display: inline-block;
    text-transform: uppercase;
    margin-top: 50px;
}
.bz-social:hover, .bz-social:focus{
    color: #ffffff;
}
.bz-social.facebook{
    background-color: #3b5997;
}
.bz-social.google-plus{
    background-color: #df4c3c;
}
.bz-social.twitter{
    background-color: #2fc2ee;
}
.bz-social.pinterest{
    background-color: #e02329;
}
.bz-social .fa{
    float: left;
    font-size: 25px;
    color: #fff;
}
/* Ohther */
.block-vc1 figure{
    margin-bottom: 15px;
}
.block-vc1 .button{
    margin-top: 12px;
}
.owl-carousel .team.team-style3 {
  margin-bottom: 30px;
}
.slide-team.owl-carousel .owl-dots{margin-top: 25px;}
.blog-list .blog-item{margin-bottom: 30px}
.pricing-table-style2 .price{color: #303030;}
.pricing-table-style2 .price .currency, .pricing-table-style2 .price .period{
    color: #515151;
}

.button-custom.round, .button.round{
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

/* Promo */
.promo.promo4{
    padding: 35px 40px;
    border: 1px solid #e8e8e8;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 50px;
}
.promo.promo4 .promo-left h3{
    color: #303030;
    font-weight: 300;
    font-size: 24px;
    font-style: normal;
    font-family: inherit;
}
/* Feature */
.bz-feature {
  margin-bottom: 50px;
}
.bz-feature .feature-icon{
    width: 70px;
    height: 70px;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    line-height: 70px;
    border: 1px solid #e8e8e8;
    float: left;
    font-size: 25px;
}
.bz-feature .feature-content{
    display: block;
    padding-left: 90px;
}
.bz-feature .feature-content .title-feature{
    display: inline-block;
    border-bottom: 1px solid #e8e8e8;
    clear: both;
}
.image-feature{position: relative;}
.image-feature .bg-image:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #565656;
    opacity: 0.5;
}
.image-feature .bg-image:after{
    content: "";
    border: 3px solid #ffffff;
    position: absolute;
    top: 55px;
    left: 55px;
    right: 55px;
    bottom: 55px;
}
.image-feature .slogan-icon{
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 20px;
}
/*  list-provide  */
.bz-list-provide::after, .bz-list-provide::before {
  content: "";
  display: table;
}
.bz-list-provide::after {
  clear: both;
}
.three-column .item-provide{
    width: 33.33%;
}
.four-column .item-provide{
    width: 25%;
}
.five-column .item-provide{
    width: 20%;
}
.provide-style1 .item-provide{
    float: left;
    overflow: hidden;
    color: #ffffff;
    text-align: center;
    position: relative;
}
.provide-style1 .item-provide .provice-title:after{
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    z-index: -1;
}
.provide-style1 .item-provide h2{
    color: inherit;
    font-weight: 300;
    letter-spacing: -0.25px;
}
.provide-style1 .item-provide .title-provice:after{
    content: "";
    height: 2px;
    width: 15px;
    display: block;
    background: #ffffff;
    margin: 15px auto;
}
.provide-style1 .item-provide .content-hover{
    background-color: #e1571a;
    padding: 50px 25px;
    -webkit-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
   -webkit-transition: all 0.5s ease 0s;
   -o-transition: all 0.5s ease 0s;
   transition: all 0.5s ease 0s;
   opacity: 0;
   height: 100%;
}
.provide-style1 .item-provide:hover .content-hover{
    -webkit-transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
}
.provide-style1 .item-provide .provice-title{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
   -webkit-transition: all 0.5s ease 0s;
   -o-transition: all 0.5s ease 0s;
   transition: all 0.5s ease 0s;
}
.provide-style1 .item-provide:hover .provice-title{
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    opacity: 0;
}
.provide-style1 .item-provide .provice-title{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15px
}
.bz-table {
  display: table;
  height: 100%;
  width: 100%;
}
.bz-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.provide-style1 .item-provide .content-hover .link-readmore{

    font-style: italic;
    color: inherit;
    font-size: 14px;
}
.provide-style1 .item-provide .content-hover .link-readmore .fa{margin-left: 10px;}
.provide-style1 .item-provide.bg1 .provice-title{
    background-image: url(/images/provide1.png);
}
.provide-style1 .item-provide.bg2 .provice-title{
    background-image: url(/images/provide2.png);
}
.provide-style1 .item-provide.bg3 .provice-title{
    background-image: url(/images/provide3.png);
}
.provide-style1 .item-provide.bg4 .provice-title{
    background-image: url(/images/provide4.png);
}
.provide-style1 .item-provide.bg5 .provice-title{
    background-image: url(/images/provide5.png);
}

.provide-style1 .item-provide.bg6 .provice-title{
    background-image: url(/images/provide10.png);
}
.provide-style1 .item-provide.bg7 .provice-title{
    background-image: url(/images/provide11.png);
}
.provide-style1 .item-provide.bg8 .provice-title{
    background-image: url(/images/provide12.png);
}
.provide-style1 .item-provide.bg10 .provice-title{
    background-image: url(/images/provide14.png);
}
.provide-style2 .item-provide{
    background-color: #ffffff;
}
.provide-style2 .item-provide .provide-body{
    padding: 0;
    text-align: center;
    margin-top: -33px;
    position: relative;
}
.provide-style2 .item-provide .provide-icon{
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: #e1571a;
    display: inline-block;
    margin-bottom: 20px;
}
.provide-style2 .item-provide .link-readmore{
    text-transform: uppercase;
    color: inherit;
    border: 1px solid #e8e8e8;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    padding: 5px 25px;
    display: inline-block;
}
.provide-style2 .item-provide .link-readmore:hover{
    border-color: #e1571a;
    background-color: #e1571a;
    color: #ffffff;
}
.provide-style2 .item-provide .provide-body .top-box-ct{
    border-style: solid solid solid dashed;
    border-color: transparent transparent transparent #ffffff;
    border-width: 33px 0 0 270px;
    width: 0;
    height: 0;
}
.provide-style2 .item-provide .provide-content{
    padding: 0px 20px 30px;
    margin-top: -50px
}
.bz-list-provide .owl-nav > div{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    line-height: 48px;
    border: 1px solid #e8e8e8;
    text-align: center;
    color: #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    font-size: 24px;
    background-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.bz-list-provide .owl-nav > div:hover{
    background-color: #e1571a;
    border-color: #e1571a;
}
.bz-list-provide .owl-nav > div .fa{line-height: normal;}
.bz-list-provide .owl-nav > div.owl-prev{
    left: -70px;
}
.bz-list-provide .owl-nav > div.owl-next{
    right: -70px;
}
/* Lastest blog */
.lastest-post.style1 .blog-item{margin-bottom: 45px}
.lastest-post.style1 .blog-item .post-format{
	float: left;
	margin-right: 25px;
}
.lastest-post.style1 .blog-item .post-format img{max-width: 170px}
.lastest-post.style1 .blog-item .info-post{
	overflow: hidden;
	text-align: left;
}
.lastest-post .blog-title{
	font-size: 16px;
	margin-bottom: 15px;
}
.lastest-post .blog-item .meta-post{
	text-align: left;
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;
	padding: 5px 0;
	margin-bottom: 10px;
}
.lastest-post .meta-post p{margin-bottom: 0}
.lastest-post .blog-item .hover-format{
    background-color: rgba(0,0,0,0.5);
}
.post-format.post-video-format .hover-format span{font-size: 22px;}
/* Twitter */
.bz-twitter .icon-twitter{
	width: 95px;
	height: 95px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	text-align: center;
	line-height: 95px;
	color: #2fc2ee;
	border: 1px solid #ffffff;
	font-size: 30px;
	margin: 0 auto 35px;
}
.bz-twitter .item-twitter p{
	margin-bottom: 10px;
}
.bz-twitter .item-twitter a{color: #2fc2ee}
.bz-twitter {
  color: #ffffff;
  font-size: 18px;
  position: relative;
  text-align: center;
}
/* Nav owl */
.arrow-style-2 .owl-nav > div{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    line-height: 48px;
    border: 1px solid #ffffff;
    text-align: center;
    color: #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    font-size: 24px;
    background-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0.3;
}
.arrow-style-2 .owl-nav > div:hover{
   opacity: 1;
   border-color: #ffffff;
}
.arrow-style-2 .owl-nav > div .fa{line-height: normal;}
.arrow-style-2 .owl-nav > div.owl-prev{
    left: -60px;
}
.arrow-style-2 .owl-nav > div.owl-next{
    right: -60px;
}
/* ----------------------
        CONTACT FORM
------------------------- */
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea{
    background: transparent;
    border-color: #e8e8e8;
    color: #ffffff;
    width: 100%;
    margin-bottom: 30px;
    padding: 10px 20px;
}
.contact-form input[type="submit"] {
  background: #ffffff none repeat scroll 0 0;
  margin-top: 20px;
  border: medium none;
  border-radius: 25px;
  letter-spacing: 1px;
  padding: 12px 40px;
  text-transform: uppercase;
  color: #303030;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.contact-form input[type="submit"]:hover{
    background: #e1571a;
    color: #ffffff;
}
.title-ct{
    display: inline-block;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.block-contactinfo > h6{
    color: #e1571a;
    margin-bottom: 0;
}
.block-contactinfo a{color: inherit;}
.block-contactinfo a:hover{color: #e1571a}
.contact-form label{
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
}
.contact-form label span{
    color: #e1571a;
}
.contact-form.style-1 input[type="text"], .contact-form.style-1 input[type="email"], .contact-form.style-1 textarea{
    padding: 8px 15px;
    margin-bottom: 0;
}
.contact-form.style-1 textarea{
    min-height: 175px;
}
.contact-form.style-1 input[type="submit"]{
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    border: 1px solid #e8e8e8;
    padding: 5px 15px;
}
.bz-contact-info{margin-bottom: 40px;}
.bz-contact-info .icon-contact{
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    line-height: 70px;
    background-color: #e1571a;
    float: left;
    font-size: 20px;
}
.bz-contact-info .contact-info{
    display: block;
    padding-left: 90px;
}
.bz-contact-info .contact-info h4{
    font-size: 12px;
    margin-bottom: 5px;
}
.bz-contact-info .contact-info p{margin-bottom: 15px;}
.bz-contact-info .contact-info a{color: inherit;margin-bottom: 15px;display: block;}
.bz-contact-info .contact-info a:hover{color: #e1571a}
.contact-form.style-2 input[type="text"],
.contact-form.style-2 input[type="email"]{
    color: #35251E;
    margin-bottom: 10px;
    padding: 15px;
    border: none;
    border-bottom: 1px solid #DCC3BB;
}
.contact-form.style-2 textarea{
    color: #35251E;
    margin-bottom: 0;
    padding: 15px;
    border: 1px solid #DCC3BB;
}
.contact-form.style-2 input[type="text"]:focus,
.contact-form.style-2 input[type="mail"]:focus,
.contact-form.style-2 textarea:focus{
    outline: 2px solid #e1571a;
}
.contact-form.style-2 input[type="submit"]{
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #e1571a;
    color: #ffffff;
    padding: 5px 35px;
    border: 1px solid #e1571a;
}
.contact-form.style-2 input[type="submit"]:hover{
    color: #e1571a;
    background-color: transparent;
}
/* Section contact */
.bz-section-equal {
  overflow: hidden;
}
.bz-section-equal .left-section{
    float: left;
    width: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bz-section-equal .right-section{
    float: left;
    width: 50%;
    padding: 35px 45px;
    background-color: #f3f3f3;
}
.bz-section-equal .left-section figure{
    display: none;
}
.bz-section-contact input[type="text"],
.bz-section-contact input[type="email"],
.bz-section-contact textarea{
    background: #ffffff;
    color: inherit;
    border: none;
}
.bz-section-contact .block-contactinfo > h6,
.bz-section-contact h6{
    color: inherit;
}
.contact-form.style-3 input[type="submit"]{
    background: #303030;
    color: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    border: none;
}
/* -----------------------
        HOME SLIDE
------------------------- */
.slide-home{
  overflow: hidden;
  position: relative;
  width: 100%;
  height:100%!important;
}
.content-slide .title-slide{
    color: #ffffff;
}
.content-slide .title-slide h1,
.content-slide .title-slide h3{
    color: inherit;
    margin: 0;
}
.content-slide .sub-title{
    font-size: 58px;
    font-weight: 900;
    line-height: 58px;
}
.content-slide .title{
    font-weight: 900;
    font-size: 150px;
    line-height: 150px;
}
.content-slide .title-slide{
    margin-top: 20px;
}
.content-style1{color: #ffffff;}
.content-style1 .desc-slide{
    font-size: 24px;
    margin-top: 15px;
}
.content-style1.text-center .desc-slide:after,
.content-style1.text-center .desc-slide:before{
    content: "";
    width: 30px;
    height: 1px;
    background-color: #ffffff;
    display: inline-block;
    margin: 0 10px;
    margin-bottom: 10px;
}
.content-style2 .title.fw900{
    font-size: 60px;
    font-weight: 900px;
}
.content-slide .button{
    color: inherit;
    margin-top: 35px;
}
.content-slide .title-slide img{display: inline-block;}
.content-style2{
    color: #ffffff;
}
.content-style2 .title{
    font-size: 52px;
    font-weight: 300;
    letter-spacing: -0.25px;
    color: inherit;
    text-transform: none;
}
.content-style2 .desc-slide{
    color: inherit;
    font-size: 24px;
}
.content-style2 .button{
    color: inherit;
    margin-top: 70px;
    padding: 12px 25px;
}
/*.content-style2 .button:hover{
    color: #e1571a;
}*/
.slide-home .full-height.container{
    position: relative;
}
.slide-home .item-homeslide{
    background-size: cover;
    background-position: top center;
    position: relative;
}
.imageslide-fullscreen .item-homeslide:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
}
.imageslide-fullscreen.no-overlay .item-homeslide::after{
	display: none;
}
.slide-home .slogan{display: inline-block;}
.slide-home.owl-carousel  img{
    width: auto;
}

/* --------------------------
            FOOTER
---------------------------*/
.footer{
    background-color:#47352F;
    color: #DCC3BB;
}
.footer .widget ul li a{
    color: #515151;
}
.footer a:hover,
.footer a:focus,
.footer .widget ul li a:hover,
.footer .widget ul li:focus{
    color: #e1571a;
    text-decoration: underline;
}
.footer-top{
    padding: 70px 0 35px 0;
}
.footer-top .footer-info{
    text-align: center;
    max-width: 767px;
    margin: 0 auto;
}
.footer-top .logo{
    margin-bottom: 40px;
    display: inline-block;
}
.footer-social a{
    width: 42px;
    height: 42px;
    line-height: 40px;
    border: 1px solid #515151;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    margin: 0 3px;
    color: #515151;
    text-align: center;
}
.footer-social a:hover,
.footer-social a:focus{
    background-color: #e1571a;
    border-color: #e1571a;
    color: #fff!important;
}
.footer-widget{
    padding-top: 15px;
}
.footer .widget{
    margin-bottom: 30px;
}
.footer .widget .widget-title{
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    display: table;
    border-bottom: 1px solid #3b3b3b;
    padding-bottom: 17px;
    margin-bottom: 30px;
}
.footer .widget .widget-title:after{
    display: none;
}
.footer .widget_text{
    padding-top: 8px;
}
.footer .widget_text h6{
    font-size: 12px;
    color: #e1571a;
}
.footer .widget ul li{
    list-style: none;
    line-height: 30px;
}
.footer-menu{
    padding: 0;
    float: right;
    padding: 20px 0;
}
.footer-menu li{
    list-style: none;
    display: inline-block;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 15px;
}
.footer-menu  a{
    color: #515151;
}
.footer-coppyright{
    padding-top: 20px;
    font-size: 18px;
    text-transform: uppercase;
}
.footer.style3 .widget .widget-title{
    margin-bottom: 20px;
}
.footer.style3 .footer-social{
    float: right;
}
.footer.style3 .footer-info .fa{
    width: 42px;
    height: 42px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    border: 1px solid #515151;
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.footer.style3 .footer-info .location,
.footer.style3 .footer-info .phone,
.footer.style3 .footer-info .email{
    overflow: hidden;
}
.footer-style2 .footer-widget{
    background-color: #1e1e1e;
    padding: 70px 0 60px;
}
.footer-style2 .footer-bottom{
    background-color: #191919;
}
.footer-style2 .logo{
    margin-bottom: 25px;
    display: inline-block;
}
.footer-style2 .footer-info{
    margin-bottom: 30px;
}
.footer-style2 .widget .widget-title{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin-bottom: 15px;
    border: none;
    padding: 0;
}
.footer-style2 .widget .widget-title:after{
    display: none;
}
.footer-style2 .form-newsletter{
    background: #242424;
}
.form-newsletter{
    position: relative;
    display: table;
    color: #464646;
}
.form-newsletter input[type="email"]{
    display: table-cell;
    width: 100%;
    background: transparent;
    padding: 10px 15px;
    border: none;
}
.form-newsletter > span{
    display: table-cell;
    width: 1%;
    vertical-align: middle;
    padding: 10px 15px;
}
.form-newsletter button{
    border: none;
    background: transparent;
    padding: 0;
}
.form-newsletter > span button .fa{font-size: 18px;}
.footer-social.style2 a{
    width: 39px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    border: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
}
.footer-social.style2 .ss-facebook{
    background-color: #3b5997;
}
.footer-social.style2 .ss-twitter{
    background-color: #2fc2ee;
}
.footer-social.style2 .ss-google{
    background-color: #df4c3c;
}
.footer-social.style2 .ss-pinterest{
    background-color: #e02329;
}

.footer-style2 .widget ul li a{color: inherit;}
.footer-style2 .widget ul li a:hover{
    color: #e1571a;
}
.footer-style2 .widget_nav_menu ul li{line-height: 30px}
.footer-style2 .bz_widget_instagram .title-widget{
    margin-bottom: 30px;
}
.footer-style2 .footer-menu a{
    color: inherit;
}
.footer-style2 .footer-menu a:hover{
    color: #e1571a;
}
/* LASTEST POST */
.list-latest-news li{
    display: inline-block;
    width: 100%;
    padding: 15px 0;
}
.list-latest-news li .post-img{
    float: left;
    margin-right: 25px;
}
.list-latest-news li .post-img img{
    max-width: 70px;
}
.list-latest-news li h3.title-post{
    font-size: inherit;
    font-weight: normal;
    color: inherit;
    overflow: hidden;
}
.list-latest-news li h3.title-post a{color: inherit;}
.list-latest-news li h3.title-post a:hover{color: #e1571a}

/*  ----------------------
        RESTAURANT
------------------------- */
.content-res .title{
    font-family: 'Dancing Script', cursive;
    font-size: 110px;
    color: #ffffff;
    text-transform: none;
    line-height: normal;
    margin: 0;
}
.slide-home .slogan-sl{display: inline-block;}
.slide-home .slogan-sl img{width: auto;}
.content-res .desc-slide{font-size: 24px;color: #ffffff;}
.content-res .button{
    color: #ffffff;
    padding: 14px 45px;
    margin-top: 60px;
}
.content-res .button:hover{
    color: #e1571a;
}
.dancing-script{
    font-family: 'Dancing Script';
}
.bz-service-image.res-style-1 .title{
    position: relative;
    margin-bottom: 55px;
}
.bz-service-image.res-style-1 .title:after{
    content: "";
    height: 1px;
    width: 84px;
    background-color: #e8e8e8;
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
}
.bz-service-image.res-style-1 .title:before{
    content: "";
    height: 1px;
    width: 123px;
    background-color: #e8e8e8;
    display: block;
    position: absolute;
    bottom: -24px;
    left: 0;
}
.section-title.title-styleres h1{
    font-size: 45px;
}
.section-title.title-styleres{margin-bottom: 45px;}
.section-title.title-styleres .title{
    font-family: 'Dancing Script';
    position: relative;
    text-transform: none;
}
.section-title.title-styleres .title:after{
    content: "";
    position: absolute;
    bottom: -10px;
    top: auto;
    height: 1px;
    width: 30px;
    background-color: #e8e8e8;
    margin: 0;
    display: block;
}
.section-title.title-styleres .title:before{
    content: "";
    position: absolute;
    top: auto;
    bottom: -14px;
    height: 1px;
    width: 65px;
    background-color: #e8e8e8;
    display: block;
    margin: 0;
}
.section-title.title-styleres.text-center .title:after,
.section-title.title-styleres.text-center .title:before{
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}
.section-title.title-styleres.text-left .title:after,
.section-title.title-styleres.text-left .title:before{
    left: 0;
}
.section-title.title-styleres.text-right .title:after,
.section-title.title-styleres.text-right .title:before{
    right: 0;
}
.bz-service-image.service-resstyle{
    margin-bottom: 30px;
}
.service-resstyle .title{
    font-size: 30px;

    font-style: italic;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    font-weight: normal;
}
.service-resstyle .sub-title{
    font-size: 18px;
    color: #e1571a;
    font-family: 'Dancing Script';
    margin-bottom: 5px;
}
.bz-service-image.service-resstyle figure{margin-bottom: 15px}
.portfolio-resstyle .pf-title,
.portfolio-resstyle.pf-hover2 .pf-title{
    padding-right: 85px;
    color: #303030;
}
.portfolio-resstyle .pf-desc{
    font-family: 'Playfair display';
    font-style: italic;
    font-size: 14px;
    color: #e1571a;
    padding-right: 85px;
}
.portfolio-resstyle .pf-price{
    font-size: 24px;
    font-weight: 500;
    position: absolute;
    right: 30px;
    top: 50%;
    color: #e1571a;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.portfolio-resstyle .pf-info{
    padding: 15px 25px;
}
/* Menu Res tab */
.bz-tab-menuweek .resp-tabs-list{
    margin-bottom: 0;
    text-align: center;
}
.bz-tab-menuweek .resp-tabs-list li{

    font-size: 16px;
    font-style: italic;
    display: inline-block;
    padding: 20px 15px;
    font-weight: normal;
    border: none;
    float: none;
    text-transform: none;
    position: relative;
}
.bz-tab-menuweek .resp-tabs-list li.resp-tab-active{
    padding: 20px 15px!important;
    margin: 0;
    color: #e1571a;
}
.bz-tab-menuweek .resp-tabs-list li.resp-tab-active:after{
    content: "";
    border-top: 10px solid #ffffff;
    border-bottom: 10px solid transparent;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    left: 50%;
    bottom: -20px;
    z-index: 2;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}
.bz-tab-menuweek .resp-tabs-container{
    background-image: url(/images/restaurant/res-parallax1.jpg);
    padding: 100px 0 120px;
}
.bz-tab-menuweek .resp-tabs-container:after{
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.9);
    position: absolute;
}
.bz-tab-menuweek .resp-tabs-container > h2,
.bz-tab-menuweek .resp-tabs-container > div{
    position: relative;
    z-index: 1;
}
.resmenu-title{
    font-family: 'Dancing Script';
    font-size: 30px;
    margin-bottom: 10px;
}
.resmenu-title:after{
    content: "";
    height: 2px;
    width: 30px;
    background-color: #363636;
    display: block;
    margin-top: 15px;
}
.resmenu-list li{
    list-style: none;
    border-bottom: 1px solid #c2bb9f;
    padding: 30px 0 15px 0;
    overflow: hidden;
}
.resmenu-list li .info-item{
    float: left;
    width: 75%;
}
.resmenu-list li .info-item .title-item{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.resmenu-list li .info-item .desc-item{

    font-style: italic;
    margin-bottom: 0;
    font-size: 14px;
}
.resmenu-list li .price-item{
    float: right;
    font-size: 24px;
    font-weight: 500;
    color: #303030;
    width: 25%;
    margin-top: 20px;
    text-align: right;
}
.bz-tab-menuweek .resp-accordion{
    font-family: "Playfair Display";
    font-size: 16px;
    font-style: italic;
    font-weight: normal;
    line-height: 30px;
}
.bz-tab-menuweek .resp-accordion .resp-arrow{
    border-top-width: 8px;
    margin-top: 10px;
}
.bz-tab-menuweek h2.resp-tab-active{
    color: #e1571a;
    background-color: #ffffff!important;
}
.bz-tab-menuweek h2.resp-tab-active span.resp-arrow{
    border-bottom-width: 8px;
    border-bottom-color: #e1571a;
}
/* MAKE RESERVATION */
.info-resv{
    text-align: center;
    margin-bottom: 55px;
}
.info-resv .open-hours{
    text-align: center;
}
.info-resv .open-hours{
    display: inline-block;
    background-color: #e1571a;
    color: #ffffff;

    font-style: italic;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    margin-bottom: 15px;
}
.info-resv .open-hours span{
    vertical-align: top;
    margin: 0 15px;
}
.info-resv .open-hours:after,
.info-resv .open-hours:before{
    content: "";
    display: inline-block;
}
.info-resv .open-hours:after{
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid #ffffff;
}
.info-resv .open-hours:before{
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 6px solid #ffffff;
    border-right: 6px solid transparent;
}
.info-resv .time-open{
    display: block;
    font-size: 14px;
    margin-bottom: 15px;
}
.info-resv .phone-number{
    display: block;
    font-size: 48px;
    font-family: 'Dancing Script';
    line-height: normal;
}
.form-resv input, .form-resv textarea{
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 20px;
    font-size: 14px;
}
.form-resv textarea{margin-top: 25px}
.form-resv input[type="submit"]{
    width: auto;
    background: #e1571a;
    border: 1px solid #e1571a;
    padding: 15px 25px;
    text-transform: uppercase;
    color: #ffffff;
    display: block;
    margin: 20px auto 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.form-resv p span{display: block;}
.form-resv input[type="submit"]:hover{
    background: transparent;
    color: #e1571a
}
/*--- Count down even ----*/
.bz-countdown:after,
.bz-countdown:before{
    content: "";
    clear: both;
    display: table;
}
.bz-countdown .item-countdown{
    display: inline-block;
    text-align: center;
    float: left;
    width: 25%;
    padding: 0 15px;
}
.item-countdown .number{
    font-weight: 300;
    font-size: 60px;
    color: #ffffff;
    display: block;
    line-height: normal;
}
.item-countdown .title{
    color: #e1571a;
    font-family: 'Dancing Script';
    font-size: 30px;
}
/*---- List comming even ----*/
.bz-list-even .item-even{
    padding: 30px 0 25px;
    border-bottom: 1px solid #e8e8e8;
    overflow: hidden;
    font-size: 14px;
    display: table;
    width: 100%;
}
.bz-list-even .item-even .meta-even{
   display: table-cell;
   vertical-align: middle;
    text-align: right;
}
.bz-list-even .img-even{
    display: table-cell;
    width: 70px;
}
.bz-list-even .title-even{
    display: table-cell;
    text-transform: uppercase;
    font-size: inherit;
    margin: 0;
    vertical-align: middle;
    text-align: left;
    padding-left: 20px;
}
.bz-list-even .title-even a{color: inherit;}
.bz-list-even .img-even img{
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.meta-even li{
    list-style: none;
    display: inline-block;
    margin-left: 13px;
}
.meta-even li .fa{margin-right: 10px;}



.testimonials.light{
    color: #ffffff;
}
.testimonials-style3.navigation-center-center .owl-nav{
    text-align: center;
}
.testimonials-style3.light .owl-nav > div{
    background-color: #000000;
    border-color: #ffffff;
    margin: 0 5px;
    opacity: 0.3;
}
.testimonials-style3.light .owl-nav > div:hover{
    opacity: 1;
}
.testimonials-style3.light .testimonial .inner span{
    font-style: italic;

    color: #e1571a;
    font-size: 14px;
    letter-spacing: 1px;
}
.testimonials-style3.light .testimonial h6{
    color: inherit;
    margin-bottom: 0;
}
.lastest-post.slide-lastest .blog-item{
    text-align: left;
    color: #ffffff;
}
.slide-lastest .blog-item{position: relative;margin: 0}
.slide-lastest .blog-item .info-post{
    position: absolute;
    background-color: rgba(225,87,26,0.9);
    bottom: 0;
    padding: 20px 25px;
}
.slide-lastest .blog-item .blog-title {
    color: inherit;
    margin-bottom: 5px;
}
.slide-lastest .blog-item .blog-title a{
    color: inherit;
}
.slide-lastest .blog-item .meta-post{
    border: none;
    margin: 0;
}
.slide-lastest .content-post p{
    margin-bottom: 10px;
}
.slide-lastest .owl-nav{
    position: absolute;
    right: 25px;
    bottom: 20px;
}
.slide-lastest .owl-nav > div{
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 16px;
    border-color: rgba(232,232,232,0.3);
    background-color: transparent;
    margin: 0 5px;
    color: #ffffff;
}
.slide-lastest .owl-nav > div:hover{
    background-color: #ffffff;
    border-color: #ffffff;
    color: #e1571a;
}

/*--------------------------
        BLOG
--------------------------*/
.banner{
		background-size: cover;
		height: auto;
		top: 120px;

}
.banner-blog{
    background-image: url('/images/b/banner-blog.jpg');
}
.blog-item{
    text-align: center;
    margin-bottom: 30px;
}
.blog-item .meta-post{
    text-align: left;
    padding-top: 17px;
    margin-bottom: 20px;
    overflow: hidden;
}
.blog-item .meta-post .post-cat{
    font-family: 'PlayfairDisplay';
    font-size: 18px;
    font-style: italic;
    display: inline-block;
    width: auto;
    float: left;
}
.blog-item .meta-post .date-post{
    display: inline-block;
    float: right;
}
.blog-item .blog-title>a{
    color: #303030;
}
.blog-item .blog-title>a:hover{
    color: #d56f3f;
}

.blog-item .post-slide-format{
    position: relative;
}
 .blog-item .post-slide-format .owl-controls{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
 }
.blog-item .post-slide-format .owl-controls .owl-prev,
.blog-item .post-slide-format .owl-controls .owl-next{
    margin: 0 5px;
    background-color: #fff;
    border-color: #fff;
    color: #303030;
}
.blog-item .post-slide-format .owl-controls .owl-prev:hover,
.blog-item .post-slide-format .owl-controls .owl-next:hover{
    background-color: #d56f3f;
    border-color: #d56f3f;
    color: #fff;
}
.blog-item .readmore-button{
    padding: 6px 32px;
}
.blog-item .post-video-format .icon-fomat{
    width: 40px;
    height: 40px;
    background-color: #fff;
    position: absolute;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    line-height: 40px;
    font-size: 16px;
    text-align: center;
}
.blog-item .post-video-format .icon-fomat:before{
    content: "\f04b";
    font-family: 'FontAwesome';
}
.blog-item .post-link-format .icon-wapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    text-align: center;
}
.blog-item .post-link-format .icon-wapper .icon-fomat:before{
    content: "\f0c1";
    font-family: 'FontAwesome';
    font-size: 16px;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 46px;
    border: 2px solid #fff;
     -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
}
.blog-item .post-link-format .link{
    font-family: 'PlayfairDisplay';
    font-size: 18px;
    font-style: italic;
    color: #fff;
    display: table;
    margin: 0 auto;
    margin-top: 25px;
}

/* Blog maronry */
.blog-masonry {
    margin: 0 -15px;
}
.blog-masonry .blog-item{
    padding: 0 15px;
    margin-bottom: 30px;
}
.blog-masonry.style2{
    margin: 0;
}
.blog-masonry.style2 .blog-item{
    padding: 0;
}
.blog-item .post-format{
    position: relative;
}
.blog-item .hover-format{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(225,87,26,0.8);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.blog-item:hover .hover-format{
    opacity: 1;
    filter: alpha(opacity=100);
}
.post-format .hover-format span {
    color: #303030;
    background-color: #fff;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
    -ms-transform: translateX(-50%) translateY(-50%) scale(0);
    -o-transform: translateX(-50%) translateY(-50%) scale(0);
    transform: translateX(-50%) translateY(-50%) scale(0);
}
.blog-item:hover .hover-format span {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
    -ms-transform: translateX(-50%) translateY(-50%) scale(1);
    -o-transform: translateX(-50%) translateY(-50%) scale(1);
    transform: translateX(-50%) translateY(-50%) scale(1);
}

.blog-item.style2 .post-cat{
    padding: 7px 30px;
    background-color: rgba(236,107,107,0.8);
    width: auto;
    display: inline-block;
    position: absolute;
    bottom: -18px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
}
.blog-item.style2{
    padding-bottom: 20px;
    background-color: #fff;
}
.blog-item.style2 .post-cat a{
    color: #fff;
    font-weight: 400;
}
.blog-item.style2 .meta-post{
    text-align: center;
}
.blog-item.style2 .meta-post .date-post{
    float: none;
}
.blog-item.style2 .blog-title{
    margin-top: 45px;
}
.blog-item.style2 .inner{
    padding: 0 20px;
}
.blog-item.style2 .post-slide-format .owl-controls{
    position: static;
}
.blog-item.style2 .post-slide-format .owl-controls .owl-prev,
.blog-item.style2 .post-slide-format .owl-controls .owl-next{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.blog-item.style2 .post-slide-format .owl-controls .owl-prev{
    left: 20px;
}
.blog-item.style2 .post-slide-format .owl-controls .owl-next{
    right: 30px;
}
.blog-item.style2 .post-slide-format:hover .owl-controls .owl-prev,
.blog-item.style2 .post-slide-format:hover .owl-controls .owl-next{
    opacity: 1;
    filter: alpha(opacity=100);
}
.blog-item.style3{
    position: relative;
    margin: 0;
}
.blog-item.style3 .post-inner{
    color: #fff;
    text-align: center;
    z-index: 2;
    padding: 75px 60px;
    position: relative;
}
.blog-item.style3 .overlay{
    background-color: rgba(46,46,46,0.6);
    z-index: 1;
}
.blog-item.style3{
    background-repeat: no-repeat;
    background-size: cover;
}
.blog-item.style3.bg1{
    background-image: url(/images/blogs/b23.jpg);
}
.blog-item.style3.bg2{
    background-image: url(/images/blogs/b24.jpg);
}
.blog-item.style3.bg3{
    background-image: url(/images/blogs/b25.jpg);
}
.blog-item.style3.bg4{
    background-image: url(/images/blogs/b26.jpg);
}
.blog-item.style3.bg5{
    background-image: url(/images/blogs/b27.jpg);
}
.blog-item.style3.bg6{
    background-image: url(/images/blogs/b28.jpg);
}

.blog-item .meta-post{
    text-align: center;
}
.blog-item.style3 .post-cat{
    margin: 0 auto;
    float: none;
}
.blog-item.style3 .post-cat:after{
    content: '';
    width: 45px;
    height: 3px;
    background-color: #fff;
    display: block;
    margin: 0 auto;
    margin-top: 25px;
    font-size: 18px;
    font-family: 'PlayfairDisplay';
    font-style: italic;
}
.blog-item.style3 .post-cat a,
.blog-item.style3 .blog-title>a{
    color: #fff;
}
.blog-item.style3 .post-cat a:hover,
.blog-item.style3 .blog-title>a:hover{
    color: #e1571a;
}
.blog-item.style3 .blog-title{
    font-size: 18px;
    margin-top: 40px;
}
.blog-item.style3 .readmore-button{
    border-color: #fff;
    color: #fff;
}
.blog-item.style3 .readmore-button:hover{
    border-color: #e1571a;
}


.blog-item.style5{
    text-align: left;
}
.blog-item.style5 .meta-post{
    padding: 10px 0;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    font-size: 12px;
}
.blog-item.style5 .meta-post .post-cat{
    font-family: inherit;
    font-style: inherit;
    font-size: 12px;
    color: #515151;
}
.blog-item.style5 .meta-post .post-cat :before{
    content: "\f02b";
    font-family: 'FontAwesome';
    margin-right: 10px;
}
.blog-item.style5 .meta-post .post-cat a{
    color: #515151;
}
.blog-item.style5 .blog-title{
    margin-top: 18px;
    font-size: 18px;
}
.blog-item.style5 .readmore-button{
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
}
.blog-item.style6 .blog-title{
    margin: 30px 0;
}
.blog-item.style6 .meta-post{
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}
.blog-item.style6 .meta-post .post-cat{
    margin: 0 auto;
    float: none;
}
.blog-item.style6 .meta-post .post-cat:after{
    content: '';
    width: 15px;
    height: 2px;
    background-color: #e8e8e8;
    display: block;
    margin: 0 auto;
    margin-top: 20px;

}
.blog-item.style6  .post-slide-format .owl-controls .owl-prev,
.blog-item.style6  .post-slide-format .owl-controls .owl-next{
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    line-height: 50px;
    border: 1px solid #fff;
    color: #fff;
    margin: 0;
    background-color: transparent;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.blog-item.style6  .post-slide-format .owl-controls .owl-prev:hover,
.blog-item.style6  .post-slide-format .owl-controls .owl-next:hover{
    border-color: #d56f3f;
    background-color: #d56f3f;
}
.blog-item.style6  .post-slide-format .owl-controls .owl-prev{
    left: 30px;
}
.blog-item.style6  .post-slide-format .owl-controls .owl-next{
    right: 30px;
}
.blog-item.style6 .post-slide-format .owl-controls{
    position: static;
}

.blog-item.style6  .footer-post{
    padding: 10px 0;
    border-top: 1px solid #DCC3BB;
    border-bottom: 1px solid #DCC3BB;
}
.blog-item.style6  .footer-post span{
    margin: 0 5px;
    display: inline-block;
}
.blog-item.style6  .readmore-button{
    padding: 0;
    border: none;
    background-color: transparent;
    float: right;
}
.blog-item.style6  .readmore-button:hover{
    color: #e1571a;
}
.blog-list .blog-item{
    margin-bottom: 30px;
}
/* ------BLOG SINGLE--------- */
.blog-single .blog-item{
    text-align: inherit;
}
.blog-single .content-post{
    padding-top: 30px;
}
.blog-single .blog-item .meta-post .post-cat{
    float: left;
}
.blog-single .blog-item .meta-post .post-cat:after{
    display: none;
}
.blog-single .bottom-post{
    margin-top: 70px;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;
    overflow: hidden;
}
.blog-single .bottom-post .tags{
    display: inline-block;
    float: left;
}
.blog-single .bottom-post .tags span{
    padding-right: 20px;
}
.blog-single .bottom-post .tags a{
    color: #303030;
}
.blog-single .bottom-post .tags a:hover{
    color: #e1571a;
}
.blog-single .bottom-post .groupshare{
    display: inline-block;
    float: right;
}
.bottom-post .groupshare li{
    list-style: none;
    display: inline-block;
    float: left;
}
.groupshare ul li a {
    color: inherit;
    width: 25px;
    height: 25px;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    font-size: 12px;
    color: #fff;
    margin-left: 6px;
}
.groupshare ul li a .fa{
    width: 100%;
    width: 25px;
    height: 26px;
    background-color: #3b5997;
    display: inline-block;
    line-height: 26px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.groupshare ul li a .fa-google-plus{
    background-color: #df4c3c;
}
.groupshare ul li a .fa-twitter{
    background-color: #2fc2ee;
}
.groupshare ul li a .fa-pinterest-p{
    background-color: #e02329;
}
.groupshare ul li a:hover .fa{
    background-color: #e1571a;
}
.post-author{
    margin-bottom: 80px;
}
.post-author .author-img{
    float: left;
    margin-right: 25px;
}
.post-author .author-img img{
    width: 100px;
    height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.post-author .author-content{
    overflow: hidden;
    font-size: 12px;
}
.post-author .author-content > h3{
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: 500;
}
.post-author .author-content > h3 > a{
    font-weight: bold;
}
.post-author .author-content > h3:after{
    content: "";
    width: 30px;
    height: 2px;
    background-color: #e8e8e8;
    display: block;
    margin-top: 15px;
}
.post-author .author-content p{color: #303030;margin-bottom: 15px}
/*------ Comment list ----- */
.comments-title,
.leave-reply > h4,
.related-posts-title{
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 40px;
}
.leave-reply > h4{
    margin-bottom: 20px;
}
.comments-title:after,
.leave-reply > h4:after,
.related-posts-title:after{
    content: '';
    width: 12px;
    height: 2px;
    display: block;
    background-color: #df4c3c;
    margin-top: 10px;
}
.comments-area ol li.comment {
    list-style: none;
}
.comments-area ol li.comment .children{
    padding-left: 100px;
}
.comment .comment-body {
    margin-bottom: 30px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
}
.comment .comment-author{
    width: 70px;
    float: left;
}
.comment .comment-content{
    margin-left: 100px;
}
.comment .author-name{
    font-size: 12px;
    text-transform: uppercase;
    width: auto;
    display: inline-block;
    margin-bottom: 5px;
}
.comment .comment-reply-link{
    text-transform: uppercase;
    color: #303030;
}
.comment .comment-reply-link:hover{
    color: #df4c3c;
}
.loadmore-comment{
    padding: 13px 0;
    border: 1px solid #e8e8e8;
    text-align: center;
    color: #303030;
    display: inline-block;
    margin-top: 35px;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 35px;
}
.loadmore-comment a{
    color: #303030;
}
.leave-reply .comment-form input[type="text"],
.leave-reply .comment-form input[type="email"] {
    max-width: 565px;
    width: 100%;
}
.leave-reply .comment-form label {
    display: block;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 10px;
    color: #515151;
}
.leave-reply .comment-form label span {
    color: #e1571a;
}
.leave-reply .comment-form .input-group {
    margin-bottom: 15px;
    display: block;
}
.leave-reply .comment-form textarea {
    width: 100%;
    min-height: 220px;
}
.related-posts{
    margin-top: 50px;
}
.related-posts .blog-title{
    font-size: 18px;
    margin-bottom: 10px;
}
.related-posts .blog-item .meta-post{
    margin: 0;
    padding: 0;
}
.related-posts .blog-item .content-post{
    margin-top: 10px;
    padding: 0;
}
.related-posts .blog-item .content-post p{
    margin-bottom: 15px;
}
.related-posts .blog-item .readmore-button{
    float: right;
}
.block-slide-text{
    color: #fff;
    margin-top: -205px;
}
.block-slide-text .title,
.block-slide-text .subtitle{
    color: #fff;
}
.block-slide-text .button{
    margin-top: 50px;
}
.block-slide-content{
    padding-top: 200px;
}
.block-slide-text.owl-carousel  .owl-next,
.block-slide-text.owl-carousel  .owl-prev{
    width: 56px;
    height: 56px;
    border: 1px solid #fff;
    color: #fff;
    background-color: transparent;
    line-height: 54px;
    position: absolute;
    top: 60%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.block-slide-text.owl-carousel  .owl-next:hover,
.block-slide-text.owl-carousel  .owl-prev:hover{
    border-color: #e1571a;
    color: #e1571a;
}
.block-slide-text.owl-carousel  .owl-next{
    right: 80px;
}
.block-slide-text.owl-carousel  .owl-prev{
    left: 80px;
}
/*-----------------------
        WIDGET
-----------------------*/
.widget ul li a {
    color: #303030;
}
.widget ul li a:hover {
    color: #e1571a;
}
.bz_widget_instagram .list-photo{
    margin-left: -7px;
    margin-right: -7px;
    margin-top: -7px;
}
.bz_widget_instagram .list-photo li{
    padding:7px;
    float: left;
    width: 33.33333333%;
}
.bz_widget_instagram img{
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.bz_widget_instagram img:hover{
    opacity: 0.8;
}
.bz_widget_tiwtter .twitter-item{
    margin-bottom: 20px;
}
.bz_widget_tiwtter .twitter-item:last-child{
    margin-bottom: 0;
}
.bz_widget_tiwtter .twitter-item .twitter-content{
    margin-bottom: 5px;
}
.bz_widget_tiwtter .twitter-item .twitter-content:last-child{
    margin-bottom: 0;
}

.widget{
    margin-bottom: 35px;
}
.widget:last-child{
    margin-bottom: 0;
    text-align: center;
}

.widget ul{
    padding: 0;
    margin: 0;
}
.widget ul li {
    list-style: none;
}
.widget_product_categories li,
.widget_layered_nav li{
    line-height: 36px;
    text-transform: uppercase;
}
.widget_product_categories li a,
.widget_layered_nav li a{
    color: #303030;
    font-weight: 600;
}
.widget_product_categories li a:hover,
.widget_product_categories li.current-cat a,
.widget_layered_nav li a:hover{
    color: #e1571a;
}

.tagcloud{
    overflow: hidden;
}
.tagcloud a{
    height: 35px;
    line-height: 35px;
    font-size: 12px!important;
    color: #515151;
    display: inline-block;
    float: left;
    margin:10px 10px 0 0;
    padding: 0 12px;
    background-color: #f3f3f3;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
}
.tagcloud a:hover{
    color: #fff;
    background-color: #e1571a;
}
.widget_price_filter .price_slider_wrapper{
    padding-top: 25px;
    padding-bottom: 15px;
}
.widget_price_filter .slider-range-price{
    position: relative;
    background: #e1571a;
    height: 6px;
}
.widget_price_filter .ui-widget-header{
    height: 6px;
    background: #e8e8e8;
    position: absolute;
}
.widget_price_filter .ui-slider-handle{
    width: 25px;
    height: 15px;
    background: #868686;
    display: inline-block;
    top: -5px;
    position: absolute;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
}
.widget_price_filter .amount-range-price{
    font-size: 10px;
    text-align: center;
    padding-bottom: 15px;
    color: #303030;
    text-transform: uppercase;
    font-weight: 600;
}
.widget_price_filter .button{
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
}
.yith-woocompare-widget .no-product{
    line-height: 24px;
    text-align: center;
    padding: 45px 0;
    margin-top: 24px;
    color: #303030;
}
.widget_product_top_sale ul{
    padding: 0;
    margin-top: 40px;
}
.widget_product_top_sale ul li{
    list-style: none;
    padding-bottom: 30px;
    overflow: hidden;
}
.widget_product_top_sale ul li:last-child{
    padding-bottom: 0;
}
.widget_product_top_sale .p-thumb{
    width: 100px;
    height: 70px;
    float: left;
    padding-right: 30px;
}
.widget_product_top_sale .p-name{
    font-size: 12px;
    font-weight: 500;
}
.widget_product_top_sale .p-name a{
    color: #303030;
}
.widget_product_top_sale .p-name a:hover{
    color: #e1571a;
}
.widget_product_top_sale .price{
    font-size: 14px;
}

.widget_search .screen-reader-text,
.widget_product_search .screen-reader-text{
    display: none;
}
.widget_search form,
.widget_product_search form{
    position: relative;
}
.widget_search .search-field,
.widget_product_search .search-field{
    width: 100%;
    padding: 9px 20px;
    color: #303030;
}
.widget_search .button-submit,
.widget_product_search .button-submit{
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    background-color: transparent;
    border: none;
    text-align: center;
    line-height: 45px;
    font-size: 0;
}
.widget_search .button-submit:before,
.widget_product_search .button-submit:before{
    font-family: 'FontAwesome';
    content: "\f002";
    font-size: 12px;
    line-height: inherit;
}
.blog-slidebar .widget{
    border: 2px solid #DCC3BB;
    padding: 30px 20px;
}
.blog-slidebar .widget .widget-title:after{
    background-color: #e1571a;
}
.blog-slidebar .widget .widget-title{
    border: none;
    padding-bottom: 15px;
}
.widget_categories li{
    line-height: 49px;
    border-bottom: 1px solid #e8e8e8;
}
.widget_categories li:last-child{
   border-bottom: none;
}
.widget_categories li>a{
    color: #333;
    padding-left: 10px;
    display: inline-block;
}
.widget_categories li>a:before{
    content: "\f105";
    font-family: 'FontAwesome';
    padding-right: 0;
    opacity: 0;
    visibility: hidden;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 0px;
}
.widget_categories li>a:hover:before,
.widget_categories li.current-cat>a:before{
    opacity: 1;
    width: auto;
    filter: alpha(opacity=100);
    padding-right: 10px;
    visibility: inherit;
}
.widget_categories li>a:hover{
    color: #e1571a;
}
.widget_categories li ul{
    padding-left: 20px;
    border-top: 1px solid #e8e8e8;
}
.widget_categories li ul li{
    padding-left: 0;
}
.benz-photoflick .list-photo{
    margin: 0 -8px;
    overflow: hidden;
}
.benz-photoflick .list-photo li{
    padding: 0 8px;
    margin-bottom: 16px;
    width: 33.333333%;
    float: left;
}
.benz-photoflick .list-photo li a {
    margin-left: 0;
    display: inline-block;
    position: relative;
}
.benz-photoflick .list-photo li a:before {
    content: "\f002";
    color: #ffffff;
    font-family: 'FontAwesome';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%) scale(0);
    -ms-transform: translateY(-50%) translateX(-50%) scale(0);
    -o-transform: translateY(-50%) translateX(-50%) scale(0);
    transform: translateY(-50%) translateX(-50%) scale(0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    font-size: 13px;
    z-index: 2;
}
.benz-photoflick .list-photo li a:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255,87,26,0.8);
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 1;
}
.benz-photoflick .list-photo li:hover a:before {
    -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
    -ms-transform: translateY(-50%) translateX(-50%) scale(1);
    -o-transform: translateY(-50%) translateX(-50%) scale(1);
    transform: translateY(-50%) translateX(-50%) scale(1);
}
.benz-photoflick .list-photo li:hover a:after {
    opacity: 1;
    filter: alpha(opacity=100);
}
.benz-recentpost .recent_posts_list li {
    text-transform: none;
    overflow: hidden;
    padding: 10px 0;
    line-height: inherit;
}
.recent_posts_list li .post-img {
    float: left;
    width: 70px;
}
.recent_posts_list li .post-title {
    display: block;
    padding-left: 85px;
    font-size: 14px;
    margin: 0;
}
.recent_posts_list li .post-cat {
    display: block;
    padding-left: 85px;
}
.recent_posts_list li .meta{
    display: block;
    padding-left: 85px;
}
.widget_about_me .widget-content{
    text-align: center;
}
.widget_about_me .about-img img{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.widget_about_me .about-img{
    padding-bottom: 30px;
}
.widget_about_me a{
    color: #333;
}
.widget_about_me a:hover{
    color: #e1571a;
}
.widget_about_me .social{
    margin-top: 15px;
}
.widget_about_me .social a{
    margin: 0 5px;
    font-size: 14px;
}
/*----------------------
FAQ PAGE
-----------------------*/
.banner-header.banner-faq{
    background-image: url(/images/b/banner-faq.jpg);
}
/*----------------------
CONTACT PAGE
-----------------------*/
.banner-header.banner-contact{
    background-image: url(/images/b/banner-contact.jpg);
}
.form-contact input[type="text"],
.form-contact input[type="email"],
.form-contact input[type="password"],
.form-contact input[type="number"],
.form-contact textarea{
    width: 100%;
}
abbr[title].required {
    color: #e92f2f;
    border: none;
}
.form-contact label{
    font-weight: 500;
    margin-bottom: 10px;
}
.form-contact2.form-contact input[type="text"]:focus,
.form-contact2.form-contact input[type="email"]:focus,
.form-contact2.form-contact input[type="password"]:focus,
.form-contact2.form-contact input[type="number"]:focus,
.form-contact2.form-contact textarea:focus{
    outline: 2px solid #e1571a;
}
.contact-social{
    padding-top: 50px;
}
.google-map{
    overflow: hidden;
    min-height: 300px;
}
.google-map iframe{
    width: 100%;
    border: none;
}
.contact-block1{
    padding: 80px 0 40px 0;
}
.contact-block2{
    padding: 50px 0 175px 0;
}
/* ----------------------
        SHOP
-----------------------*/
.banner-header.banner-shop{
    background-image: url(/images/b/banner-shop.jpg);
}
.left-slidebar .main-content{
    float: right;
}
/*---- PRODUCT -------*/
.product{
    /*padding-bottom: 15px;*/
    list-style: none;
}
.product .product-thumb{
    position: relative;
    max-height: 263px;
    overflow: hidden;

}
.product .product-thumb > a:before{
    content: "";
    position: absolute;
    padding: 10px 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 1;
}
.product:hover .product-thumb > a:before{
    opacity: 1;
    filter: alpha(opacity=100);
}
.product .product-button{
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-transform:scale(0);
    -ms-transform:scale(0);
    -o-transform:scale(0);
    transform:scale(0);

    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 2;
}
.product:hover .product-button{
    -webkit-transform:scale(1);
    -ms-transform:scale(1);
    -o-transform:scale(1);
    transform:scale(1);
}
.product .add_to_cart_button,
.product .added_to_cart {
    padding: 7px 42px;
    background-color: #DCC3BB;
    color: #47352F!important;
    text-transform: uppercase;
    display: table;
    margin: 50px auto;
    border: none;
    margin-bottom: 15px;
}
.product .add_to_cart_button:hover,
.product .add_to_cart_button:focus,
.product .added_to_cart:hover,
.product .added_to_cart:focus{
    background-color:#e1571a;
}
.product .add_to_cart_button.loading:before{
    font-family:'FontAwesome';
    content: "\f1ce";
    margin-right: 15px;
    webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}
.product .add_to_cart_button.added{
    display: none;
}
.product .added_to_cart:before{
    font-family:'FontAwesome';
    content: "\f00c";
    margin-right: 15px;
}
.product .yith-wcqv-button{
    border-color: #fff;
    font-size: 0;
    color: #fff;
    background-color: transparent;
    padding: 0;
    width: 60px;
    height: 38px;
    line-height: 36px;
}
.product .yith-wcqv-button:before{
    font-family:'FontAwesome';
    content: "\f002";
    font-size: 12px;
    line-height: inherit;
}
.product .yith-wcwl-add-to-wishlist{
    width: 60px;
    height: 38px;
    display: inline-block;
}
.product .yith-wcwl-add-to-wishlist .yith-wcwl-add-button,
.product .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse,
.product .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse{
    width: 60px;
    height: 38px;
    display: inline-block;
    font-size: 0;
    position: relative;
}
.product .yith-wcwl-add-to-wishlist a{
    width: 60px;
    height: 38px;
    display: inline-block;
    border: 1px solid #fff;
    line-height: 36px;
    color: #fff;
}
.product .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:before{
    font-family:'FontAwesome';
    content: "\f08a";
    font-size: 12px;
    line-height: inherit;
}
.product .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a.added-spinner:before{
    font-family:'FontAwesome';
    content: "\f1ce";
    webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}
.product .yith-wcwl-add-to-wishlist .yith-wcwl-add-button img.ajax-loading{
    display: none!important;
}
.product .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a:before,
.product .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a:before{
    font-family:'FontAwesome';
    content: "\f00c";
    font-size: 12px;
}
.product .compare-button{
    width: 60px;
    height: 38px;
    display: inline-block;
}
.product .compare-button .compare{
    font-size: 0px;
    color: #fff;
    width: 60px;
    height: 38px;
    line-height: 36px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-color: #fff;
}
.product .compare-button .compare:before{
    content: "\f074";
    font-family: 'FontAwesome';
    font-size: 12px;
    line-height: inherit;
}
.product .compare-button .compare.added:before{
    content: "\f00c";
}
.product .product.compare-button{
    padding: 0;
    margin: 0;
    float: none;
}
.product .star-rating{
    display: table;
    margin: 0 auto;
    color: #ebc52d;
    margin-top: 15px;
}
.product .star-rating .fa{
    margin: 0 2px;
}
.product .product-info{
    padding-top: 5px;
    padding-bottom: 10px;
    text-align: center;
}
.product .product-name{
    font-weight: 500;
}
.product .product-name a{
    color: #303030;
}
.product .product-name a:hover,
.product .product-name a:focus,
.product .product-name a:active{
    color: #e1571a;
}
.product .price ins{
    margin-right: 10px;
    text-align: center;
}
.product .price{
    font-size: 18px;
    color: #e1571a;
    line-height: 24px;
}

.price ins{
    text-decoration: none;
}
.price del{
    color: #868686;
}
/* PRODUCT PAGE */
.products .product{
    margin-bottom: 30px;
    padding: 15px 50px
}
.products.columns-2 .product:nth-child(2n+1){
    clear: both;
}
.products.columns-3 .product:nth-child(3n+1){

}
.products.columns-4 .product:nth-child(4n+1){
    clear: both;
}
.products.columns-5 .product:nth-child(5n+1){
    clear: both;
}
.product.style2{
    position: relative;
    padding: 0;
    overflow: hidden;
}
.product.style2 .product-info{
    position: absolute;
    bottom: -120px;
    left: 0;
    right: 0;
    padding: 15px 20px;
    z-index: 10;
    background-color: rgba(255,87,26,0.8);
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    visibility: hidden;
}
.product.style2:hover .product-info{
    bottom: 0;
    opacity: 1;
    visibility: inherit;
}
.product.style2 .product-name a,
.product.style2 .price{
    color: #fff;
    display: inline-block;
}
.product.style2 .price del{
    color: #ccc;
}
.product.style2 .product-button{
    top: 15%;
}

/* pagination */
.pagination{
    text-align: center;
    display: inherit!important;
    margin-top: 80px!important;
}
.pagination ul{
    padding: 0;
}
.pagination ul li{
    list-style: none;
    display: inline-block;
    padding: 10px 5px;
}
.pagination ul li a,
.pagination ul li span {
    height: 40px;
    line-height: 38px;
    border: 1px solid #47352F;
    padding: 0 15px;
    display: inline-block;
    color: #303030;
    font-size: 14px;
}
.pagination ul li.active a,
.pagination ul li a:hover,
.pagination ul li a:focus{
    color: #e1571a;
}
.functionBar{
    margin-bottom: 50px;
    display: inline-block;
    width: 100%;
    color: #303030;
}
.functionBar select{
    max-width: 170px;
}
.functionBar .functionBar-left,
.functionBar .functionBar-right{
    width: 50%;
    float: left;
}
.functionBar .chosen-container-single .chosen-single{
    height: 45px;
    line-height: 45px;
}
.functionBar .functionBar-right{
    text-align: right;
}
.functionBar .woocommerce-result-count{
    padding-top: 10px;
    margin-right: 15px;
    display: inline-block;
}
.functionBar .display-product-option{
    width: auto;
    display: inline-block;
    float: right;
    overflow: hidden;
}
.display-product-option a{
    width: 45px;
    height: 45px;
    border: 1px solid #e8e8e8;
    display: inline-block;
    color: #515151;
    font-size: 16px;
    text-align: center;
    line-height: 45px;
    float: left;
}
.display-product-option .view-as-grid{
    margin-right: 10px;
}
.display-product-option a:hover,
.display-product-option a.selected{
    color: #e1571a;
}
/*----- SINGLE PRODUCT-----*/
.summary{
    color: #303030;
}
.summary .product_title{
    margin: 0;
    color: #303030;
}
.summary .product-star{
    color: #ebc52d;
    width: auto;
    display: inline-block;
}
.summary .in-stock{
    float: right;
    font-size: 10px;
    text-transform: uppercase;
}
.summary .in-stock .fa{
    color: #f26600;
    font-size: 16px;
}
.summary .description{
   padding:30px 0;
}
.summary .description p{
    margin: 0;
}
.summary .product-meta{
    padding: 30px 0;
}
.summary .product-meta span{
    display: table;
}
.summary .product-meta a{
    color: #515151;
}
.summary .product-meta a:hover{
    color: #e1571a;
}
.summary .price{
    font-size: 30px;
    text-decoration: none;
    color: #e1571a;
    font-weight: 500;
    margin: 0;
    margin-top: 15px;
}
.summary .price ins{
    text-decoration: none;
    margin-right: 10px;
}
.summary .price del{
    color: #515151;
    font-size: 24px;
}
.summary  .variations_form {
    margin-top: 20px;
    padding: 30px 0;
    border-top: 1px dotted #DCC3BB;
    border-bottom: 1px dotted #DCC3BB;
    overflow: hidden;
}
.summary .variations{
    width: 100%;
}
.summary .variations td{
    padding: 10px 0;
}
.summary .variations .label{
    display: none;
}
.summary .variations select,
.summary .chosen-container-single .chosen-single{
    width: 100%;
    text-transform: uppercase;
}

.summary .chosen-container .chosen-drop{
    border: none;
}
.summary .variations select:focus{
    outline: none;
}
.summary .single_variation_wrap .box-qty,
.summary .single_variation_wrap .single_add_to_cart_button,
.summary .single_variation_wrap .buttom-compare,
.summary .single_variation_wrap .buttom-wishlist{
    width: auto;
    display: inline-block;
    float: left;
}
.summary .single_variation_wrap .box-qty{
    height: 45px;
    margin-right: 10px;
    border: 1px solid #e8e8e8;
    color: #47352F;
}
.summary .single_variation_wrap .box-qty a{
    color: #515151;
}
.summary .single_variation_wrap .box-qty .quantity-plus{
    float: right;
    line-height: 43px;
    border-left: 1px solid #e8e8e8;
    padding: 0 11px;
}
.summary .single_variation_wrap .box-qty .quantity-minus{
    float: left;
    line-height: 43px;
    border-right: 1px solid #e8e8e8;
    padding: 0 11px;
}
.summary .single_variation_wrap .box-qty input{
    height: 45px;
    background: none;
    border: none;
    text-align: center;
    width: 58px;
    padding: 0;
}
.summary .single_variation_wrap .box-qty input:focus{
    outline: none;

}
.summary .single_variation_wrap .single_add_to_cart_button {
    height: 45px;
    line-height: 43px;
    color: #47352F;
    text-transform: uppercase;
    background: #DCC3BB;
    padding: 0 30px;
    border: none;
    margin-right: 10px;
}
.summary .single_variation_wrap .single_add_to_cart_button:hover,
.summary .single_variation_wrap .single_add_to_cart_button:focus{
    opacity: 0.8;
}
.summary .single_variation_wrap .buttom-compare,
.summary .single_variation_wrap .buttom-wishlist{
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border: 1px solid #c9c9c9;
    margin-right: 10px;
}
.summary .single_variation_wrap .buttom-compare:hover,
.summary .single_variation_wrap .buttom-wishlist:hover{
    background: #e1571a;
    border-color:#e1571a;
    color: #fff;
}
.summary .product-share{
    text-transform: uppercase;
}
.summary .product-share a{
    margin-left: 10px;
    color: inherit;
    font-size: 16px;
}
.summary .product-share a:hover,
.summary .product-share a:focus{
    color: #e1571a;
}
.single-images{
    position: relative;
    max-width: 90%;
    padding-right: 50px;
}
.single-images .single-product-thumbnails{
    text-align: center;
    float: left;
    margin: 10px 0;
    padding: 0 10px;
}
.single-images .single-product-thumbnails img{
    opacity: 0.4;
    cursor: pointer;
}
.single-images .single-product-thumbnails span.selected img,
.single-images .single-product-thumbnails span:hover img{
    opacity: 1;
}
.group-banner-single-product a{
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
    float: left;
}
.group-banner-single-product a:last-child{
    margin-bottom: 0;
}
/** product-tabs **/
.product-tabs{
    padding-top: 70px;
    overflow: hidden;
    border-bottom: 1px dotted #e8e8e8;
}
.product-tabs .nav-tab{
    padding: 0;
    width: 100%;
    float: left;
    margin: 0;
}
.product-tabs .nav-tab li{
    list-style: none;
    display: inline;
    float: left;
    line-height: 24px;
    padding: 13px 0;
    margin-right: 30px;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
}
.product-tabs .nav-tab li:last-child{
    margin-right: 0;
}
.product-tabs .nav-tab li a{
    color: inherit;
}
.product-tabs .nav-tab li a:hover,
.product-tabs .nav-tab li.active a{
    color: #7e883a;
}
.product-tabs .nav-tab li:before{
    font-family: 'FontAwesome';
    content: "\f0dd";
    position: absolute;
    top: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #7e883a;
    display: none;
}
.product-tabs .nav-tab li:hover:before,
.product-tabs .nav-tab li.active:before{
    display: block;
}

.product-tabs .tab-content{
    float: left;
    border: 1px solid #c9c9c9;
    padding:40px 40px 30px 40px;
    width: 100%;
}

.product-tabs .reviews .comments>h2{
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
}
.product-tabs .comments .commentlist{
    margin-top: 25px;
}
.product-tabs .comments .comment{
    list-style: none;
    margin-bottom: 30px;
}
.product-tabs .comments .comment:last-child{
    border: none;
    padding-bottom: 0;
}
.product-tabs .comments .comment .avatar {
    width: 70px;
    float: left;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.product-tabs .comments .comment .comment-text{
    display: block;
    position: relative;
    margin-left: 90px;
    border: 1px solid #e8e8e8;
    padding: 30px 30px 20px 50px;
}
.product-tabs .comments .comment .comment-text:before{
    content: "";
    position: absolute;
    top: 28px;
    left: -12px;
    width: 0px;
    height: 0px;
    border-right: 12px solid #e8e8e8;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.product-tabs .comments .comment .comment-text:after{
    content: "";
    position: absolute;
    top: 29px;
    left: -11px;
    width: 0px;
    height: 0px;
    border-right: 12px solid #fff;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
}
.product-tabs .comments .comment .description{

    font-size: 14px;
    font-style: italic;
    color: #515151;
}
.product-tabs .comments .comment .description p{
    margin-bottom: 10px;
}
.product-tabs .comments .comment .comment-text .product-star{
    color: #EBC52D;
    font-size: 12px;
    position: absolute;
    bottom: 13px;
    right: 25px;
}
.product-tabs .comments .comment .comment-text .meta{
    margin-bottom: 0;
    text-transform: uppercase;
}
.product-tabs .comments .comment .comment-text .meta time{
    text-transform: none;
    margin-left: 10px;
}
.load-more-comment{
    display: inline-block;
    width: 100%;
    color: #515151;
    text-align: center;
}
.load-more-comment:after{
    display: block;
    border-bottom: 1px solid #e8e8e8;
    content: '';
    margin-top: -12px;
}
.load-more-comment span{
    background-color: #fff;
    display: inline-block;
    padding: 0 45px;
}
/* Review form */
.review_form_wrapper{
    margin-top: 45px;
    margin-top: 45px;
    overflow: hidden;
    padding-bottom: 25px;
}
.product-tabs .review_form .comment-reply-title{
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    padding-bottom: 15px;
}
.product-tabs .review_form .comment-reply-title:after{
    content: '';
    width: 10px;
    height: 3px;
    background-color: #e1571a;
    display: block;
    margin-top: 5px;
}
span.required {
    color: #e92f2f;
    border: none;
}
.product-tabs .review_form .rating{
    margin-bottom: 25px;
}
.product-tabs .review_form .rating .attribute{
    width: auto;
    display: inline-block;
    text-transform: uppercase;
    padding-right: 20px;
}
.product-tabs .review_form .rating .attribute .title{
    margin-right: 20px;
}
.product-tabs .review_form .rating .attribute .star{
    color: #ebc52d;
}
.product-tabs .review_form label{
    font-size: 12px;
    font-weight: 500;
}
.product-tabs .review_form p{
   margin-bottom: 20px;
    float: left;
    width: 100%;
}
.product-tabs .stars{
    margin: 0;
    display: block;
}
.product-tabs .stars a{
    color: #515151;
    font-size: 0;
    margin-right: 20px;
}
.product-tabs .stars a:hover{
    color: #ebc52d;
}
.product-tabs .stars a:after{
    font-family: 'FontAwesome';
    font-size: 12px;
}
.product-tabs .stars .star-1:after{
    content: "\f005";
}
.product-tabs .stars .star-2:after{
    content: "\f005\f005";
}
.product-tabs .stars .star-3:after{
    content: "\f005\f005\f005";
}
.product-tabs .stars .star-4:after{
    content: "\f005\f005\f005\f005";
}
.product-tabs .stars .star-5:after{
    content: "\f005\f005\f005\f005\f005";
}
.product-tabs .review_form input[type="text"],
.product-tabs .review_form input[type="email"],
.product-tabs .review_form textarea{
    width: 100%;
    max-width: 100%;
}
.product-tabs .review_form .comment-form-author,
.product-tabs .review_form .comment-form-email{
    width: 50%;
    float: left;
}
.product-tabs .review_form .comment-form-email{
    padding-left: 20px;
}
.product-tabs .review_form .submit{
    padding: 6px 45px;
    float: right;
}
/** Tag cound**/
.product-tabs .tagcloud{
    padding: 0;
}
.related-products{
    padding-top: 60px;
}
.related-products .title{
    margin-bottom: 50px;
    font-family: 'PlayfairDisplay';
    font-size: 30px;
    font-style: italic;
    font-weight: normal;
    text-align: center;
}
.related-products .title:after{
    content: '';
    width: 16px;
    height: 16px;
    background: url('/images/close.png') no-repeat center center;
    margin: 0 auto;
    margin-top: 10px;
    display: block;
}
.related-products .product{
    margin-bottom: 0;
}
/* Block Products */
.section-block-product{
    margin-top: 60px;
}
.section-block-product .block-products{
    margin-bottom: 30px;
}
.block-products .block-title{
    font-family: 'PlayfairDisplay';
    font-style: italic;
    font-weight: normal;
    font-size: 30px;
    margin-bottom: 30px;
}
.block-products .product-item li{
    list-style: none;
    display: table;
    margin-bottom: 30px;
}
.block-products .product-item li:last-child{
    margin-bottom: 0;
}
.block-products .product-thumb,
.block-products .product-info{
    display: table-cell;
    vertical-align: top;
}
.block-products .product-info{
    padding-left: 30px;
}
.block-products .product-thumb{
    width: 170px;
}
.block-products .product-name{
    line-height: 24px;
    font-weight: 500;
}
.block-products .product-name a{
    font-size: 12px;
    color: #303030;
}
.block-products .product-name a:hover{
    color: #e1571a;
}
.block-products .star-rating,
.block-products .price{
    display: block;
    margin-bottom: 15px;
}
.block-products .star-rating{
    color: #ebc52d;
}
.block-products .button{
    padding: 6px 26px;
}
.banner-opacity {
    position: relative;
		left: 10%;
    display: inline-block;
    overflow: hidden;
    float: left;
		width: 80%;
}
.banner-opacity-about {
    position: relative;
		top:-50px;
		left: 10%;
    display: inline-block;
    overflow: hidden;
    float: left;
		width: 100%;
}

.banner-opacity-about1 {
    position: relative;
		top:-50px;
		left: 15%;
    display: inline-block;
    overflow: hidden;
    float: left;
		width: 100%;
}
.banner-opacity-about2 {
    position: relative;
    display: inline-block;
    overflow: hidden;
    float: left;
		width: 100%;
}

.banner-opacity img {
    cursor: pointer;
    transition: all 0.6s;
}

.banner-opacity:hover img {
    transform: scale(1.05);
}
.banner-opacity:hover:before {
    opacity: 1;
    filter: alpha(opacity=100);
}
.block-text-single{
    border: 1px solid #e8e8e8;
    text-align: center;
    padding: 35px 40px 20px 40px;
    margin-bottom: 20px;
    line-height: 18px;
}
.block-text-single h6{
    font-weight: 500;
}
.block-text-single p{

}
.summary.style2 .single_add_to_cart_button {
    clear: left;
    margin-top: 20px;
}
.summary.style2 .description{
    padding: 40px 0;
}
/* ---------------------------
			COMMING SOON
----------------------------- */
.body-commingsoon{
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
}
.comming-style1{
	background-image: url(/images/commingsoon-1.jpg);
}
.comming-style2{
	background-image: url(/images/commingsoon-2.jpg);
}
.logo-commingsoon{
	padding: 50px 0 0 85px;
	position: relative;
}
.body-commingsoon .footer-social {
  position: relative;
  text-align: center;
  padding-bottom: 60px;
}
.body-commingsoon .footer-social li {
  display: inline-block;
}
.commingsoon-content{
	position: relative;
	color: #ffffff;
	padding: 16% 0;
}
.commingsoon-content h2{
	font-size: 48px;
	color: inherit;
}
.commingsoon-content .bz-countdown{
	margin-top: 60px;
}
.countdown-style2 .item-countdown{
	width: 158px;
	height: 158px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid rgba(232,232,232,0.2);
	margin: 0 65px;
	padding: 15px;
	font-family: 'Dancing Script';
}
.countdown-style2 .item-countdown .title{
	font-size: 23px;
	color: inherit;
}
.countdown-style3 .item-countdown{
	font-family: 'Dancing Script';
	color: inherit;
}
.countdown-style3 .item-countdown .title{
	color: inherit;
	font-size: 24px;
}
.bz-form-newsletter .title-form{
	color: #ffffff;
	margin-bottom: 20px;
}
.bz-form-newsletter .title-form h3{
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-size: 14px;
	color: inherit;
	font-weight: 600;
}
.bz-form-newsletter .title-form h3:after{
	content: "";
	height: 1px;
	width: 30px;
	background-color: #ffffff;
	display: block;
	margin: 15px auto 15px;
}
.form-newsletter.newsletter-style2{font-weight: 500; font-size: 12px;}
.form-newsletter.newsletter-style2 input[type="email"]{
	text-transform: uppercase;
	color: #ffffff;
	border: 1px solid #ffffff;
	padding: 15px 30px;
}
.form-newsletter.newsletter-style2 > span{padding: 0}
.form-newsletter.newsletter-style2 button{
	padding: 15px 30px;
	text-transform: uppercase;
	color: #ffffff;
	border: 1px solid #ffffff;
	border-left: none;
}
.body-commingsoon .bz-form-newsletter{margin-bottom: 90px;}
.comming-style2 .commingsoon-content{
	padding: 8% 0;
}
.comming-style2 h1{
	font-size: 100px;
	font-weight: 900;
	color: inherit;
}
/* ---------------------------------------------------------- */
/* ------------------------SECTION CONFIG------------------- */
/* -----------------------------------------------------------*/
.margin-top-30{margin-top: 30px;}
.margin-top-40{margin-top: 40px;}
.margin-top-50{margin-top: 50px;}
.margin-bottom-50{margin-top: 50px;}
.margin-top-70{margin-top: 70px;}
.margin-top-80{margin-top: 80px;}
.margin-top-100{margin-top: 100px;}
.margin-top-90{margin-top: 90px;}
.margin-bootom-30{margin-bottom: 30px;}
.section-content{position: relative;}
.main-container{padding: 80px 0;}
.margin-bt-30{margin-bottom: 30px;}
.section-ourteam1{margin-top: 80px;}
.section-testimonial{padding: 40px 0;}
.section-testimonial6{
    padding: 190px 0 190px 0;
    background-image: url(/images/bg-parallax5.jpg);
}
.section-testimonial6 .overlay{
    background-color: rgba(0,0,0,0.5);
}
.section-pricing{padding: 40px 0;}
.section-pricing3{
    padding: 140px 0 65px 0;
    background-image: url("/images/bg-parallax6.jpg");
}
.section-pricing3 .overlay{
    background: rgba(255,255,255,0.7);
}
.section-button-page .title-page-section{
    margin-top: 80px;
    text-transform: uppercase;
}
.section-button-page .button{margin-right: 30px;}
.section-button-page p{margin-bottom: 50px;}
.section-button-page{padding-bottom: 60px;}
.promo-1{
    background-color: #33302f;
    padding: 37px 0;
    margin-bottom: 100px;
}
.section-call-out-page{padding-top: 100px;}
.section-message-page{padding-bottom: 70px;}
.section-message-page p{margin-bottom: 30px;}
.section-work{margin-bottom: 90px;}
.section-our-service{margin-bottom: 100px;}
.section-paralax-2{
    padding: 145px 0;
    color: #fff;
    background-image: url("/images/b/paralax1.jpg");
}
.section-paralax-2 .title,
.section-paralax-2 .subtitle{
    color: inherit;
}
.section-paralax-2 .button{margin-top: 15px;}
.section-paralax-3{
    background-image: url("/images/b/paralax2.jpg");
    color: #fff;
    padding-top: 70px;
}
.section-paralax-3 .title,
.section-paralax-3 .subtitle{
    color: inherit;
}
.section-paralax-3 .subtitle{margin-bottom: 0;}
.section-paralax-3 .title{
    font-size: 48px;
    letter-spacing: 5px;
    margin-bottom: 90px;
}
.section-promo{
    background-color: #33302f;
    padding: 37px 0;
}
.section-paralax-4{
    background-image: url("/images/b/paralax3.jpg");
    color: #fff;
    padding-top: 95px;
    padding-bottom: 150px;
}
.section-paralax-4 .title{color: inherit;}
.section-home-slide2{
    background-image: url("/images/b/paralax4.jpg");
    color: #fff;
    padding-top: 95px;
    padding-bottom: 25px;
    margin-top: 185px;
}
.section-photos1{padding:0 45px;}
.block-text-photo{
    padding-bottom: 370px;
    background:url('/images/b/b4.png') no-repeat top right;
}
.section-video-photo-1 {
    background-image: url('/images/b/paralax5.jpg');
    padding: 205px 0;
}
.section-video-photo-1 h2{
    color: #fff;
}
.block-shop-banner1{
    background: url('/images/b/bg1.jpg') no-repeat top right;
    padding-top: 205px;
    padding-bottom: 190px;
}
.block-shop-banner1 h2,
.block-shop-banner1 h5{
    font-weight: 300;
}
.block-shop-banner1 h5{margin-bottom: 0;}
.block-shop-banner1 .title{
    font-size: 72px;
    margin-bottom: 50px;
}
.section-video-shop-1 {
    background-image: url('/images/b/parallax7.jpg');
    padding: 140px 0;
    margin-top: 30px;
}
.section-video-shop-1 h2{color: #fff;}
.fashion-paralax-1{
    padding: 230px 0 220px 0;
    background-image: url('/images/b/paralax8.jpg');
    margin-top: 60px;
    color: #fff;
}
.fashion-paralax-1 .title,
.fashion-paralax-1 .subtitle{
    color: #fff;
}
.fashion-paralax-1 .title {font-size: 60px;}
.fashion-paralax-1 .subtitle{margin-bottom: 80px;}
.fashion-paralax-1 .button{
    border-color: #fff;
    color: #fff;
}
.fashion-paralax-1 .button:hover{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.fashion-paralax-2{
    background-image: url('/images/b/paralax9.jpg');
    padding: 50px 0;
    margin-top: 80px;
    color: #fff;
}
.fashion-paralax-2 .section-title3 .title{color: #fff;}
.fashion-paralax-2 .testimonial{color: #fff;}
.fashion-paralax-2 .owl-carousel .owl-prev,
.fashion-paralax-2 .owl-carousel .owl-next{top: 40%;}
.fashion-paralax-2 .testimonials-style3 .testimonial h6{
    color: #fff;
    margin-bottom: 0;
}
.fashion-paralax-3{
    padding: 30px 0 80px 0;
		background-position: right;
    background-image: url('/images/b/paralax10.jpg');
    color: #fff;
    margin-top: 150px;
}
.fashion-paralax-3 .title{
    margin-top: -20px;
    margin-bottom: 20px;
    font-size: 36px;
    color: #fff;
}
.fashion-paralax-3 .button{
    color: #fff;
    border-color: #fff;
    margin-top: 10px;
}
.fashion-paralax-3 .button:hover{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.fashion-paralax-4{
    padding: 250px 0;
    background-image: url('/images/b/paralax11.jpg');
    margin-top: 80px;
}
.fashion-intro{
    margin-top: -430px;
}
.blocck-feature-products{
    overflow: hidden;
}
.blocck-feature-products .left{
    width: 40%;
    float: left;
}
.blocck-feature-products .right{
    width: 60%;
    float: left;
}
.inner-page{padding: 85px 0;}
.section-fucfact1{ padding: 88px 30px 85px;}
.section-funfact2{padding-bottom: 125px;}
.section-funfact3{margin-bottom: 90px;}
.funfact-style1{
    background-image: url(/images/bg-parallax1.jpg);
    padding: 145px 0;
}
.funfact-style3{
    background-image: url(/images/bg-parallax3.jpg);
    padding: 280px 0 150px 0;
}
.funfact-style4{
    background-image: url(/images/bg-parallax2.jpg);
    padding: 85px 0 50px 0;
}
.section-progressbar{
    background-image: url(/images/bg-parallax4.jpg);
    padding: 40px 0;
}
.title-page-section h2{margin-bottom: 85px;}
.title-page-section h6{margin-bottom: 40px}
.title-page-section h2, .title-page-section h6{font-weight: 500;}
.section-icon1{padding: 150px 0 100px 0;}
.section-icon2{padding: 85px 0; background-color: #eeeeee;}
.section-icon3{ padding: 50px 0;}
.section-icon3 .row{padding: 50px 0;}
.section-processbar{padding: 90px 0 0;}
.section-processbar .row-1{padding: 0px 0 90px 0;}
.section-tab{padding-bottom: 90px;}
.section-list{padding: 95px 0 85px;}
.section-accoredion{padding-bottom: 95px;}
.section-list .title-page-section h2{margin-bottom: 40px}
.section-about{padding: 60px 0;}
.section-about .row-icon{margin-top: 80px}
.banner-header.page-service{
	background-image: url(/images/bg-service.jpg);
}
.section-sevice1{padding-top: 90px}
.section-sevice1 .row-3{padding: 0 0 80px 0}
.section-sevice1 h2{margin-bottom: 15px;}
.section-feature1{
    padding: 77px 0 20px 0;
}
.section-pricingtable1{
    padding: 0 0 90px 0;
}
.section-sevice2{padding: 90px 0 80px}
.section-service3{padding: 80px 0;}
.section-video{
    background-image: url(/images/bg-parallax5.jpg);
    padding: 205px 0;
}
.section-video h2{
    color: #ffffff;
}
.section-sevice4{
    padding: 80px 0;
}
.section-portfolio1{
    padding: 105px 30px 55px;
}
.page-portfolio{
    background-image: url(/images/bg-portfolio.jpg);
}
.section-portfolio2{
    padding-top: 100px
}
.section-portfolio3{padding-top: 60px}
.section-portfolio4{
    padding: 75px 0;
}

.section-about1{
    padding: 150px 0 50px 0;
}
.section-about1 .col-sm-6 > figure{
    margin-top: -40px;
}
.section-team{
    padding: 45px 0;
}
.section-testimonial{
    padding: 45px 0 60px;
}
.section-video-1{
    background-image: url(/images/bg-parallax6.jpg);
    padding: 55px 0 155px;
}
.section-video-1 h2{
    color: #ffffff;
    margin-top: 35px;
}
.section-video-1 .overlay{
    background-color: rgba(0,0,0,0.6);
}
.section-service1{
    padding: 75px 0 30px;
}
.section-skill{
    padding: 25px 0 50px;
}
.section-skill .section-title{
    margin-bottom: 45px;
}
.section-video-2{
    background-image: url(/images/bg-parallax7.jpg);
}
.section-service2{
    padding: 65px 0 80px;
}
.section-service2 .section-title{
    margin-bottom: 20px;
}
.section-testimonial2{
    padding-bottom: 105px;
}
.section-service4{
    padding: 90px 0 50px;
}
.section-portfolio{
    padding-top: 70px;
}
.section-client{
    padding: 80px 0 40px;
}
.section-1{
	padding: 125px 0 90px;
}
.section-portfolio1 .desc-bt-pf{
    padding: 30px 0 0;
}
.section-portfolio1 .desc-bt-pf .button{
    margin-top: 30px;
}
.section-5{
    padding: 0 30px;
}
.section-6{
    padding: 95px 0;
}
.section-6 .section-title{margin-bottom: 0}
.section-ourblog1{padding-bottom: 60px;}
.section-service-4{
    padding: 80px 0 50px;
    border-bottom: 1px solid #e8e8e8;
}
.section-contact{
    padding: 60px 0 120px;
    background-image: url(/images/bg-contact.jpg);
}
.section-6 .row-icon5 .element-icon-5 .icon{
    background: #e1571a;
}
.promo-1.no-margin{
    margin: 0;
}
.section-team-1{
    padding: 93px 0;
    background:url(/images/patten1.png) repeat center;
}
.section-funfact-1{
    background-image: url("/images/bg-parallax3.jpg");
    padding:80px 0 100px;
}
.section-funfact-1 .overlay{
	background-color: rgba(34,34,34,0.4);
}
.block-2{
    margin-bottom: 65px;
}
.section-video-3{
    padding: 155px 0;
    background-image: url(/images/bg-parallax9.jpg);
}
.section-video-3 h2{color: #ffffff;margin: 20px 0 30px 0}
.section-video-3 .overlay{background-color: rgba(0,0,0,0.3);}
.section-pricingtable-1{
    padding: 90px 0 55px 0;
}
.section-8{
    padding: 60px 0 110px;
    background-image: url(/images/bg-parallax10.jpg);
}
.section-8 .overlay {
  background-color: rgba(241, 241, 241, 0.3);
}
.section-8 .row-icon{margin-bottom: 65px;}
.section-client-3{
    padding: 80px 0 20px;
    border-bottom: 1px solid #e8e8e8;
}
.section-contact-3{
    padding: 55px 0 150px;
}
.section-wellcome{
    padding: 95px 0 0;
}
.section-wellcome-2{padding: 70px 0;}
.section-promod_block_1{
    padding: 80px 0 100px;
    background-image: url(/images/bg-parallax11.jpg);
}
.section-promod_block_1 .section-title.title-style4 .title.fz43{font-size: 43px;}
.section-promod_block_1 .bz-element-icon-image{
    margin-bottom: 60px;
}
.section-portfolio-3{padding-top: 70px}
.section-feature-3{
    padding: 75px 0;
}
.section-11{padding: 70px 0 60px}
.section-11 h2{font-weight: 300;margin-bottom: 20px}
.section-11 .row{margin-top: 35px}
.section-video-4{
    background-image: url(/images/bg-parallax12.jpg);
}
.section-12{
    padding: 65px 0 20px;
}
.section-12 .row-1{
    margin-bottom: 60px;
}
.section-13{
    background-color: #f6f6f6;
    padding-top: 85px;
}
.section-contact3 .contact-form{
    padding: 45px 0 100px;
}
.section-team1{
    padding: 80px 0 45px;
}
.section-14{
    background-image: url(/images/bg-parallax13.jpg);
    padding: 140px 0;
}
.section-14 .section-title{
    margin-bottom: 80px;
}
.section-feature3{
    padding: 70px 0 0;
}
.section-feature3 .service-style-4 {
  margin-bottom: 60px;
}
.section-provide2{
    padding: 120px 0;
    background-image: url(/images/bg-parallax14.jpg);
}
.section-video-5{
    background-image: url(/images/bg-parallax15.jpg);
}
.section-client1{
    padding: 70px 0;
}
.section-client1 .title-section{
    margin-bottom: 100px;
}
.section-breaknew{padding-bottom: 35px;}
.section-twitter{
	background-image: url(/images/bg-parallax16.jpg);
	padding: 85px 0 185px;
}
.section-16{
    padding: 75px 0 85px;
}
.section-16 .figure{margin-bottom: 35px;}
.section-16 .button{
    margin: 0 10px 45px 10px;
}
.section-17{
    padding: 75px 0 0;
}
.section-18{
    background-color: #f3f3f3;
}
.section-18 .bz-service-image{padding: 120px 0}
.section-18 .img-vc1 img{margin-left: -195px;max-width: none;}
.section-skill1{
    background-image: url(/images/bg-parallax17.jpg);
    padding: 90px 0 80px;
}
.section-19{
    padding-top: 90px;
}
.section-testimonial-1{
    padding: 40px 0 150px;
    background-image: url(/images/bg-parallax18.jpg);
}
.section-ourblog{
    padding: 95px 0 60px;
}
.section-pricing .row-text{
    margin-top: 15px;
    margin-bottom: 65px;
}
.section-20{
    padding-top: 55px;
    background-color: #ffffff;
}
.section-20 .element-icon-7 .icon{
    border-color: #e8e8e8;
    color: #efb916;
}
.section-video-6{
    background-image: url(/images/bg-parallax19.jpg);
    padding: 150px 0 130px;
}
.section-video-6 .overlay{
    background-color: rgba(239,185,22,0.8);
}
.section-service1 .row-1{margin-bottom: 60px;}
.section-21{
    background-image: url(/images/bg-parallax20.jpg);
    padding: 110px 0;
}
.section-21 .container{position: relative;}
.section-21 h2{ color: #ffffff;margin-bottom: 45px;}
.section-funfact.funfact-style6{
    background-image: url(/images/bg-parallax21.jpg);
    padding: 70px 0;
    border: none;
}
.section-funfact.funfact-style6 .bz-funfact .number{
    color: #efb916;
}
.section-client3{
    background-image: url(/images/bg-parallax22.jpg);
    padding: 120px 0;
}
.section-image{
    min-height: 570px;
    background-image: url(/images/restaurant/res-parallax0.jpg);
}
.section-menures{padding-top: 60px;}
.section-testimonial1{
    padding: 65px 0 130px;
    background-image: url(/images/restaurant/res-parallax2.jpg);
}
.section-reservation{
    padding: 65px 0 75px;
}
.section-coutdount{
    background-image: url(/images/restaurant/res-parallax3.jpg);
    padding: 60px 0 130px;
}
.section-coutdount h2{
    color: #ffffff;
    font-size: 72px;
    font-family: 'Dancing Script';
}
.section-coutdount p{
    color: rgba(255,255,255,0.5);
    font-size: 14px;
}
.section-evencomming{
    padding: 65px 0 0 0;
}
.section-pricing1{
    padding: 100px 0 95px;
    background-image: url(/images/bg-parallax23.jpg);
}
.section-pricing1 .overlay{
    background-color: rgba(255,255,255,0.8);
}
.section-lastest{
    padding: 75px 0 0 0;
}
.section-13 .col-md-6:last-child{margin-bottom: 60px}
.section-counter{padding-top: 70px}
.section-7{padding: 0 30px}
.section-ourteam6{padding: 80px 100px}
.section-sevice2 .section-title{margin-bottom: 0}
.section-sevice4 .bz-service-image.service-style1{
    margin-bottom: 100px
}
/*------------------ Update 16/12 ------------------*/
.banner-product{margin-bottom: 30px}
.block-brands > a {
  display: inline-block;
  padding: 15px 0;
  width: 23%;
}
.pdb-30{padding-bottom: 30px}
.block-shop-banner1 .title > span{
	color: #e1571a;
}
.section-10 .element-icon-1 .icon{
	background-color: #e1571a;
}
 .block-products .price{
 	color: #e1571a;
	font-size: 18px;
	font-weight: 500;
 }
 .bz-lookbook .owl-dots{
    margin-top: 30px;
 }
 .section-lookbook{padding-top: 75px}
 .section-title3.light .title::after{
    background-image: url(/images/close-light.png);
 }
 .shop-newaletter1{
  background-color: #312a2a;
}
 .shop-newaletter1 .input-email{
    color: #868686;
    font-size: 12px;
    border-color: #4c4343;
}
.section-22{padding: 20px 0 0;}
.lastest-post.style1 .blog-item .content-post p{
    margin-bottom: 0;
}
.lastest-post.style1 .blog-item{
    margin-bottom: 60px;
    overflow: hidden;
}
.header.header-cons{
    padding: 0;
    position: relative;
}
.header-top-section{
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 0;
}
.content-top-header{overflow: hidden;}
.content-top-header .contact-support{
    float: left;
}
.contact-support .contact-text{
    display: inline-block;
    color: #868686;
    font-size: 12px;
    margin-right: 10px;
}
.contact-support .contact-text .fa{
    margin-right: 15px;
    font-size: 14px;
}
.contact-support .contact-text a{color: inherit;}
.contact-support .contact-text
.content-top-header .social-top{
    float: right;
}
.header-cons .main-menu > ul > li > a{
    color: #303030;
}
.social-top a{
    display: inline-block;
    list-style: none;
    margin: 0 3px;
    font-size: 12px;
    color: #868686;
}
.social-top a:hover{color: #e1571a;}
.header-cons .header-main-section{padding-top: 37px}
.header-main-section .content-mainheader {position: relative;}
.header-main-section .content-mainheader:after,
.header-main-section .content-mainheader:before,
.main-menu > ul:after,
.main-menu > ul:before{
    content: "";
    display: table;
    clear: both;
}
.ss-contac-cons.section-contact3 .contact-form{padding-bottom: 20px}
.title-page-section h2, .title-page-section h6{text-transform: uppercase;}
.text-section{margin-bottom: 55px}
.text-section .big-title{
    font-weight: 900;
    font-size: 48px;
}
.page-blog2{
    background:url(/images/patten1.png) repeat center;
}
.no-padding-bottom{padding-bottom: 0}

.scroll_top {
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 9999;
    right: 10px;
    bottom: 10px;
    display: none;
    color: #fff;
    text-align: center;
    line-height: 40px;
}
.scroll_top:hover,
.scroll_top:focus{
    color: #fff;
    opacity: 0.7;
}
.scroll_top .fa{
  color: #fff;
}

/* -------------------------
		RESPONSIVE
------------------------- */
.blog-list > .col-sm-4:nth-child(3n+1){
	clear: both;
}
.lastest-post.slide-lastest{margin-bottom: 35px}
.block-tab-products .product-thumb img {
  width: 100%;
}
@media (min-width: 1200px) and (max-width: 1366px){
    .slide-home .content-style1 figure.title-slide > img{max-width: 290px}
    .slide-home .content-style1 .slogan img{max-width: 80px;}
    .content-style1 .desc-slide{font-size: 20px;}
    .content-slide.content-style1 .button{margin-top: 20px}
    .section-service-style3 .service-image{max-width: 45%}
    .content-style1 .sub-title{font-size: 50px; line-height: 50px;}
    .content-style1 .title{font-size: 100px; line-height: 100px;}
    .product .add_to_cart_button, .product .added_to_cart{padding: 7px 20px}
    .block-product-slide .product .yith-wcwl-add-to-wishlist a, .block-product-slide .product .compare-button .compare,
    .block-product-slide .product .yith-wcqv-button{width: 45px}
    .block-product-slide .product .star-rating{margin-top: 10px}
    .block-product-slide .product .product-name{margin-bottom: 0;}
}
@media(max-width: 1200px){
    .main-menu>ul>li{padding:10px 3px}
    .header{padding:15px 15px 0 15px}
    .main-menu .megamenu{width:100%!important}
    .header.style3{padding-top:45px}
    .page-template-sidebar .sidebar-menu{width:250px;padding:50px 0 60px 20px}
    .sidebar-menu .inner{padding-right:0}
    .sidebar-menu .footer-social a{width:30px;height:30px;line-height:30px}
    .page-template-sidebar .main-content-site{padding-left:250px}
    .blocck-feature-products .left{display:none}
    .blocck-feature-products .right{width:100%}
    .section-service-style3 .service-image{max-width: 45%}
		}
@media(min-width: 992px) and (max-width: 1199px){
    .content-style1 .sub-title{font-size: 45px;line-height: 45px;}
    .content-style1 .title{font-size: 90px; line-height: 90px;}
     .content-res .title{font-size: 65px;}
    .slide-home .content-res .slogan-sl img{max-width: 130px;}
    .content-res .desc-slide{font-size: 22px;}
    .content-res .button{margin-top: 15px;}
    .block-products .product-thumb {float: left; width: 100px;}
    .block-products .product-name{margin-bottom: 10px}
    .fashion-intro{margin-top: -425px}
    .blog-item.style3 .post-inner{padding: 30px 25px;}
}

@media(min-width: 768px) and (max-width: 991px){
	.element-icon-4 .icon{margin-right: 20px}
	.element-icon-4 h4{padding-left: 75px}
	.element-icon-4 p{padding-left: 75px;}
	.promo-right .button-custom { white-space: nowrap;}
	.contact-form.style-2 input[type="text"], .contact-form.style-2 input[type="mail"]{margin-bottom: 10px}
	.bz-social .fa{font-size: 14px;}
	.bz-social{line-height: 15px;padding: 10px;}
	.content-style1 .sub-title{line-height: normal;font-size: 40px;}
	.content-style1 .title{font-size: 95px;line-height: normal;}
    .content-style2 .title{font-size: 45px;}
    .content-style2 .desc-slide{font-size: 20px;}
	.content-style1 .desc-slide{font-size: 20px;}
	.bz-video-bg .content-slide .title{font-size: 60px;}
	.five-column .item-provide{width: 33.3334%}
	.four-column .item-provide{width: 50%}
	.section-funfact.five-column .bz-funfact{width: 33.3334%}
	.section-funfact.four-column .bz-funfact{width: 50%}
	.pricing-table-style3 .pricing-button{padding: 5px 10px;}
	.pricing-table-style3.active { margin-top: 0px;}
    .element-icon-9 .icon{width: 60px;height: 60px;line-height: 60px;margin-right: 20px}
    .element-icon-9 h4{padding-left: 80px}
    .element-icon-9 p{padding-left: 80px}
    .pricing-table-style4{padding: 25px 15px 40px;}
    .pricing-table-style4 li .fa {padding: 0 15px;}
    .service-resstyle .title{font-size: 22px;}
    .bz-tab-menuweek .resp-accordion{display: none;}
    .resmenu-list li .price-item{font-size: 16px;}
    .bz-tab-menuweek .resp-tabs-list li{padding-left: 10px;padding-right: 10px}
    .button{margin-bottom: 20px;}
    .section-service-style3 .service-image{max-width: 100%;position: relative;}
    .testimonials-style1{padding: 35px 20px 45px}
    .blog-item.style3 .post-inner{padding: 30px 25px;}
    .pricing-table-style1 .head .title{font-size: 20px;}
    .pricing-table-style1 .head .desc{font-size: 14px;}
    .blog-item.style2 .inner { padding: 0 15px;}
    .blog-item.style2 .post-cat{padding: 5px 15px;}
    .provide-style1 .item-provide .content-hover{padding: 20px 15px;}
    .footer-widget .list-latest-news li .post-img img{max-width: 45px}
    .footer-widget .list-latest-news li .post-img{margin-right: 15px;}
    .footer-widget .list-latest-news li h3.title-post{margin-bottom: 0;}
    .footer-social.style2 a{width: 35px; height: 35px; line-height: 35px;}
    .header.header-trans{padding: 15px 15px 0;}
    .header.header-trans .header-container .logo{padding-bottom: 0; width: 20%;margin-top: 15px}
    .header.header-trans .header-container .bz-main-mennu{width: 80%;float: right; text-align: right;display: block;}
    .header.header-trans .main-menu > ul > li.bz-search-icon, .header-trans .main-menu > ul > li.bz-mini-cart{padding: 15px 10px}
    .header.header-trans .main-menu > ul > li{padding: 20px 10px 10px;font-size: 10px;}
    .content-res .title{font-size: 60px;}
    .slide-home .content-res .slogan-sl img{max-width: 100px;}
    .content-res .desc-slide{font-size: 20px;}
    .content-res .button{margin-top: 0;}
    .block-tab-products .resp-accordion{display: none;}
    .block-products .product-thumb {float: left;width: 70px;}
    .block-products .product-info{padding-left: 15px}
    .block-products .button{padding: 6px 10px}
    .block-products .star-rating, .block-products .price{margin-bottom: 8px}
    .block-products .product-name{margin-bottom: 10px}
    .fashion-intro{margin-top: -325px}
    .testimonials-style2 .testimonial .inner{padding: 25px 15px;}
    .comments-area ol li.comment .children{padding-left: 50px;}
    blockquote{padding: 30px}
    .bz-tab .resp-accordion{display: none;}
    .products .product:nth-child(3n+1){clear: both;}
    .products.columns-4 .product:nth-child(4n+1){clear: none;}
    .left-slidebar .products .product:nth-child(2n+1){clear: both;}
    .left-slidebar .products .product:nth-child(3n+1){clear: none;}
    .right-slidebar .products .product:nth-child(2n+1){clear: both;}
    .right-slidebar .products .product:nth-child(3n+1){clear: none;}
    .pf-meta{padding: 20px}
    .countdown-style2 .item-countdown{
    	width: 145px;
    	height: 145px;
    	margin: 0 15px;
    }
    .commingsoon-content{padding: 25% 0;}
    .comming-style2 .commingsoon-content{padding: 15% 0;}
    .comming-style2 h1{font-size: 70px;}
    .contact-support .contact-text .fa{margin-right: 10px;}
}
@media(max-width: 767px){
	h1{font-size: 30px;}
  h2{font-size: 25px;}
	h5{font-size: 15px;}
    .logo img{width:70%;}
    .header{position:relative;background-color:#303030;padding:20px 15px}
    .bz-main-mennu .mobile-navigation{display:block}
    .header .bz-main-mennu{width:100%}
    .bz-main-mennu .main-menu{display:none;padding-top:15px}
    .main-menu>ul>li{width:100%;float:left;padding:0;border-bottom-width:1px;border-bottom-style:solid;border-bottom-color:rgba(53,37,30,0.3)}
    .main-menu>ul>li>a{padding:10px 0;width:100%;float:left}
    .main-menu .sub-menu{
        position:relative;
        top:inherit;
        right:inherit;
        left:inherit;
        display:none;
        opacity:1;
        visibility:inherit;
        -webkit-transform:inherit;
        -moz-transform:inherit;
        -ms-transform:inherit;
        -o-transform:inherit;
        transform:inherit;
        width:100%;
        float:left;
        padding:0
    }
    .main-menu ul li.menu-item-has-children.show-submenu>.sub-menu{
        display:block;
        -webkit-transform:inherit;
        -moz-transform:inherit;
        -ms-transform:inherit;
        -o-transform:inherit;
        transform:inherit
    }
    .main-menu>ul>li>a:before{display:none!important}
    .main-menu .sub-menu .sub-menu{position:relative;top:inherit;left:inherit;right:inherit}
    .main-menu .sub-menu>li{padding:0 15px}
    .main-menu .sub-menu .menu-item-has-children>a:after{content:"\f107"}
    .main-menu .megamenu{width:100%!important;padding:15px}
    .main-menu>ul>li:nth-last-child(1) .sub-menu .sub-menu,.main-menu>ul>li:nth-last-child(2) .sub-menu .sub-menu,.main-menu>ul>li:nth-last-child(3) .sub-menu .sub-menu,.main-menu>ul>li:nth-last-child(4) .sub-menu .sub-menu,.main-menu>ul>li:nth-last-child(5) .sub-menu .sub-menu{right:inherit}
    .main-menu>ul>li.bz-search-icon,.main-menu>ul>li.bz-mini-cart{padding:0}
    .main-menu>ul>li.bz-mini-cart>a .count{top:2px;left:20px;right:inherit}
    .mini-cart-content{right:0;z-index:10}
    .popup-search .popup-search-form{padding:15px}
    .popup-search .input-search{font-size:10px}
    .popup-search .close-popup{right:15px;z-index:3;font-size:16px}
    .header.style3,.header.style4{background-color:#D7bfb7;padding:20px 15px}
    .page-template-sidebar .bz-main-mennu .main-menu{display:block}
    .sidebar-menu .main-menu>ul>li{padding:0}
    .banner-header{padding:20px 0}
    .page-title{font-size:20px}
    .page-desc{font-size:14px}
    .breadcrumbs a,.breadcrumbs span{font-size:10px}
    .breadcrumbs a:after{padding:0 5px}
    .promo h3{font-size:24px}
    .msg-box{padding:10px}
    .msg-box .msg-box-icon{width:30px;height:30px;line-height:30px;font-size:10px;display:table;margin:0 auto}
    .msg-box .box-text{font-size:10px;text-align:center;display:block}
    .msg-box .box-close{top:10px;right:5px}
    .msg-box2 .msg-box-icon{padding:0}

	.bz-service-image.service-style1 .service-content .title{margin-bottom: 15px;}
	.bz-video-lightbox h2 {
	  font-size: 30px;
	  margin-bottom: 35px;
	}
	.section-6 .col-sm-4{margin-bottom: 35px}
	.content-slide .sub-title{font-size: 30px;line-height: normal;}
	.content-slide .title{font-size: 50px; line-height: normal;}
	.content-style1 .desc-slide{font-size: 18px;}
	.bz-feature:first-child{margin-top: 50px}
	.promo .promo-left, .promo .promo-right {display: block; text-align: center;}
	.promo .promo-right {margin-top: 20px;text-align: center;}
	.section-funfact.five-column .bz-funfact,
	.section-funfact.four-column .bz-funfact,
	.section-funfact.three-column .bz-funfact{width: 100%}
	.blog-list .blog-item .post-format > figure img{width: 100%}
	.five-column .item-provide,	.four-column .item-provide{width: 100%}
	.bz-video-bg .content-slide .title{font-size: 45px;}
	.bz-video-bg .content-slide .desc-slide{font-size: 19px;}
	.bz-video-bg .play-button {margin-top: 35px;}
	.bz-chart{margin-bottom: 35px}
	.lastest-post.style1 .blog-item .post-format{margin-bottom: 30px;margin-right: 0;width: 100%}
	.lastest-post.style1 .blog-item .info-post{width: 100%}
	.pricing-table-style3.active{margin-top: 0}
    .bz-service-image.service-style5{margin-bottom: 35px;}
    .content-style2 .title{font-size: 38px;}
    .content-style2 .desc-slide{font-size: 18px;}
    .portfolio_fillter.fillter-style1 .item-fillter{margin: 0 5px 10px;padding: 8px 25px}
    .section-18 .img-vc1 img{max-width: 100%; margin-left: 0}
    .bz-section-equal .right-section{padding-right: 15px!important; padding-left: 15px}
    .info-resv .phone-number{font-size: 35px;}
    .bz-countdown .item-countdown{width: 100%;margin-bottom: 30px}
    .meta-even li{font-size: 12px; margin-left: 5px}
    .meta-even li .fa{margin-right: 5px}
    .bz-list-even .item-even{display: block;}
    .bz-list-even .item-even .meta-even{display: block;margin-top: 20px;text-align: left;}
    .bz-tab-menuweek .resp-tabs-list{display: none;}
    .bz-tab-menuweek .res-list-menu .col-sm-4{margin-top: 35px;}
    .bz-tab-style1 .resp-tabs-list li{margin: 0 5px;}
    .bz-tab-style1 .resp-tabs-list li, .bz-tab-style1 .resp-tabs-list li.resp-tab-active,
    .bz-tab-style3 .resp-tabs-list li, .bz-tab-style3 .resp-tabs-list li.resp-tab-active{margin-bottom: 10px!important}
    .resp-vtabs ul.resp-tabs-list{width: 100%}
    .resp-vtabs .resp-tabs-container{width: 100%}
    .button{margin-bottom: 20px;}
    .button.icon { padding: 23px 45px;white-space: nowrap;}
    .slide-home .content-style1 figure.title-slide > img{max-width: 50%}
    .slide-home .content-style1 .slogan img{max-width: 65px;}
    .content-style1.text-center .desc-slide::after, .content-style1.text-center .desc-slide::before{width: 15px; margin: 0 6px 6px;}
    .content-slide .button{margin-top: 0;}
    .header .logo{ width: 60%; text-align: left; padding: 0;}
    .slide-home .content-slide{padding-top: 0!important}
    .section-service-style3 .service-image{position: relative;max-width: 100%;margin-bottom: 20px;}
    .section-service-style3.left-image .service-content{padding-bottom: 50px}
    .section-3 {padding: 75px 0 55px;}
    .section-portfolio1 { padding: 50px 15px 35px;}
    .section-5 {padding: 0 15px;}
    .section-title.title-style2 .title{font-size: 30px;}
    .content-style2 .title.fw900{font-size: 40px;}
    .about-text h2{font-size: 30px;}
    .bz-service-image { margin-bottom: 35px;}
    .section-paralax-3 .title{font-size: 35px; letter-spacing: 3px}
    .element-icon-4 .icon{margin-right: 20px}
    .element-icon-4 p{padding-left: 75px;}
    .element-icon-4 h4{padding-left: 75px;}
    .blog-item.style3 .post-inner{padding: 30px 20px;}
    .bz-element-icon-image .item-icon-image{margin-bottom: 20px;}
    .section-title.title-style4 .title{font-size: 40px;}
    .section-promod_block_1 .section-title.title-style4 .title.fz43{font-size: 35px;}
    .slide-home .content-slide{padding: 0 10px;}
    .content-res .title{font-size: 35px;}
    .content-res .desc-slide{font-size: 12px;}
    .slide-home .content-res .slogan-sl img{max-width: 50px}
    .content-res .button{font-size: 10px;padding: 5px 20px;}
    .slide-home .item-homeslide > figure > img { height: 300px;max-width: none;}
    .section-title.title-styleres h1{font-size: 36px;}
    .section-coutdount h2{font-size: 43px;}
    .banner-slide .owl-nav{bottom: 30px}
    .banner-slide.owl-carousel, .banner-item{margin-bottom: 30px}
    .owl-carousel {width: 80px;}
    .block-products .product-item li{display: block;margin-bottom: 30px}
    .block-products .product-thumb{display: block;margin: 0 0 20px;}
    .block-products .product-thumb, .block-products .product-info{display: block;padding-left: 0}
    .block-products .product-name, .block-products .price{margin-bottom: 0}
    .fashion-paralax-1 .title{font-size: 45px;}
    .fashion-paralax-1 .subtitle { margin-bottom: 60px;}
    .block-tab-products .resp-accordion{display: none;}
    .fashion-intro {margin-top: -185px;}
    .section-photos1{padding: 0 15px;}
    .slide-about .flex-control-thumbs{top: 100%; bottom: auto;}
    .slide-about .flex-control-nav li{border-width: 2px; width: 45px;}
    blockquote{padding: 30px 20px;}
    .comment .comment-content{margin-left: 90px}
    .comments-area ol li.comment .children{padding-left: 35px}
    .blog-item.style6 .post-slide-format .owl-controls .owl-prev, .blog-item.style6 .post-slide-format .owl-controls .owl-next{
    	width: 35px;
    	height: 35px;
    	line-height: 35px;
    }
    .post-format.post-link-format > figure img {
	  height: 260px;
	  max-width: none;
	  width: auto;
	}
	.pricing-table-style4{padding-left: 15px;padding-right: 15px}
	.pricing-table-style4 li .fa{padding: 0 25px 0 20px;}
	.pricing-table-style4.active{margin-top: 0;}
	.bz-tab-style4 .resp-accordion{display: none;}
	.resp-vtabs .resp-tabs-container{float: left;width: 100%}
	.product-tabs .comments .comment .comment-text .meta time{display: block;margin-left: 0}
	.pf-detail-2 .pf-meta{margin-bottom: 30px}
	.countdown-style2 .item-countdown{width: 158px;display: block;}
	.commingsoon-content h2{font-size: 35px;}
	.comming-style2 h1{font-size: 50px;}
    .header-top-section{display: none;}
    .header-cons .header-main-section{padding: 15px 0 20px;}
    .header.header-cons{background-color: #ffffff;}
    .header.header-cons .bz-main-mennu .mobile-navigation{border-color: #303030;color: #303030}
}

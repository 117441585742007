a{color: #7a4a4b;}
.bz-funfact-style2 .bz-icon-funfact{color: #7a4a4b;}
.bz-element-icon h4 a:hover{ color:#7a4a4b}
.element-icon-3 .icon{background-color: #7a4a4b;}
.element-icon-4 .icon{color: #7a4a4b;}
.element-icon-5 .link-readmore:hover{ color: #7a4a4b;}
.element-icon-6 .icon{border-color: #7a4a4b}
.element-icon-9 .icon{color: #7a4a4b;}
.element-icon-4.bg-icon .icon{
    background: #7a4a4b;
    border-color: #7a4a4b;
}
.bz-tab-style1 .resp-tabs-list li.resp-tab-active{color: #7a4a4b;}
.bz-tab-style2 .resp-tabs-list li:after{background-color: #7a4a4b;}
.bz-tab-style2 .resp-tabs-list li.resp-tab-active{color: #7a4a4b}
.bz-tab-style3 .resp-tabs-list li.resp-tab-active{background: #7a4a4b;}
.bz-tab-style4 .resp-tabs-list li.resp-tab-active{border-left-color: #7a4a4b;}
.bz-tab-style4 .resp-tabs-list li span.number-review{background-color: #7a4a4b;}
.bz-list li .fa{background-color: #7a4a4b;}
.bz-acordion > h3.ui-accordion-header.ui-accordion-header-active{color: #7a4a4b;}
.section-title.title-style4 .title span{color: #7a4a4b;}
.section-title .title span{color: #7a4a4b;}
.title-style7 .hr{ background-color: #7a4a4b;}
.portfolio_fillter.fillter-style1 .item-fillter.fillter-active{color: #7a4a4b;}
.portfolio_fillter.fillter-style2 .item-fillter:after{background-color: #7a4a4b;}
.portfolio_fillter.fillter-style2 .item-fillter.fillter-active{color: #7a4a4b}
.portfolio_fillter.fillter-style3 .item-fillter.fillter-active{ background: #7a4a4b;}
.item-portfolio .pf-title > a:hover{ color: #7a4a4b;}
.item-portfolio .pf-cat a:hover{color: #7a4a4b;}
.item-portfolio .pf-icon:hover{color: #7a4a4b;}
.portfolio-style3 .pf-social a:hover{color: #7a4a4b;}
.pf-hover2 .pf-title { color: #7a4a4b;}
.portfolio-slide .owl-prev:hover,
.portfolio-slide .owl-next:hover{color: #7a4a4b; border-color: #7a4a4b;}
.bz-pf-detail .pf-cat a:hover{color: #7a4a4b}
.pf-meta li a:hover{color: #7a4a4b}
.pf-meta li.pf-webclient a{ color: #7a4a4b;}
.list-relate-project .owl-controls .owl-dots .owl-dot.active{background: #7a4a4b;}
.bz-slidecontent .item-content .button:hover{background-color: #7a4a4b}
.provide-style1 .item-provide .content-hover{background-color: #7a4a4b;}
.provide-style2 .item-provide .provide-icon{background-color: #7a4a4b;}
.provide-style2 .item-provide .link-readmore:hover{
    border-color: #7a4a4b;
    background-color: #7a4a4b;
}
.bz-list-provide .owl-nav > div:hover{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.contact-form input[type="submit"]:hover{ background: #7a4a4b;}
.block-contactinfo > h6{color: #7a4a4b;}
.block-contactinfo a:hover{color: #7a4a4b}
.contact-form label span{color: #7a4a4b;}
.bz-contact-info .icon-contact{background-color: #7a4a4b;}
.bz-contact-info .contact-info a:hover{color: #7a4a4b}
.contact-form.style-2 input[type="text"]:focus,
.contact-form.style-2 input[type="mail"]:focus,
.contact-form.style-2 textarea:focus{
    outline: 2px solid #7a4a4b;
}
.contact-form.style-2 input[type="submit"]{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.contact-form.style-2 input[type="submit"]:hover{color: #7a4a4b;}
.content-style2 .button:hover{color: #7a4a4b;}
.footer-style2 .widget ul li a{color: inherit;}
.footer-style2 .widget ul li a:hover{color: #7a4a4b;}
.footer-style2 .footer-menu a:hover{color: #7a4a4b;}
.list-latest-news li h3.title-post a:hover{color: #7a4a4b}
.content-res .button:hover{color: #7a4a4b;}
.service-resstyle .sub-title{ color: #7a4a4b;}
.portfolio-resstyle .pf-desc{ color: #7a4a4b;}
.portfolio-resstyle .pf-price{color: #7a4a4b;}
.bz-tab-menuweek .resp-tabs-list li.resp-tab-active{color: #7a4a4b;}
.info-resv .open-hours{background-color: #7a4a4b;}
.form-resv input[type="submit"]{
    background: #7a4a4b;
    border-color: #7a4a4b;
}
.form-resv input[type="submit"]:hover{color: #7a4a4b}
.item-countdown .title{ color: #7a4a4b;}
.testimonials-style3.light .testimonial .inner span{color: #7a4a4b;}
.slide-lastest .owl-nav > div:hover{ color: #7a4a4b;}
.slide-lastest .blog-item .info-post {background-color: rgba(94, 89, 157, 0.9);}
.section-6 .row-icon5 .element-icon-5 .icon{ background: #7a4a4b;}

/* custom.css */
.text-color-primary{
    color:#7a4a4b;
}
.nav-style2.owl-carousel .owl-next:hover, 
.nav-style2.owl-carousel .owl-prev:hover{
    border-color: #7a4a4b;
    color: #7a4a4b;
}
.nav-style3.owl-carousel .owl-next:hover, 
.nav-style3.owl-carousel .owl-prev:hover{
    border-color: #7a4a4b;
    color: #7a4a4b;
}
.widget ul li a:hover {
    color: #7a4a4b;
}
.widget_product_categories li a:hover,
.widget_product_categories li.current-cat a,
.widget_layered_nav li a:hover{
    color: #7a4a4b;
}
.tagcloud a:hover{
    background-color: #7a4a4b;
}
.widget_price_filter .slider-range-price{
    background: #7a4a4b;
}
.widget_product_top_sale .p-name a:hover{
    color: #7a4a4b;
}
.blog-slidebar .widget .widget-title:after{
    background-color: #7a4a4b;
}
.widget_categories li>a:hover{
    color: #7a4a4b;
}
.widget_about_me a:hover{
    color: #7a4a4b;
}
.main-menu>ul>li>a:before{
    border-color: #7a4a4b;
}
.main-menu .sub-menu>li>a:hover,
.main-menu .sub-menu>li>a:focus{
    color: #7a4a4b;
}
.megamenu .widget ul li a:hover{
    color:#7a4a4b ;
}
.megamenu .owl-carousel .owl-next:hover,
.megamenu .owl-carousel .owl-prev:hover{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.mini-cart-content .price{
    color: #7a4a4b;
}
.mini-cart-content .sub-toal-wapper .sub-toal{
    color: #7a4a4b;
}
.mini-cart-content .button.outline:hover{
    color: #7a4a4b;
}
.footer a:hover,
.footer a:focus,
.footer .widget ul li a:hover,
.footer .widget ul li:focus{
    color: #7a4a4b;
}
.footer-social a:hover,
.footer-social a:focus{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.footer .widget_text h6{
    color: #7a4a4b;
}
.breadcrumbs a:hover,
.breadcrumbs a:focus{
    color: #7a4a4b;
}
.team .team-social a:hover{
    color: #7a4a4b;
}
.team-style4 .team-social{
    background-color: #7a4a4b;
}
.team-style4 .postion{
    color: #7a4a4b;
}
.team-style5 .postion {
    color:#7a4a4b ;
}
.team-style6 .postion {
    color:#7a4a4b;
}
.testimonials-style1 .testimonial h6{
    color: #7a4a4b;
}
.testimonials-style3 .testimonial h6{
    color: #7a4a4b;
}
.testimonials-style5 span{
    color: #7a4a4b;
}
.pricing-table .popular span{
    background-color: #7a4a4b;
}
.pricing-table-style2 .pricing-button:hover{
    background-color: #7a4a4b;
}
.pricing-table-style3.active .head,
.pricing-table-style3.active .pricing-button{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.pricing-table-style4 .price{
    color: #7a4a4b;
}
.pricing-table-style4.active .pricing-button{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.button:hover{
    background-color:#7a4a4b;
    border-color: #7a4a4b;
}
.button.primary{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.button-custom:hover{
    color: #7a4a4b;
}
.faq-category li:after{
    border-left: 7px solid #7a4a4b;
}
.faq-category li:hover,
.faq-category li.active,
.faq-category li.current-cat{
    background-color: #7a4a4b;
}
.bz-acordion.acordion2 > h3.ui-accordion-header.ui-accordion-header-active .ui-icon{
    background-color: #7a4a4b;
}
.form-contact2.form-contact input[type="text"]:focus, 
.form-contact2.form-contact input[type="email"]:focus, 
.form-contact2.form-contact input[type="password"]:focus, 
.form-contact2.form-contact input[type="number"]:focus, 
.form-contact2.form-contact textarea:focus{
    outline: 2px solid #7a4a4b;
}
.element-icon-10 .icon{
    background-color: #7a4a4b;
}
.product .add_to_cart_button:hover,
.product .add_to_cart_button:focus,
.product .added_to_cart:hover,
.product .added_to_cart:focus{
    background-color:#7a4a4b;
}
.product .product-name a:hover,
.product .product-name a:focus,
.product .product-name a:active{
    color: #7a4a4b;
}
.product .price{
    color: #7a4a4b;
}
.pagination ul li.active a,
.pagination ul li a:hover,
.pagination ul li a:focus{
    color: #7a4a4b;
}
.display-product-option a:hover,
.display-product-option a.selected{
    color: #7a4a4b;
}
.summary .product-meta a:hover{
    color: #7a4a4b;
}
.summary .price{
    color: #7a4a4b;
}
.summary .single_variation_wrap .buttom-compare:hover,
.summary .single_variation_wrap .buttom-wishlist:hover{
    background: #7a4a4b;
    border-color:#7a4a4b;
}
.summary .product-share a:hover,
.summary .product-share a:focus{
    color: #7a4a4b;
}
.product-tabs .review_form .comment-reply-title:after{
    background-color: #7a4a4b;
}
.block-products .product-name a:hover{
    color: #7a4a4b;
}
.blog-item.style3 .post-cat a:hover,
.blog-item.style3 .blog-title>a:hover{
    color: #7a4a4b;
}
.blog-item.style3 .readmore-button:hover{
    border-color: #7a4a4b;
}
.blog-item.style6  .readmore-button:hover{
    color: #7a4a4b;
}
.blog-single .bottom-post .tags a:hover{
    color: #7a4a4b;
}
.groupshare ul li a:hover .fa{
    background-color: #7a4a4b;
}
.leave-reply .comment-form label span {
    color: #7a4a4b;
}
.block-slide-text.owl-carousel  .owl-next:hover, 
.block-slide-text.owl-carousel  .owl-prev:hover{
    border-color: #7a4a4b;
    color: #7a4a4b;
}
.block-about-3 .social a:hover{
    background-color: #7a4a4b;
    border-color: #7a4a4b;
}
.block-tab-products .item-fillter.fillter-active,
.block-tab-products .bz-horizontalTab .resp-tabs-list .resp-tab-active{
    color: #7a4a4b;
}
.block-lastest-new .title a:hover{
    color: #7a4a4b;
}
.sidebar-menu .bz-mini-cart .icon .count{
    background-color: #7a4a4b;
}
.sidebar-menu .main-menu>ul>li>a:hover,
.sidebar-menu .main-menu>ul>li.current-menu-item>a{
    color: #7a4a4b;
}
.main-menu > ul > li.bz-mini-cart > a .count{background-color: #7a4a4b;}
.block-shop-banner1 .title > span{
    color: #7a4a4b;
}
.section-10 .element-icon-1 .icon{
    background-color: #7a4a4b;
}
 .block-products .price{
    color: #7a4a4b;
 }
 .scroll_top{
    background-color: #7a4a4b;
 }